import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tiles } from "./tiles/Tiles";
import { TbSettingsFilled } from "react-icons/tb";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { useAuth } from "../../contexts/AuthContext";

const Portfolio = () => {
  const {
    contributionScheduleLoading,
    totalAccountLoading,
    totalAccountValue,
    contributionSchedule,
    investmentStatus,
    contributions,
    activeUserProfile,
  } = useAuth();

  const navigate = useNavigate();

  const [hasActiveSub, setHasActiveSub] = useState();
  const [isDesktop, setIsDesktop] = useState(true);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  useEffect(() => {
    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (activeUserProfile) {
      if (activeUserProfile.subActive) {
        setHasActiveSub("yes");
      } else {
        setHasActiveSub("no");
      }
    }
  }, [activeUserProfile]);

  return (
    <>
      <section className="portfolio-header">
        <h1>Portfolio</h1>
        {hasActiveSub !== "no" ? (
          <Link to="configuration">
            {isDesktop ? (
              <sl-button class="configuration-btn">
                <TbSettingsFilled /> Configuration
              </sl-button>
            ) : (
              <TbSettingsFilled />
            )}
          </Link>
        ) : null}
      </section>
      {activeUserProfile || hasActiveSub === undefined ? (
        hasActiveSub !== "no" ? (
          <Tiles />
        ) : (
          <div className="inactive-sub-tile">
            <TbAlertTriangleFilled />
            <span>Your subscription is not currently active.</span>
            <span className="link" onClick={() => navigate("/profile/subscription")}>
              Please click here to select your plan and checkout
            </span>
          </div>
        )
      ) : (
        <div className="loading-portfolio">
          <sl-spinner></sl-spinner>
        </div>
      )}
    </>
  );
};

export { Portfolio };
