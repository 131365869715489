import React from "react";
import { AiFillPlusCircle, AiFillCloseCircle } from "react-icons/ai";
import { TbExternalLink } from "react-icons/tb";

const FAQ = () => {
  return (
    <div>
      <h1>FAQ</h1>
      <FaqPanel />
    </div>
  );
};

const FaqPanel = () => {
  return (
    <section className="faq-panel-group">
      <sl-details summary="What is 'shoeboxing'?" class="faq-panel">
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          'Shoeboxing' is a strategic financial practice that involves recording and categorizing expenses for future
          withdrawal from a Health Savings Account (HSA). By using other funds for immediate medical expenses while
          allowing your HSA funds to grow tax-free through investment, you're maximizing growth by taking advantage of
          tax-efficient savings.
        </p>
        <p>
          This method allows you to tap into the HSA’s triple-tax advantage: tax-free deductions on contributions,
          tax-free growth while invested, and tax-free withdrawals for eligible medical expenses.
        </p>
        <p>
          Shoebox helps empowers you to optimize your HSA management for long-term financial gain and is the only app on
          the market purpose-built to maximize this process.
        </p>
      </sl-details>
      <sl-details summary="Can I try the service before I sign up?" class="faq-panel">
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          Absolutely! We understand the importance of trying before committing. Shoebox offers a 7-day free trial period
          which gives you full access to all of the app’s features before you are charged.
        </p>
        <p>
          This trial is available whether you select monthly or annual billing. Feel free to cancel at any time during
          your trial to avoid being charged. Already past the trial and are a paid user? If you're not satisfied with
          Shoebox at any point in your first 30 days, please reach out to us and we'll process a full refund, no
          questions asked!
        </p>
      </sl-details>
      <sl-details
        summary="Does Shoebox invest my money for me? What investments options are available to me?"
        class="faq-panel"
      >
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          No, Shoebox does not manage any investments, nor do we offer suggestions or provide any investment advice.
          However, to help track potential market growth we offer a selection of US market indices to more accurately
          track the balance growth that you hold with your HSA plan provider.
        </p>
      </sl-details>
      <sl-details summary="Is my information shared with any third parties?" class="faq-panel">
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          Absolutely not! We hold user privacy as a top priority. Any information we hold is entered by you to help you
          track your financial progress. All data is held and maintained privately within your own Shoebox account.
        </p>
        <p>
          Any changes to this policy for any potential feature updates, Shoebox and The Blocks will provide clear and
          appropriate communicate to ensure complete transparency.
        </p>
      </sl-details>
      <sl-details summary="What are eligible HSA expenses?" class="faq-panel">
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          Typical HSA-eligible expenses include medical, dental, and vision treatments, prescription medications,
          preventive care services, and other healthcare-related expenditures.
        </p>
        <p>
          It's important to note that the Internal Revenue Service (IRS) defines the specific eligible expenses, and
          these may vary over time. Shoebox is designed to help you track and categorize these expenses accurately,
          ensuring you're well-prepared for tax time and maximizing the benefits of your HSA.
        </p>
        <p>
          Always consult IRS guidelines or a tax professional for the most up-to-date information on eligible expenses.
          To start, check out our nifty guide on getting started. The additional IRS resources mentioned are also
          included within this document.
        </p>
        <p className="link">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/shoebox-dev-app.appspot.com/o/global-items%2Fresources%2Feducation%2FHSA%20Eligibility%20Guide%20(2025).pdf?alt=media&token=f011d648-0cc8-4eb2-b10d-4a859349705a"
            target="_blank"
          >
            The Shoebox Guide to HSA Eligible Expenses <TbExternalLink />
          </a>
        </p>
      </sl-details>
      <sl-details summary="Why should I use Shoebox?" class="faq-panel">
        <AiFillPlusCircle slot="expand-icon" />
        <AiFillCloseCircle slot="collapse-icon" />
        <p>
          Shoebox is the only purpose-built HSA utility on the market and your ultimate companion for maximizing Health
          Savings Account management. Our app simplifies the complex task of tracking and organizing your HSA expenses,
          allowing you to focus on what matters most – your health and financial well-being.
        </p>
        <p>
          Shoebox also goes beyond tracking – it empowers you to make the most of your HSA by leveraging its investment
          potential. By using alternate funds for immediate medical expenses and letting your HSA grow tax-free through
          investments, you're creating a solid financial foundation for the future. With Shoebox, you'll effortlessly
          stay on top of your HSA game, optimize your savings, and build a solid foundation for your financial life.
        </p>
      </sl-details>
    </section>
  );
};

export { FAQ };
