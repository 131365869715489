import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  currencyDeformatter,
  currencyFormatter,
  currencyHandler,
  currencyInputHandling,
} from "../../hooks-n-utils/currency";
import { ContributionForm } from "./ContributionForm";
import { Investments } from "./Investments";
import {
  TbArrowLeft,
  TbChartCandleFilled,
  TbHelpCircleFilled,
  TbInfoCircleFilled,
  TbCardsFilled,
} from "react-icons/tb";
import { BiSolidBank } from "react-icons/bi";
import { PiHandWavingFill } from "react-icons/pi";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { notify } from "../../hooks-n-utils/toast";
import { IoCloseCircle } from "react-icons/io5";

const Configuration = () => {
  const {
    currentUser,
    totalAccountValue,
    totalAccountLoading,
    writeAccountValue,
    contributionSchedule,
    investmentStatus,
    contributions,
  } = useAuth();

  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);

  const [investments, setInvestments] = useState();
  const [newAccountValue, setNewAccountValue] = useState("");
  const [editingTotalAccountValue, setEditingTotalAccountValue] = useState(false);
  const [loadingValue, setLoadingValue] = useState(false);
  const [updateError, setUpdateError] = useState("");

  const [editingIndex, setEditingIndex] = useState(false);

  let bannerTimeout;

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  useEffect(() => {
    if (
      Boolean(
        totalAccountValue === null &&
          contributions?.length < 1 &&
          !contributionSchedule &&
          (!investmentStatus || !investmentStatus.activeInvestments)
      )
    ) {
      bannerTimeout = setTimeout(() => {
        setShowWelcomeBanner(true);
      }, 1000);
    } else {
      setShowWelcomeBanner(false);
    }
    // sets session value to stop all tile chart animations after initial user session load
    if (!afterFirstVisit) {
      setTimeout(() => {
        window.sessionStorage.setItem("after-first-session-portfolio-visit", true);
      }, 3000);
    }
    return () => {
      clearTimeout(bannerTimeout);
    };
  }, [totalAccountValue, contributionSchedule, investmentStatus, totalAccountLoading, afterFirstVisit]);

  useEffect(() => {
    if (totalAccountValue) {
      setNewAccountValue(totalAccountValue);
    }
  }, [totalAccountValue]);

  const toggleAccountValueEdit = () => {
    setEditingTotalAccountValue(!editingTotalAccountValue);

    setTimeout(() => {
      document.getElementById("act-value-input")?.focus();
    }, 50);
  };

  const setAccountValue = () => {
    setLoadingValue(true);
    writeAccountValue(currencyDeformatter(newAccountValue), currentUser?.uid)
      .then((value) => {
        setEditingTotalAccountValue(false);
        setLoadingValue(false);
        notify("Account value has been successfully updated.", "success");
      })
      .catch((error) => {
        setUpdateError(error);
        console.error(error);
        setEditingTotalAccountValue(false);
        setLoadingValue(false);
        notify("There was an error while trying to update your account value.", "danger");
      });
  };

  return (
    <div className="configuration-dashboard">
      <div className="configuration-header">
        <div className="title">
          <Link to="/portfolio">
            <sl-button variant="neutral" class="portfolio-back-button">
              <TbArrowLeft />
            </sl-button>
          </Link>
          <h1>Configuration</h1>
        </div>
        <TbHelpCircleFilled
          className="config-help"
          onClick={() => document.getElementById("config-help-dialog")?.show()}
        />
      </div>
      {/* <div className={showWelcomeBanner ? "intro-banner open" : "intro-banner"}>
        <div className="banner-container">
          <h4>
            <PiHandWavingFill />
            It looks like you’re just getting into your portfolio!
          </h4>
          <div className="banner-text">
            Check out <a onClick={() => document.getElementById("config-help-dialog")?.show()}>this overview</a> or
            click the
            <TbHelpCircleFilled
              className="config-help"
              onClick={() => document.getElementById("config-help-dialog")?.show()}
            />
            to get some information about each configuration section.
          </div>
          <IoCloseCircle className="close-start-banner" onClick={() => setShowWelcomeBanner(false)} />
        </div>
      </div> */}
      <section className="account-overview">
        <span>
          <small>Current Account Value</small>
          {editingTotalAccountValue ? (
            <small className="edit-account-value active">
              <span
                onClick={() => {
                  !loadingValue && setAccountValue();
                }}
                className={
                  !loadingValue
                    ? currencyDeformatter(newAccountValue) === totalAccountValue || newAccountValue === ""
                      ? "save-edit disabled"
                      : "save-edit"
                    : "save-edit disabled"
                }
              >
                Save
              </span>
              <span className="actions-divider">•</span>
              <span
                className={loadingValue ? "cancel-edit disabled" : "cancel-edit"}
                onClick={() => {
                  !loadingValue && toggleAccountValueEdit();
                }}
              >
                Cancel
              </span>
            </small>
          ) : (
            <small className="edit-account-value" onClick={() => toggleAccountValueEdit()}>
              {totalAccountValue || totalAccountValue === 0 ? "Edit" : "Set Initial Value"}
            </small>
          )}
        </span>
        {editingTotalAccountValue ? (
          <input
            className="account-value-window editable"
            id="act-value-input"
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              setNewAccountValue(currencyInputHandling(e.target.value));
            }}
            onBlur={(e) => setNewAccountValue(currencyInputHandling(e.target.value, "blur"))}
            value={newAccountValue}
          />
        ) : (
          <div className="account-value-window">
            {totalAccountLoading ? (
              <sl-spinner variant="total-account-window"></sl-spinner>
            ) : (
              <span className={afterFirstVisit ? "set-account-value" : "set-account-value animated"}>
                {totalAccountValue || totalAccountValue === 0 ? (
                  currencyHandler(totalAccountValue)
                ) : (
                  <div className="not-set-indicator">Not Set</div>
                )}
              </span>
            )}
          </div>
        )}
      </section>

      <section className="config-sections">
        <section className="contributions-configuration">
          <div className="contributions-configuration-header">
            <small>Automated Contributions</small>
          </div>
          <ContributionForm />
        </section>
        <section className="investments-configuration">
          <div className="investment-header">
            <small>Investments</small>
            {editingIndex && (
              <>
                <span onClick={() => document.getElementById("index-disclosure-popup")?.show()}>
                  <TbInfoCircleFilled />
                  Index Disclosure
                </span>
                <sl-dialog label="Index Disclosure" id="index-disclosure-popup">
                  <div>
                    <h4>
                      <TbCardsFilled className="index-popup-icon" />
                      This Selection Is Not An Investment
                    </h4>
                    <p>
                      These offered indexes are not actual investments nor are they to serve as investment advice. These
                      selections were chosen as broad-market, standard indexes to monitor market growth over time.
                    </p>
                    <p>
                      By selecting one of these indexes, Shoebox does not invest any money on your behalf. Any
                      investments related to your HSA account are self-initiated and must be completed through your
                      account provider itself.
                    </p>
                    <p>
                      The following Indexes and ETFs are used to supply the market performance data of each selection:
                    </p>
                    <table>
                      <tr>
                        <th>Index</th>
                        <th>Symbol</th>
                        <th>Description</th>
                      </tr>
                      <tr>
                        <td>Standard & Poor's 500</td>
                        <td className="symbol">GPSC</td>
                        <td>S&P 500 Index</td>
                      </tr>
                      <tr>
                        <td>Nasdaq Composite Index</td>
                        <td className="symbol">IXIC</td>
                        <td>Nasdaq Composite Index</td>
                      </tr>
                      <tr>
                        <td>Dow Jones Industrial Average</td>
                        <td className="symbol">DJI</td>
                        <td>Dow Jones Industrial Average Index</td>
                      </tr>
                      <tr>
                        <td>Total Stock Market Index</td>
                        <td className="symbol">VTI</td>
                        <td>Vanguard Total Stock Market ETF</td>
                      </tr>
                    </table>
                  </div>
                </sl-dialog>
              </>
            )}
          </div>
          <Investments setEditingIndex={setEditingIndex} editingIndex={editingIndex} />
        </section>
      </section>
      <sl-dialog label="Configuration Help" id="config-help-dialog">
        <div>
          <h4>
            <BiSolidBank className="config-icon" /> Account Value
          </h4>
          <p>
            We recommend using <span className="link">Edit</span> to bring your account value up to where your existing
            HSA account is today.
          </p>
          <p>Don't have anything in your account yet? No worries! We'll leave this as $0.00.</p>
          <p>
            Once this value is set, if you find that any adjustments need to be made in the future, simply click{" "}
            <span className="link">Edit</span> and update. A datapoint in your account value dashboard chart will
            reflect this change.
          </p>
          <h4>
            <BsFillFileEarmarkPlusFill /> Contributions
          </h4>
          <p>
            Creating a <span className="bold">Contribution Schedule</span> will follow your regular paycheck deductions
            or employer contributions to automate your account growth.
          </p>
          <p>
            Making a lump-sum deposit or getting an off-cycle contribution from your employer can be logged using a
            one-time contribution.
          </p>
          <p>
            Automated contributions or future one-time contributions logged will be completed at 7:00am (eastern) on
            their scheduled day.
          </p>
          <h4>
            <TbChartCandleFilled className="config-icon" /> Investments
          </h4>
          <p>
            Lastly, <span className="bold">Investments</span> will allow you to track a broad index fund or
            interest-bearing cash to factor in growth on an invested portion of your HSA account.
          </p>
          <p>
            Your account value is updated every evening at 7:15pm (eastern) with any movement on your selected
            investment for that day.
          </p>
        </div>
      </sl-dialog>
    </div>
  );
};

export { Configuration };
