import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  query,
  where,
  updateDoc,
  getDocs,
  arrayUnion,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { createCheckoutSession } from "../stripe/checkoutSession";
import { notify } from "../hooks-n-utils/toast";
import { dateToStandardDateString, parseDateToLocal } from "../hooks-n-utils/date";
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { validateCatchUpAge } from "../hooks-n-utils/age-validation";
import { createClient } from "@sanity/client";
import { ErrorHandler } from "../hooks-n-utils/error-handling";
import { generateUID } from "../hooks-n-utils/uid-generator";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [authLoading, setAuthLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [activeUserProfile, setActiveUserProfile] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [colorMode, setColorMode] = useState();
  const [weightingMethod, setWeightingMethod] = useState();
  const [isNewUser, setIsNewUser] = useState(false);

  // Global
  const [annualData, setAnnualData] = useState();
  const [loadingDocs, setLoadingDocs] = useState(true);
  const [legalDocs, setLegalDocs] = useState();
  const [activeTerms, setActiveTerms] = useState();
  const [currentTermsVersion, setCurrentTermsVersion] = useState();
  const [currentPrivacyVersion, setCurrentPrivacyVersion] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  // CMS Content
  const [releaseNotes, setReleaseNotes] = useState();
  const [helpCategories, setHelpCategories] = useState();
  const [helpDocuments, setHelpDocuments] = useState();

  // Expenses
  const [expenses, setExpenses] = useState();
  const [hsaExpenseGuide, setHsaExpenseGuide] = useState();
  const [hasGuide, setHasGuide] = useState(false);
  const [loadingExpenses, setLoadingExpenses] = useState(true);
  const [noExpenses, setNoExpenses] = useState(false);

  // Expense Action Loaders
  const [addExpenseLoading, setAddExpenseLoading] = useState(false);
  const [updateExpenseLoading, setUpdateExpenseLoading] = useState(false);
  const [deleteExpenseLoading, setDeleteExpenseLoading] = useState(false);

  // Portfolio
  const [totalAccountValue, setTotalAccountValue] = useState();
  const [contributionSchedule, setContributionSchedule] = useState();
  const [contributions, setContributions] = useState();
  const [currentYearContributions, setCurrentYearContributions] = useState();
  const [noContributions, setNoContributions] = useState(false);
  const [investmentStatus, setInvestmentStatus] = useState();
  const [investmentData, setInvestmentData] = useState();
  const [noInvestmentData, setNoInvestmentData] = useState(false);

  // Portfolio Action Loaders
  // I set first two to be defaulted to true to avoid empty portfolio spalsh from displaying.
  // Test that this is still an appropriate decision with a new account.
  const [totalAccountLoading, setTotalAccountLoading] = useState(true);
  const [contributionScheduleLoading, setContributionScheduleLoading] = useState(true);
  const [loadingContributions, setLoadingContributions] = useState(false);
  const [loadingInvestmentStatus, setLoadingInvestmentStatus] = useState(false);
  const [loadingInvestmentData, setLoadingInvestmentData] = useState(false);
  const [loadingVerifiedSubscription, setLoadingVerifiedSubscription] = useState(false);

  const navigate = useNavigate();

  let sanityClient = createClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: process.env.REACT_APP_SANITY_DATASET,
    apiVersion: "2022-03-07",
    useCdn: true,
    token: process.env.REACT_APP_SANITY_API_TOKEN,
    ignoreBrowserTokenWarning: true,
  });

  // AUTHENTICATION & ACCOUNT CREATION

  const signUp = async (email, password, firstName, lastName) => {
    setIsNewUser(true);
    let user = await createUserWithEmailAndPassword(auth, email, password);
    return updateProfile(user.user, {
      displayName: `${firstName} ${lastName}`,
    });
  };

  const createAccount = async (plan, firstName, lastName, dob, catchUpEligible, coverageType) => {
    const currentDay = new Date().toLocaleDateString("en-CA");
    const currentYear = new Date().getFullYear().toString();
    const randomFirstIndex = Math.floor(Math.random() * 8);
    const randomSecondIndex = Math.floor(Math.random() * 8);
    handleColorMode("light");
    setColorMode("light");
    setWeightingMethod("time-weighted");
    let todaysDataPoint = [
      {
        accountValue: 0,
        adjustments: 0,
        contributions: 0,
        date: currentDay,
        invested: false,
        investment: null,
        percentChange: 0,
        withdrawals: 0,
      },
    ];
    await setDoc(doc(db, "users", auth.currentUser.uid), {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email,
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dob,
      picture: "",
      defaultAvatar: [randomFirstIndex, randomSecondIndex],
      created: currentDay,
      onboardingOptOut: false,
      catchUpEligible: catchUpEligible,
      coverageType: coverageType,
      plan: plan,
      subscription: null,
      subActive: false,
      provider: "firebase",
      preferences: [{ colorMode: "light" }, { weightingMethod: "time-weighted" }],
      useAgreements: {
        termsOfUse: {
          agreementDate: currentDay,
          version: currentTermsVersion,
        },
        privacyPolicy: {
          agreementDate: currentDay,
          version: currentPrivacyVersion,
        },
      },
    })
      .then(async () => {
        await setDoc(doc(db, "users", auth.currentUser.uid, "portfolio", "investments"), {
          activeInvestments: false,
          dataLabel: null,
          firstDataPointLogged: currentDay,
          indexTitle: "No Active Investments",
          interestRate: 0,
          symbol: "Not Tracking",
        });
        await setDoc(doc(db, "users", auth.currentUser.uid, "portfolio", "investments", "data_points", currentYear), {
          data: todaysDataPoint,
        });
        await setDoc(doc(db, "users", auth.currentUser.uid, "portfolio", "contributions"), {
          activeSchedule: false,
          scheduleDetail: {
            employerContributionSchedule: {},
            hasEmployerContributionSchedule: false,
            individualContributionSchedule: {},
            hasIndividualContributionSchedule: false,
          },
        });
        setActiveUser(auth.currentUser.uid);
        setIsNewUser(false);
        applyPreferences(auth.currentUser.uid);
        setTimeout(() => {
          createCheckoutSession(auth.currentUser.uid, plan, "create-account", setLoadingCheckout);
        }, 1000);
        return true;
      })
      .catch((err) => {
        console.error("There has been an issue creating your account. Please try again later. ERROR: " + err);
        alert("There has been an issue creating your account. Please try again later.");
        return false;
      });
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const resetUserState = () => {
    setExpenses();
    setTotalAccountValue();
    setContributionSchedule();
    setContributions();
    setCurrentYearContributions();
    setInvestmentData();
  };

  const signOut = () => {
    auth.signOut().then(() => {
      // sets light-mode on sign out to keep external pages consistent
      handleColorMode("light");
      navigate("/sign-in");
      setActiveUser();
      // Resets animations for next sign in if session is persisting
      sessionStorage.removeItem("after-first-session-expenses-visit");
      sessionStorage.removeItem("after-first-session-portfolio-visit");
      // Purges stored state if account switches within same session
      resetUserState();
    });
  };

  const setNewPassword = async (oldPassword, newPassword) => {
    const credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        updatePassword(auth.currentUser, newPassword)
          .then(() => {
            notify("Password has been successfully updated", "success");
          })
          .catch((err) => {
            notify(ErrorHandler(err.code), "danger");
          });
      })
      .catch((err) => {
        if (err.code === "auth/wrong-password") {
          notify("Incorrect current password entered. Please try again.", "danger");
        } else {
          notify(ErrorHandler(err.code), "danger");
        }
      });
  };

  const getPasswordResetLink = async (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        notify("An email was sent to this address with a link to reset your password", "success");
      })
      .catch((error) => {
        notify(ErrorHandler(error.code), "danger");
      });
  };

  const setActiveUser = (uid) => {
    if (uid) {
      const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
        if (doc) {
          setActiveUserProfile(doc.data());
          return unsubscribe();
        }
      });
    } else {
      setActiveUserProfile();
    }
  };

  const welcomeDaysOptOut = async () => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      onboardingOptOut: true,
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  // SUBSCRIPTION HANDLING

  const openCheckout = (uid, plan, location) => {
    createCheckoutSession(uid, plan, location, setLoadingCheckout);
  };

  const verifySubscription = (uid) => {
    setLoadingVerifiedSubscription(true);
    const q = query(collection(db, "users", uid, "subscriptions"), where("status", "in", ["active", "trialing"]));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot?.docs[0]?.data().status === "active" || querySnapshot?.docs[0]?.data().status === "trialing") {
        updateDoc(doc(db, "users", auth.currentUser.uid), {
          subActive: true,
          subscription: querySnapshot?.docs[0]?.data().items[0].price.product.name,
        });
        setSubscriptionDetails({
          price: querySnapshot?.docs[0]?.data().items[0].price.unit_amount,
          created: querySnapshot?.docs[0]?.data().items[0].created,
          currentBillingPeriodEnd: querySnapshot?.docs[0]?.data()?.current_period_end.seconds,
          image: querySnapshot?.docs[0]?.data().items[0]?.price.product.images[0],
          interval: querySnapshot?.docs[0]?.data().items[0].price.recurring.interval,
          canceled: Boolean(querySnapshot?.docs[0]?.data().cancel_at),
          cancelDate: querySnapshot?.docs[0]?.data()?.cancel_at?.seconds,
        });
        const userDocSub = onSnapshot(doc(db, "users", uid), (doc) => {
          setLoadingVerifiedSubscription(false);
          if (doc) {
            setActiveUserProfile(doc.data());
          }
          if (doc.data().subActive) {
            userDocSub();
            return unsubscribe();
          }
        });
      } else {
        updateDoc(doc(db, "users", auth.currentUser.uid), {
          subActive: false,
        });
        const userDocSub = onSnapshot(doc(db, "users", uid), (doc) => {
          setLoadingVerifiedSubscription(false);
          if (doc) {
            setActiveUserProfile(doc.data());
          }
          if (doc.data().subActive) {
            userDocSub();
            return unsubscribe();
          }
        });
      }
    });
  };

  // USER SETTINGS & PREFERENCES

  const getLegalDocs = () => {
    const url =
      "https://public-api.wordpress.com/rest/v1.1/sites/blocksfinance.wordpress.com/posts/?category=shoebox/s-legal";
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setLegalDocs(data.posts);
        setLoadingDocs(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDocs(false);
      });
  };

  const getCurrentAgreementVersions = async () => {
    const termsRef = doc(db, "global", "internal", "legal", "terms-of-use");
    const termsSnap = await getDoc(termsRef);

    const privacyRef = doc(db, "global", "internal", "legal", "terms-of-use");
    const privacySnap = await getDoc(privacyRef);

    if (termsSnap.exists()) {
      let termsDate = new Date(termsSnap.data().currentVersion.seconds * 1000);
      const month = termsDate.toLocaleString("default", {
        month: "long",
      });
      let year = termsDate.getFullYear();
      setCurrentTermsVersion(month + " " + year);
    } else {
      setCurrentTermsVersion(null);
    }

    if (privacySnap.exists()) {
      let privacyDate = new Date(privacySnap.data().currentVersion.seconds * 1000);
      const month = privacyDate.toLocaleString("default", {
        month: "long",
      });
      let year = privacyDate.getFullYear();
      setCurrentPrivacyVersion(month + " " + year);
    } else {
      setCurrentPrivacyVersion(null);
    }

    return;
  };

  const applyPreferences = (uid) => {
    const unsubscribe = onSnapshot(doc(db, "users", uid), (doc) => {
      if (doc) {
        handleColorMode(doc.data()?.preferences[0]?.colorMode);
        setColorMode(doc.data()?.preferences[0]?.colorMode);
        setWeightingMethod(doc.data()?.preferences[1]?.weightingMethod);
        return unsubscribe();
      }
    });
  };

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  function changeThemeColor(mode) {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", mode === "dark" ? "#131313" : "#ffffff");
  }

  // magic for system color mode
  let currentSystemTheme = window.matchMedia("(prefers-color-scheme: dark)");

  const handleColorMode = (colorPreference) => {
    let prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    let prefersLight = window.matchMedia("(prefers-color-scheme: light)").matches;

    document.getElementById("shoebox-index").classList.remove("sl-theme-shoebox-light");
    document.getElementById("shoebox-index").classList.remove("sl-theme-shoebox-dark");
    switch (colorPreference) {
      case "system":
        if (prefersDark) {
          setDark();
          changeThemeColor("dark");
          document.getElementById("shoebox-index").classList.add("sl-theme-shoebox-dark");
        } else if (prefersLight) {
          setLight();
          changeThemeColor("light");
          document.getElementById("shoebox-index").classList.add("sl-theme-shoebox-light");
        }
        break;
      case "light":
        setLight();
        changeThemeColor("light");
        document.getElementById("shoebox-index").classList.add("sl-theme-shoebox-light");
        break;
      case "dark":
        setDark();
        changeThemeColor("dark");
        document.getElementById("shoebox-index").classList.add("sl-theme-shoebox-dark");
        break;
      default:
        setLight();
        changeThemeColor("light");
        document.getElementById("shoebox-index").classList.add("sl-theme-shoebox-light");
    }
  };

  const updateColorPreference = (uid, preference) => {
    updateDoc(doc(db, "users", uid), {
      preferences: [{ colorMode: preference }, { weightingMethod: weightingMethod }],
    })
      .then(() => {
        handleColorMode(preference);
        setColorMode(preference);
        notify(`Color mode has been updated to ${preference.charAt(0).toUpperCase() + preference.slice(1)}`, "success");
      })
      .catch(() => {
        notify("There was an issue with updating your color mode. Please try again later.", "danger");
      });
  };

  const updateWeightingPreference = (uid, preference) => {
    updateDoc(doc(db, "users", uid), {
      preferences: [{ colorMode: colorMode }, { weightingMethod: preference }],
    })
      .then(() => {
        setWeightingMethod(preference);
        notify(`${preference.charAt(0).toUpperCase() + preference.slice(1)} returns are now active`, "success");
        return setActiveUser(auth.currentUser.uid);
      })
      .catch(() => {
        notify("There was an issue with updating your weighting method. Please try again later.", "danger");
      });
  };

  const updateCoverageType = async (type) => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      coverageType: type,
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const updateName = async (firstName, lastName) => {
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      firstName: firstName,
      lastName: lastName,
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const updateDateOfBirth = async (dob) => {
    let eligible = validateCatchUpAge(dob);
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      dateOfBirth: dob,
      catchUpEligible: eligible,
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const updateProfilePhoto = async (fileObj) => {
    const storage = getStorage();
    let downloadUrl;

    try {
      const storageRef = ref(storage, `users/${auth.currentUser.uid}/profile/profile-image`);
      // Upload the file to the storage reference
      const snapshot = await uploadBytes(storageRef, fileObj.file);
      // Get the download URL for the uploaded file
      downloadUrl = await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading file:", fileObj.name, error);
      throw error; // Ensure the error propagates if upload fails
    }

    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      picture: downloadUrl,
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const deleteProfilePhoto = async () => {
    const storage = getStorage();

    const profPicRef = ref(storage, `users/${auth.currentUser.uid}/profile/profile-image`);
    await deleteObject(profPicRef); // Delete the file

    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      picture: "",
    })
      .then(() => {
        return setActiveUser(auth.currentUser.uid);
      })
      .catch((err) => {
        console.error(err);
        return err;
      });
  };

  const getReleaseNotes = () => {
    const query = '*[_type == "release_note" && !(_id in path("drafts.**"))] | order(releasedAt desc)';
    sanityClient.fetch(query).then((data) => {
      setReleaseNotes(data);
    });
  };

  const getHelpCategories = () => {
    const query = '*[_type == "help_category" && !(_id in path("drafts.**"))] | order(categoryInfo.order asc)';
    sanityClient.fetch(query).then((data) => {
      setHelpCategories(data);
    });
  };

  const getHelpDocuments = () => {
    const query = '*[_type == "help_document" && !(_id in path("drafts.**"))] | order(lastUpdated desc)';
    sanityClient.fetch(query).then((data) => {
      setHelpDocuments(data);
    });
  };

  // GLOBAL ANNUAL INFORMATION

  const getAnnualContributionAndLimitData = async () => {
    let tempData = [];
    const querySnapshot = await getDocs(collection(db, "global", "internal", "expenses_and_limits"));
    if (querySnapshot.empty) {
      setAnnualData([]);
      return annualData;
    } else {
      querySnapshot.forEach((doc) => {
        tempData.push(doc.data());
      });
      setAnnualData(tempData);
      return annualData;
    }
  };

  // SHOEBOX EXPENSES

  const checkExpenseYear = async (payloadYear) => {
    let index;
    for (let i = 0; i < expenses.length; i++) {
      if (+expenses[i].year === +payloadYear) {
        index = i;
        return index;
      }
    }
  };

  const uploadReceipts = async (receipts, uid, expenseId) => {
    // Get a reference to the Firebase storage service

    const storage = getStorage();

    let receiptDbInfo = [];

    // Create an array of Promises for uploading files
    const uploadPromises = receipts.map(async (receipt) => {
      try {
        // Create a storage reference with a unique file path for each file
        const storageRef = ref(storage, `users/${uid}/expenses/${expenseId}/${receipt.name}`);

        // Upload the file to the storage reference
        const snapshot = await uploadBytes(storageRef, receipt.file);

        // Get the download URL for the uploaded file
        const downloadUrl = await getDownloadURL(snapshot.ref);

        // Add the download URL to the object that is uploaded to firestore
        let receiptInfo = {
          name: receipt.name,
          type: receipt.type,
          base64: receipt.type === "pdf" ? receipt.base64 : "",
          downloadLink: downloadUrl,
        };
        receiptDbInfo.push(receiptInfo);
      } catch (error) {
        console.error("Error uploading file:", receipt.name, error);
        throw error; // Ensure the error propagates if upload fails
      }
    });

    // Wait for all uploads to complete and return a final array to the addExpense function
    await Promise.all(uploadPromises);
    return receiptDbInfo;
  };

  const addExpense = async (payload, uid) => {
    setAddExpenseLoading(true);
    const docRef = doc(db, "users", uid, "expenses", payload.year);
    let index = await checkExpenseYear(payload.year);

    const uploadedReceipts = await uploadReceipts(payload.receipts, uid, payload.id);

    payload.receipts = uploadedReceipts;

    // Checks to see if there is already a document for the year of the
    // submitted expense, if so, it adds to the expense array. If not,
    // it creates a new document with empty values for the year.
    if (index === undefined) {
      let expenseTotal = payload.eligible && !payload.withdrawn ? payload.amount : 0;
      let withdrawnTotal = payload.withdrawn && payload.eligible ? payload.amount : 0;
      await setDoc(docRef, {
        expenses: [payload],
        annualExpensesTotal: +expenseTotal.toFixed(2),
        annualWithdrawalsTotal: +withdrawnTotal.toFixed(2),
        year: +payload.year,
      })
        .then(() => {
          setAddExpenseLoading(false);
          getExpenses(uid);
          return;
        })
        .catch((error) => {
          console.error(error);
          setAddExpenseLoading(false);
        });
    } else {
      let revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal;
      let revisedExpensesTotal = +expenses[index].annualExpensesTotal;
      if (payload.withdrawn) {
        revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal + (payload.eligible ? payload.amount : 0);
      } else {
        revisedExpensesTotal = +expenses[index].annualExpensesTotal + (payload.eligible ? payload.amount : 0);
      }

      await updateDoc(docRef, {
        expenses: arrayUnion(payload),
        annualExpensesTotal: +revisedExpensesTotal.toFixed(2),
        annualWithdrawalsTotal: +revisedWithdrawalsTotal.toFixed(2),
        year: +payload.year,
      })
        .then(async () => {
          if (payload.withdrawn) {
            await updateAccountValuesAfterChange(payload.dateWithdrawn, null, "withdrawal", null, uid, payload);
          }
        })
        .then(() => {
          setAddExpenseLoading(false);
          getExpenses(uid);
          return;
        })
        .catch((error) => {
          console.error(error);
          setAddExpenseLoading(false);
        });
    }
  };

  const updateExpense = async (payload, previousYear, uid) => {
    setUpdateExpenseLoading(true);
    let index = await checkExpenseYear(payload.year);
    let previousIndex = await checkExpenseYear(previousYear);
    let savedExpense;

    // Find and remove the expense being updated
    let newExpenses = expenses[previousIndex].expenses.filter((expense) => {
      if (expense.id === payload.id) {
        savedExpense = expense;
      }
      return expense.id !== payload.id;
    });

    // Handle receipt updates
    const storage = getStorage(); // Get the Firebase storage reference
    const savedReceipts = savedExpense.receipts || []; // Old receipts from saved expense
    const newReceipts = payload.receipts || []; // New receipts from the updated payload

    // Find receipts to delete (present in old but not in new)
    const receiptsToDelete = savedReceipts.filter(
      (savedReceipt) => !newReceipts.some((newReceipt) => newReceipt.name === savedReceipt.name)
    );

    // Find receipts to upload (present in new but not in old)
    const receiptsToUpload = newReceipts.filter(
      (newReceipt) => !savedReceipts.some((savedReceipt) => savedReceipt.name === newReceipt.name)
    );

    try {
      // Delete the necessary files from Firebase Storage
      for (const receipt of receiptsToDelete) {
        const receiptRef = ref(storage, `users/${uid}/expenses/${payload.id}/${receipt.name}`);
        await deleteObject(receiptRef); // Delete the file
      }

      // Upload new receipts and get their metadata (if any new ones exist)
      let uploadedReceipts = [];
      if (receiptsToUpload.length > 0) {
        uploadedReceipts = await uploadReceipts(
          receiptsToUpload.map((receipt) => receipt),
          uid,
          payload.id // Use the expense ID as the folder
        );
      }

      // Combine old and new receipts, ensuring only the necessary properties are included
      const updatedReceipts = [
        ...newReceipts.filter((receipt) => savedReceipts.some((savedReceipt) => savedReceipt.name === receipt.name)),
        ...uploadedReceipts,
      ];

      // Add the updated payload back into the expenses array
      payload.receipts = updatedReceipts; // Set the cleaned-up receipts array in the payload

      let revisedExpensesTotal;
      let revisedOldExpensesTotal;
      let revisedWithdrawalsTotal;
      let revisedOldWithdrawalsTotal;

      if (index === previousIndex) {
        newExpenses.push(payload);

        revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal;
        revisedExpensesTotal = +expenses[index].annualExpensesTotal;

        if (payload.eligible && savedExpense.eligible) {
          if (savedExpense.withdrawn && payload.withdrawn) {
            revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal - savedExpense.amount + payload.amount;
          }
          if (!savedExpense.withdrawn && payload.withdrawn) {
            revisedExpensesTotal = +expenses[index].annualExpensesTotal - savedExpense.amount;
            revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal + payload.amount;
          }
          if (savedExpense.withdrawn && !payload.withdrawn) {
            revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal - savedExpense.amount;
            revisedExpensesTotal = +expenses[index].annualExpensesTotal + payload.amount;
          }
          if (!savedExpense.withdrawn && !payload.withdrawn) {
            revisedExpensesTotal = +expenses[index].annualExpensesTotal - savedExpense.amount + payload.amount;
          }
        } else {
          if (payload.eligible && !savedExpense.eligible) {
            if (payload.withdrawn) {
              revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal + payload.amount;
            } else {
              revisedExpensesTotal = +expenses[index].annualExpensesTotal + payload.amount;
            }
          }
          if (!payload.eligible && savedExpense.eligible) {
            if (savedExpense.withdrawn) {
              revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal - savedExpense.amount;
            } else {
              revisedExpensesTotal = +expenses[index].annualExpensesTotal - savedExpense.amount;
            }
          }
          if (!payload.eligible && !savedExpense.eligible) {
            revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal;
            revisedExpensesTotal = +expenses[index].annualExpensesTotal;
          }
        }

        await setDoc(doc(db, "users", uid, "expenses", payload.year), {
          expenses: newExpenses,
          annualExpensesTotal: +revisedExpensesTotal.toFixed(2),
          annualWithdrawalsTotal: +revisedWithdrawalsTotal.toFixed(2),
          year: +payload.year,
        });
      } else {
        revisedWithdrawalsTotal = expenses[index] ? +expenses[index]?.annualWithdrawalsTotal : 0;
        revisedExpensesTotal = expenses[index] ? +expenses[index]?.annualExpensesTotal : 0;
        revisedOldWithdrawalsTotal = expenses[previousIndex] ? +expenses[previousIndex]?.annualWithdrawalsTotal : 0;
        revisedOldExpensesTotal = expenses[previousIndex] ? +expenses[previousIndex]?.annualExpensesTotal : 0;

        if (savedExpense.withdrawn && savedExpense.eligible) {
          revisedOldWithdrawalsTotal = revisedOldWithdrawalsTotal - savedExpense.amount;
        }
        if (!savedExpense.withdrawn && savedExpense.eligible) {
          revisedOldExpensesTotal = revisedOldExpensesTotal - savedExpense.amount;
        }

        if (payload.withdrawn && payload.eligible) {
          revisedWithdrawalsTotal = revisedWithdrawalsTotal + payload.amount;
        }
        if (!payload.withdrawn && payload.eligible) {
          revisedExpensesTotal = revisedExpensesTotal + payload.amount;
        }

        if (index === undefined) {
          await setDoc(doc(db, "users", uid, "expenses", previousYear), {
            expenses: newExpenses,
            annualExpensesTotal: +revisedOldExpensesTotal.toFixed(2),
            annualWithdrawalsTotal: +revisedOldWithdrawalsTotal.toFixed(2),
            year: +previousYear,
          }).catch((error) => {
            console.error(error);
          });
          await setDoc(doc(db, "users", uid, "expenses", payload.year), {
            expenses: [payload],
            annualExpensesTotal: +revisedExpensesTotal.toFixed(2),
            annualWithdrawalsTotal: +revisedWithdrawalsTotal.toFixed(2),
            year: +payload.year,
          })
            .then(() => {
              setAddExpenseLoading(false);
              getExpenses(uid);
              return;
            })
            .catch((error) => {
              console.error(error);
              setAddExpenseLoading(false);
            });
        } else {
          await setDoc(doc(db, "users", uid, "expenses", previousYear), {
            expenses: newExpenses,
            annualExpensesTotal: +revisedOldExpensesTotal.toFixed(2),
            annualWithdrawalsTotal: +revisedOldWithdrawalsTotal.toFixed(2),
            year: +previousYear,
          }).catch((error) => {
            console.error(error);
          });
          await updateDoc(doc(db, "users", uid, "expenses", payload.year), {
            expenses: arrayUnion(payload),
            annualExpensesTotal: +revisedExpensesTotal.toFixed(2),
            annualWithdrawalsTotal: +revisedWithdrawalsTotal.toFixed(2),
            year: +payload.year,
          })
            .then(() => {
              setAddExpenseLoading(false);
              getExpenses(uid);
              return;
            })
            .catch((error) => {
              console.error(error);
              setAddExpenseLoading(false);
            });
        }
      }

      if (payload.withdrawn || (!payload.withdrawn && savedExpense.withdrawn)) {
        let prevAmount;
        let newAmount;
        if (payload.withdrawn && !savedExpense.withdrawn) {
          prevAmount = 0;
          newAmount = payload.amount;
        }
        if (payload.withdrawn && savedExpense.withdrawn) {
          prevAmount = savedExpense.amount;
          newAmount = payload.amount;
        }
        if (!payload.withdrawn && savedExpense.withdrawn) {
          prevAmount = savedExpense.amount;
          newAmount = 0;
        }
        await updateAccountValuesAfterChange(
          payload.dateWithdrawn ? payload.dateWithdrawn : savedExpense.dateWithdrawn,
          savedExpense.dateWithdrawn ? savedExpense.dateWithdrawn : null,
          "withdrawal",
          prevAmount,
          uid,
          { amount: newAmount }
        );
      }

      // Handle post-update actions
      setUpdateExpenseLoading(false);
      getExpenses(uid);
    } catch (error) {
      console.error("Error updating expense:", error);
      setUpdateExpenseLoading(false);
    }
  };

  const deleteExpense = async (payload, uid) => {
    setDeleteExpenseLoading(true);
    let index = await checkExpenseYear(payload.year);
    let revisedExpensesTotal = +expenses[index].annualExpensesTotal;
    let revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal;
    let savedExpense;

    // Find and remove the expense being updated
    let newExpenses = expenses[index].expenses.filter((expense) => {
      if (expense.id === payload.id) {
        savedExpense = expense;
      }
      return expense.id !== payload.id;
    });

    const storage = getStorage(); // Get the Firebase storage reference

    if (savedExpense.receipts.length > 0) {
      // Delete the necessary files from Firebase Storage
      for (const receipt of savedExpense.receipts) {
        const receiptRef = ref(storage, `users/${uid}/expenses/${payload.id}/${receipt.name}`);
        await deleteObject(receiptRef); // Delete the file
      }
    }

    if (newExpenses.length < 1) {
      await deleteDoc(doc(db, "users", uid, "expenses", savedExpense.year))
        .then(async () => {
          if (savedExpense.withdrawn) {
            await updateAccountValuesAfterChange(
              savedExpense.dateWithdrawn,
              savedExpense.dateWithdrawn,
              "withdrawal",
              savedExpense.amount,
              uid,
              { amount: 0 }
            );
          }
        })
        .then(() => {
          setDeleteExpenseLoading(false);
          getExpenses(uid);
        })
        .catch((error) => {
          console.error(error);
          setDeleteExpenseLoading(false);
        });
    } else {
      // Logic to determine need to update annual total expense & withdrawal values
      if (savedExpense.withdrawn && savedExpense.eligible) {
        revisedWithdrawalsTotal = +expenses[index].annualWithdrawalsTotal - savedExpense.amount;
      }
      if (!savedExpense.withdrawn && savedExpense.eligible) {
        revisedExpensesTotal = +expenses[index].annualExpensesTotal - savedExpense.amount;
      }

      await setDoc(doc(db, "users", uid, "expenses", payload.year), {
        expenses: newExpenses,
        annualExpensesTotal: revisedExpensesTotal.toFixed(2),
        annualWithdrawalsTotal: revisedWithdrawalsTotal.toFixed(2),
        year: +payload.year,
      })
        .then(async () => {
          if (payload.withdrawn) {
            await updateAccountValuesAfterChange(
              payload.dateWithdrawn,
              payload.dateWithdrawn,
              "withdrawal",
              payload.amount,
              uid,
              { amount: 0 }
            );
          }
        })
        .then(() => {
          setDeleteExpenseLoading(false);
          getExpenses(uid);
        })
        .catch((error) => {
          console.error(error);
          setDeleteExpenseLoading(false);
        });
    }
  };

  const getExpenses = async (uid) => {
    setLoadingExpenses(true);
    let tempExpenses = [];
    const querySnapshot = await getDocs(collection(db, "users", uid, "expenses"));
    if (querySnapshot.empty) {
      setExpenses(tempExpenses);
      setNoExpenses(true);
      setLoadingExpenses(false);
      return expenses;
    } else {
      querySnapshot.forEach((doc) => {
        if (doc.data().expenses.length > 0) {
          tempExpenses.push(doc.data());
        }
      });
      setExpenses(tempExpenses);
      setNoExpenses(tempExpenses.length < 1);
      setLoadingExpenses(false);
      return expenses;
    }
  };

  const getHSAExpenseGuide = async () => {
    let docRef = doc(db, "global", "internal", "resources", "education");
    const educationDocSnap = await getDoc(docRef);
    if (educationDocSnap.exists()) {
      setHasGuide(true);
      let guide = educationDocSnap.data().documents.filter((doc) => {
        return doc.title === "HSA Eligible Expenses Guide";
      });
      setHsaExpenseGuide(guide);
      return hsaExpenseGuide;
    } else {
      setHasGuide(false);
    }
  };

  // SHOEBOX PORTFOLIO

  const writeAccountValue = async (amount, uid) => {
    let currentYear = new Date().getFullYear().toString();
    let currentDate = new Date().toLocaleDateString("en-CA"); // Don't need CA, but easy way to get YYYY-MM-DD format in a single line...
    const docRef = doc(db, "users", uid, "portfolio", "investments", "data_points", currentYear);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists) {
      let yearsInvestmentData = docSnap.data().data;
      yearsInvestmentData.sort((a, b) => b.date - a.date);
      let dataPoint;
      if (yearsInvestmentData[yearsInvestmentData.length - 1].date === currentDate) {
        let adjustmentCalc = amount - yearsInvestmentData[yearsInvestmentData.length - 1].accountValue;
        dataPoint = {
          accountValue: amount,
          adjustments: +(+yearsInvestmentData[yearsInvestmentData.length - 1].adjustments + adjustmentCalc).toFixed(2),
          contributions: +yearsInvestmentData[yearsInvestmentData.length - 1].contributions,
          date: yearsInvestmentData[yearsInvestmentData.length - 1].date,
          invested: yearsInvestmentData[yearsInvestmentData.length - 1].invested,
          investment: yearsInvestmentData[yearsInvestmentData.length - 1].investment,
          percentChange: +yearsInvestmentData[yearsInvestmentData.length - 1].percentChange,
          withdrawals: +yearsInvestmentData[yearsInvestmentData.length - 1].withdrawals,
        };
        yearsInvestmentData = yearsInvestmentData.filter((data) => data.date !== currentDate);
        yearsInvestmentData.push(dataPoint);
      } else {
        let adjustmentCalc = amount - yearsInvestmentData[yearsInvestmentData.length - 1].accountValue;
        dataPoint = {
          accountValue: amount,
          adjustments: +adjustmentCalc.toFixed(2),
          contributions: 0,
          date: currentDate,
          invested: false,
          investment: null,
          percentChange: 0,
          withdrawals: 0,
        };
        yearsInvestmentData.push(dataPoint);
      }
      await setDoc(docRef, { data: yearsInvestmentData })
        .then(() => {
          setTotalAccountValue(+amount);
          return totalAccountValue;
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    }
  };

  const getAccountValue = async (uid) => {
    setTotalAccountLoading(true);
    let currentYear;
    let date = new Date();
    if (dateToStandardDateString(date).slice(4) === "-1-1") {
      currentYear = (new Date().getFullYear() - 1).toString();
    } else {
      currentYear = new Date().getFullYear().toString();
    }
    const docRef = doc(db, "users", uid, "portfolio", "investments", "data_points", currentYear);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let yearsInvestmentData = docSnap.data().data;
      yearsInvestmentData.sort((a, b) => b.date - a.date);
      let currentAccountValue = yearsInvestmentData[yearsInvestmentData.length - 1].accountValue;
      setTotalAccountValue(currentAccountValue);
      setTotalAccountLoading(false);
    } else {
      setTotalAccountValue(null);
      setTotalAccountLoading(false);
    }
  };

  const removeExistingScheduledContributions = async (uid) => {
    const querySnapshot = await getDocs(collection(db, "users", uid, "portfolio", "contributions", "data_points"));

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        const docRef = doc.ref;
        const filteredData = doc
          .data()
          .data.filter((contribution) => !(contribution.status === "Upcoming" && contribution.isRecurring === true));

        // Update the filtered data back to Firestore, ONLY if items have been removed.
        if (doc.data().data.length !== filteredData.length) {
          await updateDoc(docRef, { data: filteredData });
        }
      });
    }
  };

  const recordContributionSchedule = async (payload, uid) => {
    let individualDate = payload.scheduleDetail.hasIndividualContributionSchedule
      ? payload.scheduleDetail.individualContributionSchedule.startDate
      : null;
    let employerDate = payload.scheduleDetail.hasEmployerContributionSchedule
      ? payload.scheduleDetail.employerContributionSchedule.startDate
      : null;
    let individualDateYear = individualDate ? individualDate.slice(0, 4) : null;
    let employerDateYear = employerDate ? employerDate.slice(0, 4) : null;
    removeExistingScheduledContributions(uid);
    const docRef = doc(db, "users", uid, "portfolio", "contributions");
    const indiviudalDataPointRef = individualDate
      ? doc(db, "users", uid, "portfolio", "contributions", "data_points", individualDateYear)
      : null;
    const employerDataPointRef = employerDate
      ? doc(db, "users", uid, "portfolio", "contributions", "data_points", employerDateYear)
      : null;

    // Log the Upcoming Contribution Data Point(s)
    if (individualDate) {
      let iDocSnap = await getDoc(indiviudalDataPointRef);
      let dataPoint = {
        amount: payload.scheduleDetail.individualContributionSchedule.amount,
        date: payload.scheduleDetail.individualContributionSchedule.startDate,
        id: generateUID("contribution", individualDateYear),
        isRecurring: true,
        lastEdit: null,
        status: "Upcoming",
        title: payload.scheduleDetail.individualContributionSchedule.title,
        type: "Individual",
      };
      if (iDocSnap.exists()) {
        // Add new data to doc
        await updateDoc(indiviudalDataPointRef, {
          data: arrayUnion(dataPoint),
        }).catch((error) => {
          console.error(error);
          return error;
        });
      } else {
        // If doc doesn't exist, set the initial data
        await setDoc(indiviudalDataPointRef, {
          data: [dataPoint],
        }).catch((error) => {
          console.error(error);
          return error;
        });
      }
    }
    if (employerDate) {
      let eDocSnap = await getDoc(employerDataPointRef);
      let dataPoint = {
        amount: payload.scheduleDetail.employerContributionSchedule.amount,
        date: payload.scheduleDetail.employerContributionSchedule.startDate,
        id: generateUID("contribution", employerDateYear),
        isRecurring: true,
        lastEdit: null,
        status: "Upcoming",
        title: payload.scheduleDetail.employerContributionSchedule.title,
        type: "Employer",
      };
      if (eDocSnap.exists()) {
        // Add new data to doc
        await updateDoc(employerDataPointRef, {
          data: arrayUnion(dataPoint),
        }).catch((error) => {
          console.error(error);
          return error;
        });
      } else {
        // If doc doesn't exist, set the initial data
        await setDoc(employerDataPointRef, {
          data: [dataPoint],
        }).catch((error) => {
          console.error(error);
          return error;
        });
      }
    }

    await setDoc(
      docRef,
      {
        activeSchedule: payload.activeSchedule,
        scheduleDetail: payload.scheduleDetail,
      },
      { merge: true }
    )
      .then(async () => {
        setContributionSchedule({
          activeSchedule: payload.activeSchedule,
          scheduleDetail: payload.scheduleDetail,
        });
        await getRecentContributions(uid);
        return contributionSchedule;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  const handleAfterSave = async (uid) => {
    try {
      await getAccountValue(uid);
      await getRecentContributions(uid);
      await getInvestmentData(uid);
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const updateAccountValuesAfterChange = async (newDate, oldDate, type, previousAmount, uid, payload) => {
    // Step 1: Pre-flight checks
    const runPreFlightChecks = async () => {
      let impactedYears = [];
      let firstDataPointLogged = null;
      // Retrieve firstDataPointLogged date
      const investmentsDocRef = doc(db, "users", uid, "portfolio", "investments");
      const investmentsDocSnap = await getDoc(investmentsDocRef);
      if (investmentsDocSnap.exists()) {
        firstDataPointLogged = investmentsDocSnap.data().firstDataPointLogged;
      } else {
        throw new Error("First data point logged date is missing from investments document.");
      }

      // Determine earliest date among oldDate, newDate, and potentially firstDataPointLogged
      const date1 = oldDate && parseDateToLocal(oldDate);
      const date2 = parseDateToLocal(newDate);
      const minAllowedDate = firstDataPointLogged && parseDateToLocal(firstDataPointLogged);
      let earliestDate;
      let doChangesPrecedeAccountHistory;
      let noOldDate = true;

      if (oldDate) {
        // Check if either oldDate or newDate precedes firstDataPointLogged
        doChangesPrecedeAccountHistory = date1 < minAllowedDate || date2 < minAllowedDate;
        // If changes precede account history, set earliestDate to firstDataPointLogged
        if (doChangesPrecedeAccountHistory) {
          earliestDate = firstDataPointLogged;
        } else {
          // Otherwise, set earliestDate to the earlier of oldDate and newDate
          earliestDate = date1 < date2 ? oldDate : newDate;
        }

        // Format earliestDate to 'yyyy-mm-dd' if it's a Date object
        if (earliestDate instanceof Date) {
          earliestDate = earliestDate.toISOString().split("T")[0];
        }
        noOldDate = false;
      } else {
        // Check if either oldDate or newDate precedes firstDataPointLogged
        doChangesPrecedeAccountHistory = date2 < minAllowedDate;
        // If changes precede account history, set earliestDate to firstDataPointLogged
        if (doChangesPrecedeAccountHistory) {
          earliestDate = firstDataPointLogged;
        } else {
          // Otherwise, set earliestDate to the earlier of oldDate and newDate
          earliestDate = newDate;
        }

        // Format earliestDate to 'yyyy-mm-dd' if it's a Date object
        if (earliestDate instanceof Date) {
          earliestDate = earliestDate.toISOString().split("T")[0];
        }
      }

      // Extract 'yyyy' from 'yyyy-mm-dd' for year reference
      const yearForEarliestDate = earliestDate.slice(0, 4);

      // // Fetch the initial data point based on earliest year document needed
      const initialYearRef = doc(db, "users", uid, "portfolio", "investments", "data_points", yearForEarliestDate);
      const initialYearDoc = await getDoc(initialYearRef);

      if (initialYearDoc.exists()) {
        const sortedData = initialYearDoc.data().data.toSorted((a, b) => {
          return parseDateToLocal(a.date) - parseDateToLocal(b.date);
        });
        impactedYears.push({ ref: initialYearRef, data: sortedData });
      }

      // Determine the years to update based on the earliest date to the current year
      const yearsToUpdate = new Date().getFullYear() - new Date(earliestDate).getFullYear() + 1;
      for (let i = 1; i < yearsToUpdate; i++) {
        const year = (new Date(earliestDate).getFullYear() + i).toString();
        const yearRef = doc(db, "users", uid, "portfolio", "investments", "data_points", year);
        const yearSnap = await getDoc(yearRef);
        if (yearSnap.exists()) {
          // Sort the data for the current year by date
          const sortedData = yearSnap.data().data.toSorted((a, b) => {
            return parseDateToLocal(a.date) - parseDateToLocal(b.date);
          });
          // Push the sorted data into impactedYears
          impactedYears.push({ ref: yearRef, data: sortedData });
        }
      }

      return { impactedYears, earliestDate, doChangesPrecedeAccountHistory, noOldDate };
    };

    const { impactedYears, earliestDate, doChangesPrecedeAccountHistory, noOldDate } = await runPreFlightChecks();

    let mostRecentDataPoint = impactedYears?.[impactedYears.length - 1]?.data?.slice(-1)[0] || null;

    // Final check to see if newDate or oldDate is equal to today's date and does not have a datapoint.
    // If not, one will be created with the appropriate values.
    const date = new Date();
    const currentDay = dateToStandardDateString(date);

    if (mostRecentDataPoint.date !== currentDay) {
      let todaysDataPoint = {
        accountValue: 0,
        adjustments: 0,
        contributions: 0,
        date: currentDay,
        invested: false,
        investment: null,
        percentChange: 0,
        withdrawals: 0,
      };

      impactedYears?.[impactedYears.length - 1]?.data.push(todaysDataPoint);
      mostRecentDataPoint = todaysDataPoint;
    }

    const maxAllowedDate = mostRecentDataPoint && parseDateToLocal(mostRecentDataPoint.date);

    let date1 = oldDate && parseDateToLocal(oldDate);
    let date2 = parseDateToLocal(newDate);
    let doChangesSucceedPresentDay;
    let allChangesInFuture;
    if (oldDate) {
      doChangesSucceedPresentDay = date1 > maxAllowedDate || date2 > maxAllowedDate;
      allChangesInFuture = date1 > maxAllowedDate && date2 > maxAllowedDate;
    } else {
      doChangesSucceedPresentDay = date2 > maxAllowedDate;
      allChangesInFuture = date2 > maxAllowedDate;
    }

    // Will hold all updates to be pushed to document(s)
    let updatedData = impactedYears;

    function updateAccountValues(dataArray, newDate, oldDate, type, previousAmount, payload, earliestDate) {
      // Convert dates to JavaScript Date objects & parse dates in UTC without time adjustments
      const oldDateObj = oldDate && parseDateToLocal(oldDate);
      const newDateObj = parseDateToLocal(newDate);
      const earliestDateObj = parseDateToLocal(earliestDate);

      // Determine starting date for update
      let startDate;
      if (noOldDate) {
        startDate = newDateObj;
      } else {
        startDate = newDateObj < oldDateObj ? newDateObj : oldDateObj;
      }

      let previousDataPoint;
      let previousIndex;

      // 10. If all changes in the future
      if (allChangesInFuture) {
        console.info("All changes occur in the future, no historical updates necessary");
        return [];
      }

      // Iterate through each year of data
      for (const yearData of dataArray) {
        for (const [index, dataPoint] of yearData.data.entries()) {
          const dataPointDate = parseDateToLocal(dataPoint.date);

          // Skip dates before the earliest date or the calculated start date
          if (dataPointDate < earliestDateObj || dataPointDate < startDate) {
            previousIndex = index;
            continue;
          }

          function areDatesEqual(date1, date2) {
            return date1.getTime() == date2.getTime();
          }

          function adjustWnCValues(type, direction, datapoint, value, newValue) {
            if (type === "withdrawal") {
              // This may seem backwards, but it IS CORRECT. The withdrawal values are passed as positive num from expense payload!
              if (direction === "neg") {
                datapoint.withdrawals = datapoint.withdrawals + value;
              }
              // This may seem backwards, but it IS CORRECT. The withdrawal values are passed as positive num from expense payload!
              if (direction === "pos") {
                datapoint.withdrawals = datapoint.withdrawals - value;
              }
            } else if (type === "contribution") {
              if (direction === "neg") {
                datapoint.contributions = datapoint.contributions - value;
              }
              if (direction === "pos") {
                datapoint.contributions = datapoint.contributions + value;
              }
            }
            return datapoint;
          }

          function recalcAccountValue(previousAccountValue, datapoint) {
            if (datapoint.percentChange < 0) {
              let newValue =
                previousAccountValue * (1 - Math.abs(datapoint.percentChange / 100)) +
                datapoint.contributions +
                datapoint.withdrawals +
                datapoint.adjustments;
              datapoint.accountValue = Number(newValue.toFixed(2));
              return datapoint;
            }
            if (datapoint.percentChange > 0) {
              let newValue =
                previousAccountValue * (1 + datapoint.percentChange / 100) +
                datapoint.contributions +
                datapoint.withdrawals +
                datapoint.adjustments;
              datapoint.accountValue = Number(newValue.toFixed(2));
              return datapoint;
            }
            if (datapoint.percentChange === 0) {
              let newValue =
                previousAccountValue + datapoint.contributions + datapoint.withdrawals + datapoint.adjustments;
              datapoint.accountValue = Number(newValue.toFixed(2));
              return datapoint;
            }
          }
          if (doChangesPrecedeAccountHistory) {
            // 0. If no old date, new date preceding earliest date
            if (noOldDate) {
              // While on the first date (earliestDate) add adjustment to datapoint, then update account value
              if (areDatesEqual(dataPointDate, earliestDateObj)) {
                if (type === "withdrawal") {
                  dataPoint.adjustments = dataPoint.adjustments - payload.amount;
                  dataPoint.accountValue = dataPoint.accountValue - payload.amount;
                } else {
                  dataPoint.adjustments = dataPoint.adjustments + payload.amount;
                  dataPoint.accountValue = dataPoint.accountValue + payload.amount;
                }
              }

              // After first date, update remaining account values through to present day
              if (dataPointDate > earliestDateObj) {
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
            } else {
              // 1. If oldDate precedes earliest data point but newDate does not
              if (oldDateObj < earliestDateObj && earliestDateObj < newDateObj) {
                // While on the first date (earliestDate) add adjustment to datapoint to 'erase' previous adjustment, then update account value
                if (areDatesEqual(dataPointDate, earliestDateObj)) {
                  if (type === "withdrawal") {
                    dataPoint.adjustments = dataPoint.adjustments + previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue + previousAmount;
                  } else {
                    dataPoint.adjustments = dataPoint.adjustments - previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue - previousAmount;
                  }
                }

                // After first date, update remaining values between earliest date and new date
                if (dataPointDate > earliestDateObj && dataPointDate < newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }

                // While on new date, make proper withdrawal/contribution updates, and recalc account value
                if (areDatesEqual(dataPointDate, newDateObj)) {
                  adjustWnCValues(type, "pos", dataPoint, payload.amount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }

                // After new date, update remaining account values through to present day
                if (dataPointDate > newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }

              // 2. If oldDate precedes earliest data point but newDate is equal to earliest data point
              if (oldDateObj < earliestDateObj && earliestDate == newDate) {
                // While on earliest datapoint, back out prior adjustments, update account value, then recalculate with newDate and payload amount
                if (areDatesEqual(dataPointDate, earliestDateObj)) {
                  if (type === "withdrawal") {
                    dataPoint.adjustments = dataPoint.adjustments + previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue + previousAmount;
                  } else {
                    dataPoint.adjustments = dataPoint.adjustments - previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue - previousAmount;
                  }
                  adjustWnCValues(type, "pos", dataPoint, payload.amount);
                  recalcAccountValue(dataPoint.accountValue, dataPoint);
                }

                // After earliest datapoint, update remaining account values through to present day
                if (dataPointDate > newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }

              // 3. If newDate precedes earliest data point but oldDate does not
              if (oldDateObj > earliestDateObj && earliestDateObj > newDateObj) {
                // While on the first date (earliestDate) add adjustment to datapoint, then update account value
                if (areDatesEqual(dataPointDate, earliestDateObj)) {
                  if (type === "withdrawal") {
                    dataPoint.adjustments = dataPoint.adjustments - payload.amount;
                    dataPoint.accountValue = dataPoint.accountValue - payload.amount;
                  } else {
                    dataPoint.adjustments = dataPoint.adjustments + payload.amount;
                    dataPoint.accountValue = dataPoint.accountValue + payload.amount;
                  }
                }

                // While between new date and old date
                if (dataPointDate > earliestDateObj && dataPointDate < oldDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }

                // While on the old date
                if (areDatesEqual(dataPointDate, oldDateObj)) {
                  adjustWnCValues(type, "neg", dataPoint, previousAmount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }

                // After old date, update remaining account values through to present day
                if (dataPointDate > oldDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }

              // 4. If newDate precedes earliest data point but oldDate is equal to earliest data point
              if (oldDate == earliestDate && earliestDateObj > newDateObj) {
                // While on earliest datapoint, back out prior adjustments, update account value, then recalculate with newDate and payload amount
                if (areDatesEqual(dataPointDate, earliestDateObj)) {
                  adjustWnCValues(type, "neg", dataPoint, previousAmount);
                  if (type === "withdrawal") {
                    dataPoint.accountValue = dataPoint.accountValue + previousAmount;
                    dataPoint.adjustments = dataPoint.adjustments - payload.amount;
                  } else {
                    dataPoint.accountValue = dataPoint.accountValue - previousAmount;
                    dataPoint.adjustments = dataPoint.adjustments + payload.amount;
                  }
                  recalcAccountValue(dataPoint.accountValue, dataPoint);
                }

                // After earliest datapoint, update remaining account values through to present day
                if (dataPointDate > earliestDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }

              // 5. If both oldDate and newDate precedes earliest data point
              if (oldDateObj < earliestDateObj && newDateObj < earliestDateObj) {
                // Back out prior adjustment amount and add in new adjustment amount
                if (areDatesEqual(dataPointDate, earliestDateObj)) {
                  if (type === "withdrawal") {
                    dataPoint.adjustments = dataPoint.adjustments + previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue + previousAmount;
                    dataPoint.adjustments = dataPoint.adjustments - payload.amount;
                    dataPoint.accountValue = dataPoint.accountValue - payload.amount;
                  } else {
                    dataPoint.adjustments = dataPoint.adjustments - previousAmount;
                    dataPoint.accountValue = dataPoint.accountValue - previousAmount;
                    dataPoint.adjustments = dataPoint.adjustments + payload.amount;
                    dataPoint.accountValue = dataPoint.accountValue + payload.amount;
                  }
                }
                // After earliest datapoint, update remaining account values through to present day
                if (dataPointDate > earliestDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }
            }
          } else if (doChangesSucceedPresentDay) {
            // 11. If oldDate && oldDate is on or after first datapoint but on or before last datapoint
            if (!noOldDate && oldDateObj >= earliestDateObj && oldDateObj <= maxAllowedDate) {
              // Adjustments when equal to old date
              if (areDatesEqual(dataPointDate, oldDateObj)) {
                adjustWnCValues(type, "neg", dataPoint, previousAmount);
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
              // Adjustments while between old date and new date -- given that new date is in future, this will run through the rest of the datapoints
              if (dataPointDate > oldDateObj) {
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
            }

            // 12. If newDate is on or after first datapoint but on or before last datapoint
            if (newDateObj >= earliestDateObj && newDateObj <= maxAllowedDate) {
              // Adjustments when equal to newDate
              if (areDatesEqual(dataPointDate, newDateObj)) {
                adjustWnCValues(type, "pos", dataPoint, payload.amount);
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
              // Adjustments while between new date and old date -- given that old date is in future, this will run through the rest of the datapoints
              if (dataPointDate > newDateObj) {
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
            }
          } else {
            // 6. If no old date, new date is after earliest date
            if (noOldDate) {
              if (areDatesEqual(dataPointDate, newDateObj)) {
                adjustWnCValues(type, "pos", dataPoint, payload.amount);
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
              if (dataPointDate > newDateObj) {
                recalcAccountValue(
                  previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                  dataPoint
                );
              }
            } else {
              // 7. If oldDate and newDate are equal
              if (areDatesEqual(newDateObj, oldDateObj)) {
                // If the amounts are the same, return
                if (previousAmount === payload.amount) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                } else {
                  // Adjustments when equal to new date
                  if (areDatesEqual(dataPointDate, newDateObj)) {
                    adjustWnCValues(type, "neg", dataPoint, previousAmount);
                    adjustWnCValues(type, "pos", dataPoint, payload.amount);
                    recalcAccountValue(
                      previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                      dataPoint
                    );
                  }
                  // Adjustments while between new date and present day
                  if (dataPointDate > newDateObj) {
                    recalcAccountValue(
                      previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                      dataPoint
                    );
                  }
                }
              }

              // 8. If oldDate precedes newDate
              if (oldDateObj < newDateObj) {
                // Adjustments when equal to old date
                if (areDatesEqual(dataPointDate, oldDateObj)) {
                  adjustWnCValues(type, "neg", dataPoint, previousAmount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
                // Adjustments while between old date and new date
                if (oldDateObj < newDateObj && dataPointDate > oldDateObj && dataPointDate < newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
                // Adjustments when equal to new date
                if (areDatesEqual(dataPointDate, newDateObj)) {
                  adjustWnCValues(type, "pos", dataPoint, payload.amount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
                // Adjustments while between new date and present day
                if (dataPointDate > newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }

              // 9. If newDate precedes oldDate
              if (newDateObj < oldDateObj) {
                // Adjustments when equal to new date
                if (areDatesEqual(dataPointDate, newDateObj)) {
                  adjustWnCValues(type, "pos", dataPoint, payload.amount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }

                // Adjustments while between new date and old date
                if (dataPointDate < oldDateObj && dataPointDate > newDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
                // Adjustments when equal to old date
                if (areDatesEqual(dataPointDate, oldDateObj)) {
                  adjustWnCValues(type, "neg", dataPoint, previousAmount);
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
                // Adjustments while between old date and present day
                if (dataPointDate > oldDateObj) {
                  recalcAccountValue(
                    previousDataPoint ? previousDataPoint.accountValue : yearData.data[previousIndex].accountValue,
                    dataPoint
                  );
                }
              }
            }
          }

          previousDataPoint = dataPoint;
        }
      }

      return dataArray; // Return the modified array
    }

    const completedHistoricalUpdates = updateAccountValues(
      updatedData,
      newDate,
      oldDate,
      type,
      previousAmount,
      payload,
      earliestDate
    );

    if (completedHistoricalUpdates.length < 1) {
      return;
    } else {
      completedHistoricalUpdates.forEach(async (doc) => {
        await setDoc(doc.ref, {
          data: doc.data,
        }).catch((error) => {
          console.error(error);
        });
      });
    }
  };

  const recordOneTimeContribution = async (payload, uid) => {
    // Gets year from contribution to determine data_point to pull for proper updating
    let year = payload.date.slice(0, 4);

    // Reference for the CONTRIBUTION data_point doc
    const docRef = doc(db, "users", uid, "portfolio", "contributions", "data_points", year);

    // Log the Contribution Data Point
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Add new data to doc
      await updateDoc(docRef, {
        data: arrayUnion(payload),
      })
        .then(async () => {
          await updateAccountValuesAfterChange(payload.date, null, "contribution", null, uid, payload).then(() => {
            handleAfterSave(uid);
          });
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    } else {
      // If doc doesn't exist, set the initial data
      await setDoc(docRef, {
        data: [payload],
      })
        .then(async () => {
          await updateAccountValuesAfterChange(payload.date, null, "contribution", null, uid, payload).then(() => {
            handleAfterSave(uid);
          });
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    }
  };

  const updateIndividualContribution = async (newDate, oldDate, previousAmount, uid, payload, previousYear) => {
    // Gets year from contribution to determine data_point to pull for proper updating
    let year = payload.date.slice(0, 4);

    // Reference for the CONTRIBUTION data_point doc
    const docRef = doc(db, "users", uid, "portfolio", "contributions", "data_points", year);

    // Reference for the old contribuiton doc, if not from same year as update
    if (year != previousYear) {
      const oldDocRef = doc(db, "users", uid, "portfolio", "contributions", "data_points", previousYear);

      const oldDocSnap = await getDoc(oldDocRef);
      let oldYearData = [];

      if (oldDocSnap.exists()) {
        oldYearData = oldDocSnap.data();

        const filteredData = oldYearData.data.filter((contribution) => contribution.id !== payload.id);

        await setDoc(oldDocRef, {
          data: filteredData,
        }).catch((error) => {
          console.error(error);
        });
      }
    }

    // Update the Contribution Data Point
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let docData = docSnap.data();
      let filteredNewData = [];

      if (year == previousYear) {
        filteredNewData = docData.data.filter((contribution) => contribution.id !== payload.id);
        filteredNewData.push(payload);
      } else {
        filteredNewData = docData.data;
        filteredNewData.push(payload);
      }

      // Add new data to doc
      await updateDoc(docRef, {
        data: filteredNewData,
      })
        .then(async () => {
          await updateAccountValuesAfterChange(newDate, oldDate, "contribution", previousAmount, uid, payload)
            .then(() => {
              handleAfterSave(uid);
              notify("Your contribution has been successfully updated.", "success");
              return payload;
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    } else {
      // If doc doesn't exist, set the initial data
      await setDoc(docRef, {
        data: [payload],
      })
        .then(async () => {
          await updateAccountValuesAfterChange(newDate, oldDate, "contribution", previousAmount, uid, payload)
            .then(() => {
              handleAfterSave(uid);
              notify("Your contribution has been successfully updated.", "success");
              return payload;
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    }
  };

  const deleteIndividualContribution = async (payload, uid) => {
    // Gets year from contribution to determine data_point to pull for proper deletion
    let year = payload.date.slice(0, 4);

    // Reference for the CONTRIBUTION data_point doc
    const docRef = doc(db, "users", uid, "portfolio", "contributions", "data_points", year);

    // Delete the Contribution Data Point
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let docData = docSnap.data();
      let filteredData = [];

      filteredData = docData.data.filter((contribution) => contribution.id !== payload.id);

      // Add new data to doc
      await updateDoc(docRef, {
        data: filteredData,
      })
        .then(async () => {
          await updateAccountValuesAfterChange(payload.date, payload.date, "contribution", payload.amount, uid, {
            amount: 0,
          })
            .then(() => {
              handleAfterSave(uid);
              notify("Your contribution has been successfully deleted.", "danger");
              return payload;
            })
            .catch((error) => {
              console.error(error);
              return error;
            });
        })
        .catch((error) => {
          console.error(error);
          return error;
        });
    }
  };

  const getContributionSchedule = async (uid) => {
    setContributionScheduleLoading(true);
    const docRef = doc(db, "users", uid, "portfolio", "contributions");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setContributionSchedule({
        activeSchedule: docSnap.data().activeSchedule,
        scheduleDetail: docSnap.data().scheduleDetail,
      });
      setContributionScheduleLoading(false);
    } else {
      setContributionSchedule(null);
      setContributionScheduleLoading(false);
    }
  };

  const getRecentContributions = async (uid) => {
    setLoadingContributions(true);
    let yearDataPoints = [];
    let allDataPoints = [];
    let currentYear = new Date().getFullYear().toString();
    const querySnapshot = await getDocs(collection(db, "users", uid, "portfolio", "contributions", "data_points"));
    if (querySnapshot.empty) {
      setContributions(allDataPoints);
      setCurrentYearContributions(yearDataPoints);
      setNoContributions(true);
      setLoadingContributions(false);
      return allDataPoints;
    } else {
      querySnapshot.forEach(async (doc) => {
        await doc.data().data.forEach((contribution) => {
          if (contribution.date.slice(0, 4) === currentYear) {
            yearDataPoints.push(contribution);
          }
          allDataPoints.push(contribution);
        });
      });
      function dateComparison(a, b) {
        const date1 = new Date(a.date);
        const date2 = new Date(b.date);

        return date2 - date1;
      }
      allDataPoints.sort(dateComparison);
      yearDataPoints.sort(dateComparison);
      setContributions(allDataPoints);
      setCurrentYearContributions(yearDataPoints);
      setNoContributions(false);
      setLoadingContributions(false);
      return contributions;
    }
  };

  const updateInvestmentStatus = async (payload, uid) => {
    const docRef = doc(db, "users", uid, "portfolio", "investments");
    await setDoc(
      docRef,
      {
        activeInvestments: payload.activeInvestments,
        dataLabel: payload.dataLabel,
        interestRate: +payload.interestRate,
        symbol: payload.symbol,
        indexTitle: payload.indexTitle,
      },
      { merge: true }
    )
      .then(() => {
        setInvestmentStatus({
          activeInvestments: payload.activeInvestments,
          dataLabel: payload.dataLabel,
          interestRate: +payload.interestRate,
          symbol: payload.symbol,
          indexTitle: payload.indexTitle,
        });
        return investmentStatus;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  const getInvestmentStatus = async (uid) => {
    setLoadingInvestmentStatus(true);
    const docRef = doc(db, "users", uid, "portfolio", "investments");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setInvestmentStatus(docSnap.data());
      setLoadingInvestmentStatus(false);
    } else {
      setInvestmentStatus({
        activeInvestments: false,
        symbol: null,
        indexTitle: null,
        dataLabel: null,
      });
      setLoadingInvestmentStatus(false);
    }
  };

  const getInvestmentData = async (uid) => {
    setLoadingInvestmentData(true);
    let datapointDocs = [];
    const querySnapshot = await getDocs(collection(db, "users", uid, "portfolio", "investments", "data_points"));
    if (querySnapshot.empty) {
      setInvestmentData(datapointDocs);
      setNoInvestmentData(true);
      setLoadingInvestmentData(false);
      return investmentData;
    } else {
      querySnapshot.forEach((doc) => {
        datapointDocs.push(doc.data());
      });
      setInvestmentData(datapointDocs);
      setNoInvestmentData(false);
      setLoadingInvestmentData(false);
      return investmentData;
    }
  };

  useEffect(() => {
    if (colorMode && colorMode === "system") {
      currentSystemTheme.addEventListener("change", (e) => {
        if (e.matches) {
          handleColorMode("system");
        } else {
          handleColorMode("system");
        }
      });
    }

    return () => {
      currentSystemTheme.removeEventListener("change", (e) => {
        if (e.matches) {
          handleColorMode("system");
        } else {
          handleColorMode("system");
        }
      });
    };
  }, [colorMode]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      setAuthLoading(false);
      if (user) {
        setActiveUser(user.uid);
        if (!isNewUser) {
          applyPreferences(user.uid);
          verifySubscription(user.uid);
        }
        if (
          !currentTermsVersion &&
          currentTermsVersion !== null &&
          !currentPrivacyVersion &&
          currentPrivacyVersion !== null
        ) {
          getCurrentAgreementVersions();
        }
        if (!annualData) {
          await getAnnualContributionAndLimitData();
        }
        if (!hsaExpenseGuide) {
          await getHSAExpenseGuide();
        }
        if (!expenses) {
          await getExpenses(user.uid);
        }
        if (!totalAccountValue) {
          await getAccountValue(user.uid);
        }
        if (!contributionSchedule || !contributions) {
          await getContributionSchedule(user.uid);
          await getRecentContributions(user.uid);
        }
      } else {
        // sets light-mode to keep external pages consistent whenever unauthenticated
        handleColorMode("light");
      }
    });
    return unsubscribe;
  }, [currentTermsVersion, activeTerms]);

  const value = {
    getLegalDocs,
    legalDocs,
    loadingDocs,
    sanityClient,
    releaseNotes,
    getReleaseNotes,
    helpCategories,
    getHelpCategories,
    helpDocuments,
    getHelpDocuments,
    getCurrentAgreementVersions,
    currentTermsVersion,
    currentPrivacyVersion,
    activeTerms,
    currentUser,
    activeUserProfile,
    welcomeDaysOptOut,
    subscriptionDetails,
    authLoading,
    loadingCheckout,
    setLoadingCheckout,
    setNewPassword,
    openCheckout,
    verifySubscription,
    loadingVerifiedSubscription,
    updateName,
    updateCoverageType,
    updateDateOfBirth,
    updateProfilePhoto,
    deleteProfilePhoto,
    setActiveUser,
    signUp,
    createAccount,
    signIn,
    signOut,
    getPasswordResetLink,
    applyPreferences,
    updateColorPreference,
    colorMode,
    updateWeightingPreference,
    weightingMethod,
    getAnnualContributionAndLimitData,
    annualData,
    getHSAExpenseGuide,
    hsaExpenseGuide,
    hasGuide,
    getExpenses,
    addExpense,
    updateExpense,
    deleteExpense,
    addExpenseLoading,
    updateExpenseLoading,
    deleteExpenseLoading,
    expenses,
    loadingExpenses,
    noExpenses,
    totalAccountValue,
    totalAccountLoading,
    getAccountValue,
    writeAccountValue,
    contributionSchedule,
    contributionScheduleLoading,
    getContributionSchedule,
    recordContributionSchedule,
    recordOneTimeContribution,
    updateIndividualContribution,
    deleteIndividualContribution,
    getRecentContributions,
    loadingContributions,
    noContributions,
    contributions,
    currentYearContributions,
    updateInvestmentStatus,
    getInvestmentStatus,
    loadingInvestmentStatus,
    investmentStatus,
    getInvestmentData,
    loadingInvestmentData,
    investmentData,
    noInvestmentData,
    updateAccountValuesAfterChange,
  };

  return <AuthContext.Provider value={value}>{!authLoading && children}</AuthContext.Provider>;
}
