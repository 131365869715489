import { getFunctions, httpsCallable } from "firebase/functions";

export const openCustomerPortal = async () => {
  const functions = getFunctions();
  const createPortalLink = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");

  const { data } = await createPortalLink({
    returnUrl: `${window.location.origin}/profile/subscription`,
  });

  window.location.assign(data.url);
};
