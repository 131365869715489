import { collection, onSnapshot, addDoc } from "firebase/firestore";
import { db } from "../firebase";

const createCheckoutSession = async (uid, plan, location, setLoadingCheckout) => {
  setLoadingCheckout(true);
  let price;
  if (plan === "monthly") {
    price = process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID;
  } else {
    price = process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID;
  }
  let successUrl =
    location === "create-account"
      ? `${window.location.origin}/welcome`
      : `${window.location.origin}/profile/subscription`;
  let cancelUrl =
    location === "create-account"
      ? `${window.location.origin}/welcome`
      : `${window.location.origin}/profile/subscription`;
  // create checkout session subcollection in the user's document
  const collectionRef = collection(db, "users", uid, "checkout_sessions");
  let sessionRef;
  if (location === "established-account") {
    // Removes Trial from Checkout Session Flow
    sessionRef = await addDoc(collectionRef, {
      price: price,
      success_url: successUrl,
      cancel_url: cancelUrl,
    });
  } else {
    sessionRef = await addDoc(collectionRef, {
      price: price,
      trial_period_days: 7,
      success_url: successUrl,
      cancel_url: cancelUrl,
    });
  }
  const unsubscribe = onSnapshot(sessionRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      console.error(error);
      setLoadingCheckout(false);
      return unsubscribe();
    }
    if (url) {
      window.location.assign(url);
      setLoadingCheckout(false);
      return unsubscribe();
    }
  });
};

export { createCheckoutSession };
