import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  currencyDeformatter,
  currencyFormatter,
  currencyHandler,
  currencyInputHandling,
} from "../../../../hooks-n-utils/currency";
import { shortDateHandler, shorterDateHandler } from "../../../../hooks-n-utils/date";
import {
  getCollapsedViewContributionRecords,
  getExpandedViewContributionRecords,
  handleNextTwoDates,
} from "../../../../hooks-n-utils/scheduler";
import {
  TbBallpen,
  TbChevronRight,
  TbClockHour7,
  TbCircleCheck,
  TbLoader,
  TbProgressCheck,
  TbRotate2,
  TbSquareRoundedLetterE,
  TbSum,
  TbTag,
  TbBolt,
} from "react-icons/tb";
import { FaFont } from "react-icons/fa6";
import { GoDot } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { generateUID } from "../../../../hooks-n-utils/uid-generator";

const ContributionSchedule = ({ showFullHistory }) => {
  const { contributions, updateIndividualContribution, deleteIndividualContribution, currentUser } = useAuth();

  const [expandedRow, setExpandedRow] = useState();
  const [contributionsData, setContributionsData] = useState();
  const [showingContributionTable, setShowingContributionTable] = useState();

  const [isEditing, setIsEditing] = useState(false);
  const [editingContribution, setEditingContribution] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [title, setTitle] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState(true);
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [lastEdit, setLastEdit] = useState("");

  const [collapsedView, setCollapsedView] = useState();

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  useEffect(() => {}, [afterFirstVisit]);

  const updateWidth = () => {
    if (document.querySelector(".contribution-table")) {
      setCollapsedView(document.querySelector(".contribution-table").getBoundingClientRect().width < 385);
    }
  };

  useEffect(() => {
    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (contributions) {
      if (!showFullHistory) {
        setExpandedRow();
        setEditingContribution(null);
        setIsEditing(false);
        setConfirmDelete(false);
        setContributionsData(getCollapsedViewContributionRecords(contributions));
      } else {
        setContributionsData(getExpandedViewContributionRecords(contributions));
        handleRowExpansion(getExpandedViewContributionRecords(contributions)[0]);
      }
    }
  }, [showFullHistory, contributions]);

  useEffect(() => {}, []);

  const handleRowExpansion = (contribution) => {
    setEditingContribution(contribution);
    setIsEditing(false);
    if (contribution === expandedRow) {
      setExpandedRow();
    } else {
      setExpandedRow(contribution);
    }
  };

  const mapFormToSelectedContribution = (contribution) => {
    setTitle(contribution.title);
    setStatus(contribution.status);
    setType(contribution.type);
    setDate(contribution.date);
    setAmount(currencyFormatter(String(contribution.amount)));
    setLastEdit(contribution.lastEdit ? contribution.lastEdit : "–");
  };

  const toggleEdit = (contribution) => {
    if (!isEditing) {
      mapFormToSelectedContribution(contribution);
    }
    if (confirmDelete) {
      setConfirmDelete(false);
    }
    setEditingContribution(contribution ? contribution : null);
    setIsEditing(!isEditing);
  };

  const updateContribution = async (contribution) => {
    const dt = new Date();

    const currentSavedYear = contribution.date.slice(0, 4);

    let year = dt.getFullYear();
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let day = dt.getDate().toString().padStart(2, "0");

    const editTs = year + "-" + month + "-" + day;

    let payload = {
      id: contribution.id,
      date: date,
      isRecurring: contribution.isRecurring,
      lastEdit: editTs,
      status: status,
      title: title,
      amount: currencyDeformatter(amount),
      type: type,
    };

    await updateIndividualContribution(
      date,
      contribution.date,
      contribution.amount,
      currentUser.uid,
      payload,
      currentSavedYear
    ).then((newContribution) => {
      toggleEdit(newContribution);
      handleRowExpansion(newContribution);
    });
  };

  const toggleDelete = () => {
    setConfirmDelete(!confirmDelete);
  };

  const deleteContribution = (contribuiton) => {
    deleteIndividualContribution(contribuiton, currentUser.uid).then(() => {
      toggleDelete();
    });
  };

  return (
    <section className="contribution-table">
      <table className={afterFirstVisit ? "" : "animated"}>
        <tbody>
          {contributionsData?.map((contribution, index) => {
            if (isEditing && editingContribution.id && contribution.id === editingContribution.id) {
              return (
                <tr className="edit-contribution-form" key={"contribution-" + index}>
                  <td colSpan={3}>
                    <div className="full-width">
                      <small className="first">
                        <FaFont /> Contribution Title
                      </small>
                      <input value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="split-width">
                      <div>
                        <small>
                          <TbLoader /> Status
                        </small>
                        <sl-select placeholder="Select Status" id="status-select" value={status} filled>
                          <sl-option
                            key="status-upcoming"
                            value="Upcoming"
                            onClick={() => {
                              setStatus("Upcoming");
                            }}
                          >
                            Upcoming
                          </sl-option>
                          <sl-option
                            key="status-completed"
                            value="Completed"
                            onClick={() => {
                              setStatus("Completed");
                            }}
                          >
                            Completed
                          </sl-option>
                          <sl-option
                            key="status-skipped"
                            value="Skipped"
                            onClick={() => {
                              setStatus("Skipped");
                            }}
                          >
                            Skipped
                          </sl-option>
                        </sl-select>
                      </div>
                      <div className="type-block">
                        <small>
                          <TbTag /> Employer?
                        </small>
                        <sl-radio-group value={type}>
                          <sl-radio-button onClick={() => setType("Employer")} value={"Employer"}>
                            Yes
                          </sl-radio-button>
                          <sl-radio-button
                            onClick={() => {
                              setType("Invididual");
                            }}
                            value={"Individual"}
                          >
                            No
                          </sl-radio-button>
                        </sl-radio-group>
                      </div>
                    </div>
                    <div className="half-width">
                      <div className="input-block first-item">
                        <small>
                          <TbSum /> Amount
                        </small>
                        <span className="blocks-currency-input">
                          <span className="dollar-sign">$</span>
                          <input
                            className="amount"
                            value={amount}
                            onClick={(e) => e.target.select()}
                            onChange={(e) => setAmount(currencyInputHandling(e.target.value))}
                            onBlur={(e) => {
                              if (e.target.value !== "") {
                                setAmount(currencyInputHandling(e.target.value, "blur"));
                              }
                            }}
                          />
                        </span>
                      </div>
                      <div className="input-block">
                        <small>
                          <TbProgressCheck /> {contribution.status === "Upcoming" ? "Scheduled" : "Recorded"}
                        </small>
                        <input
                          type="date"
                          onBlur={(e) => setDate(e.target.value)}
                          onChange={(e) => setDate(e.target.value)}
                          value={date ? date : ""}
                        ></input>
                      </div>
                    </div>

                    <div className="split-width buttons">
                      <sl-button onClick={() => toggleEdit(contribution)} variant="neutral">
                        Cancel
                      </sl-button>
                      <sl-button onClick={() => updateContribution(contribution)}>Update Contribution</sl-button>
                    </div>
                    <hr />
                  </td>
                </tr>
              );
            } else {
              return (
                <Fragment key={"contribution-" + index}>
                  <tr
                    className={
                      contribution.status !== "Upcoming"
                        ? contribution.status === "Completed"
                          ? "header-section completed"
                          : "header-section skipped"
                        : "header-section upcoming"
                    }
                    id={afterFirstVisit ? "" : "animated"}
                    onClick={() => showFullHistory && handleRowExpansion(contribution)}
                  >
                    <td className={showFullHistory ? "expandable" : null}>
                      <span
                        className={
                          expandedRow === contribution
                            ? "main-info-header-wrapper expanded"
                            : "main-info-header-wrapper"
                        }
                      >
                        <small className={expandedRow === contribution ? "showing" : ""}>
                          <TbLoader /> Status
                        </small>
                      </span>
                      <span className="static-cell-content header">
                        {collapsedView ? (
                          contribution?.status === "Upcoming" ? (
                            <TbClockHour7 className="main-status-icon" />
                          ) : (
                            <TbCircleCheck className="main-status-icon" />
                          )
                        ) : (
                          contribution?.status
                        )}
                        {expandedRow !== contribution && contribution.type === "Employer" && (
                          <TbSquareRoundedLetterE className="employer-indicator" />
                        )}
                        {contribution?.isRecurring && <TbRotate2 className="recurring-indicator" />}
                        {!contribution?.isRecurring && <GoDot className="one-time-indicator" />}
                      </span>
                    </td>
                    <td className={showFullHistory ? "expandable amount" : "amount"}>
                      <span
                        className={
                          expandedRow === contribution
                            ? "main-info-header-wrapper expanded"
                            : "main-info-header-wrapper"
                        }
                      >
                        <small className={expandedRow === contribution ? "showing" : ""}>
                          <TbSum /> Total
                        </small>
                      </span>
                      <span className="static-cell-content number value">
                        {currencyHandler(contributions ? contribution?.amount : 0)}
                      </span>
                    </td>
                    <td className={showFullHistory ? "expandable date" : "date"}>
                      <span
                        className={
                          expandedRow === contribution
                            ? "main-info-header-wrapper expanded date"
                            : "main-info-header-wrapper date"
                        }
                      >
                        <small className={expandedRow === contribution ? "showing" : ""}>
                          <TbProgressCheck /> {contribution.status === "Upcoming" ? "Scheduled" : "Recorded"}
                        </small>
                      </span>
                      <span className="static-cell-content number date value">
                        {contributionsData.length > 0
                          ? collapsedView
                            ? shorterDateHandler(contribution?.date)
                            : shortDateHandler(contribution?.date)
                          : null}
                        <span
                          className={
                            showFullHistory ? "more-info-chevron-wrapper expanded" : "more-info-chevron-wrapper"
                          }
                        >
                          <div className="toggle-icon">
                            <TbChevronRight className={expandedRow === contribution ? "open" : ""} />
                          </div>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr
                    className={showFullHistory ? "expansion-section expandable" : "expansion-section"}
                    id={afterFirstVisit ? "" : "animated"}
                  >
                    <td colSpan="3">
                      <section
                        className={
                          expandedRow === contribution
                            ? "additional-information-wrapper expanded"
                            : "additional-information-wrapper"
                        }
                      >
                        <div
                          className={
                            contribution.status === "Upcoming" ? "expanded-content upcoming" : "expanded-content"
                          }
                        >
                          <div className="title-section">
                            <div>
                              <small className={expandedRow === contribution ? "visible" : ""}>
                                <FaFont /> Contribution Title
                              </small>
                              <span className="static-cell-content title">{contribution?.title}</span>
                            </div>
                            {(contribution.status !== "Upcoming" || contribution.lastEdit) && (
                              <div className="last-edit-block">
                                <small className={expandedRow === contribution ? "visible" : ""}>
                                  <TbBallpen /> Last Edit
                                </small>
                                <span className="static-cell-content last-edit">
                                  {contribution?.lastEdit ? contribution?.lastEdit : "–"}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="metadata-n-control-section">
                            <div>
                              <small className={expandedRow === contribution ? "visible" : ""}>
                                <TbTag /> Type
                              </small>
                              <span className="static-cell-content type-value">{contribution?.type}</span>
                            </div>
                            <div className="actions-block">
                              <small className={expandedRow === contribution ? "visible" : ""}>
                                <TbBolt /> Actions
                              </small>
                              <div className="action-btns">
                                <sl-button
                                  class={
                                    confirmDelete
                                      ? "blocks-pill-btn delete-cancel expanded"
                                      : "blocks-pill-btn delete-cancel"
                                  }
                                  onClick={() => (confirmDelete ? toggleDelete() : toggleEdit(contribution))}
                                  variant={confirmDelete ? "neutral" : "default"}
                                  pill
                                >
                                  {confirmDelete ? <IoClose /> : "Edit"}
                                </sl-button>
                                <sl-button
                                  class={
                                    confirmDelete
                                      ? "blocks-pill-btn delete-main expanded"
                                      : "blocks-pill-btn delete-main"
                                  }
                                  onClick={() => (confirmDelete ? deleteContribution(contribution) : toggleDelete())}
                                  variant="danger"
                                  pill
                                >
                                  {confirmDelete ? "Confirm Delete" : "Delete"}
                                </sl-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </td>
                  </tr>
                </Fragment>
              );
            }
          })}
        </tbody>
      </table>
      {!showFullHistory && (
        <>
          <div className={afterFirstVisit ? "table-legend" : "table-legend animated"}>
            <small className="one-time">
              <GoDot /> One-Time
            </small>
            <small>
              <TbRotate2 /> Recurring
            </small>
            <small>
              <TbSquareRoundedLetterE /> Employer
            </small>
          </div>
          {collapsedView && (
            <div className={afterFirstVisit ? "table-legend" : "table-legend animated"}>
              <small>
                <TbClockHour7 /> Upcoming
              </small>
              <small>
                <TbCircleCheck /> Completed
              </small>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export { ContributionSchedule };
