import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "./Loader";

const Feedback = () => {
  const { activeUserProfile } = useAuth();

  // Function to load the Canny widget
  const loadCannyWidget = () => {
    if (window.Canny) {
      window.Canny("render", {
        boardToken: process.env.REACT_APP_CANNY_BOARD_TOKEN, // Pass your Canny board token
        basePath: "/feedback", // Adjust if needed
        sso: false, // Set to true if using Single Sign-On
        container: "#canny-widget-container",
      });
    }
  };

  useEffect(() => {
    // Load Canny SDK
    if (!document.getElementById("canny-jssdk")) {
      const script = document.createElement("script");
      script.id = "canny-jssdk";
      script.src = "https://canny.io/sdk.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.Canny && activeUserProfile) {
          window.Canny("identify", {
            appID: process.env.REACT_APP_CANNY_APP_ID,
            user: {
              email: activeUserProfile.email,
              name: `${activeUserProfile.firstName} ${activeUserProfile.lastName.slice(0, 1)}.`,
              id: activeUserProfile.uid,
              avatarURL: activeUserProfile.picture ? activeUserProfile.picture : "",
            },
          });
          loadCannyWidget();
        }
      };
    } else if (window.Canny && activeUserProfile) {
      window.Canny("identify", {
        appID: process.env.REACT_APP_CANNY_APP_ID,
        user: {
          email: activeUserProfile.email,
          name: `${activeUserProfile.firstName} ${activeUserProfile.lastName.slice(0, 1)}.`,
          id: activeUserProfile.uid,
          avatarURL: activeUserProfile.picture ? activeUserProfile.picture : "",
        },
      });
      loadCannyWidget();
    }

    return () => {
      if (window.Canny) {
        window.Canny("identify", null); // Clears the session
      }
    };
  }, [activeUserProfile]);

  return (
    <div className="feedback-page">
      <section className="title">
        <h1>Feedback</h1>
        {/* {isDesktop && (
          <sl-button
            id="shoebox-feedback-desktop-submit-button"
            onClick={() =>
              submitFeedback(
                name,
                email,
                phone,
                feedbackType,
                appArea,
                issueSeverity,
                notes,
                screenshot,
                followUpAllowed,
                followUpContactMethod
              )
            }
            class="submit-feedback-button"
          >
            Submit Feedback
          </sl-button>
        )} */}
      </section>
      <section className="feedback-widget-wrapper">
        {activeUserProfile ? <div data-canny id="canny-widget-container"></div> : <Loader />}
      </section>
    </div>
  );
};

export { Feedback };
