import React, { useEffect, useState } from "react";
import { SignUp } from "../authentication/SignUp";
import { TbSwitch } from "react-icons/tb";
import Shoebox from "../../assets/Shoebox_Filebox.webp";
import { useLocation } from "react-router-dom";
import { Pricing } from "./Pricing";

const CreateAccount = () => {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [headerHeight, setHeaderHeight] = useState();
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  const getHeaderHeight = () => {
    let header = document.querySelector(".create-account-header");
    if (header) {
      setHeaderHeight(header.getBoundingClientRect().height);
    }
    setIsMobile(window.innerWidth < 740);
  };

  window.addEventListener("resize", getHeaderHeight);

  useEffect(() => {
    getHeaderHeight();
    if (location.state && window.sessionStorage.getItem("lastPosition") === "/") {
      setSelectedPlan(location.state.selectedPlan);
    }

    return () => window.removeEventListener("resize", getHeaderHeight);
  }, [selectedPlan]);

  return (
    <div id="create-account-container">
      <div className="create-account-header">
        <div className="left-header">
          <h1>{"Let's Get Started."}</h1>
          <span></span>
          {selectedPlan ? (
            <div>
              <div className="subheader-wrapper">
                <span className="selected-plan-card subheader">Add your details to finalize your account.</span>
              </div>
            </div>
          ) : (
            <span className="selected-plan-card subheader">Select a plan to continue.</span>
          )}
        </div>
        {selectedPlan && (
          <div>
            <div className="plan-card-wrapper">
              <div className="selected-plan-card">
                <div>
                  <img src={Shoebox} />
                </div>
                <div className="selected-details">
                  <h2>{selectedPlan}</h2>
                  <div>
                    {selectedPlan === "Monthly" ? "$9" : "$89"}{" "}
                    <span>{selectedPlan === "Monthly" ? "per month" : "billed annually"}</span>
                  </div>
                </div>
              </div>
              <a className="swap-plan-button" onClick={() => setSelectedPlan("")}>
                {isMobile ? <TbSwitch /> : "switch plan"}
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="page-content" style={{ marginTop: `${headerHeight + 60}px` }}>
        {selectedPlan ? (
          <SignUp selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
        ) : (
          <section id="pricing">
            <Pricing setSelectedPlan={setSelectedPlan} />
          </section>
        )}
      </div>
    </div>
  );
};

export { CreateAccount };
