export const currencyHandler = (amount) => {
  return (
    "$" +
    parseFloat(amount).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
};

export const currencyHandlerSansDollarSign = (amount) => {
  return parseFloat(amount).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const currencyRoundHandler = (amount) => {
  return (
    "$" +
    parseFloat(amount).toLocaleString("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  );
};

export const currencyFormatter = (amount) => {
  let value = String(Number(amount).toFixed(2));
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1.$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ",");
  return value;
};

export const currencyInputHandling = (amount, event) => {
  let value = amount;
  // Remove any non-numeric character except for the first decimal point
  value = value.replace(/[^0-9.]/g, "");
  // Ensure only one decimal point is allowed
  const decimalIndex = value.indexOf(".");
  if (decimalIndex !== -1) {
    value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(/\./g, "");
  }
  // Limit to two decimal places
  if (decimalIndex !== -1) {
    value = value.slice(0, decimalIndex + 3);
  }
  // Add comma as thousand separator
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Remove leading zeroes if they appear before any other numbers (excluding decimal point)
  if (value.length > 1 && value.startsWith("0") && value[1] !== ".") {
    value = value.replace(/^0+/, "");
  }
  if (event === "blur") {
    // Remove commas before parsing to a float
    value = parseFloat(value.replace(/,/g, "")).toFixed(2);
    // And add them back
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return value;
};

export const percentageInputHandling = (amount, event) => {
  let value = amount;
  // Remove any non-numeric character except for the first decimal point
  value = value.replace(/[^0-9.]/g, "");
  // Ensure only one decimal point is allowed
  const decimalIndex = value.indexOf(".");
  if (decimalIndex !== -1) {
    value = value.slice(0, decimalIndex + 1) + value.slice(decimalIndex + 1).replace(/\./g, "");
  }
  // Limit to three decimal places
  if (decimalIndex !== -1) {
    value = value.slice(0, decimalIndex + 4);
  }
  // Add comma as thousand separator
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Remove leading zeroes if they appear before any other numbers (excluding decimal point)
  if (value.length > 1 && value.startsWith("0") && value[1] !== ".") {
    value = value.replace(/^0+/, "");
  }
  if (event === "blur") {
    // Determine if there are three non-zero decimal places, also removing commas
    const [integerPart, decimalPart] = value.split(".");
    if (decimalPart && decimalPart.length === 3 && decimalPart[2] !== "0") {
      value = parseFloat(value.replace(/,/g, "")).toFixed(3);
    } else {
      value = parseFloat(value.replace(/,/g, "")).toFixed(2);
    }
    // And add the commas back
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value;
};

export const currencyDeformatter = (amount) => {
  if (String(amount).includes(",")) {
    let value = +amount.split(",").join("");
    return value;
  } else {
    return Number(amount);
  }
};
