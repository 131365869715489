import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "../global/Loader";

const PublicRoute = () => {
  const { currentUser, activeUserProfile } = useAuth();
  let today = new Date().toLocaleDateString("en-CA");
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday = yesterday.toLocaleDateString("en-CA");

  // Ensure activeUserProfile is loaded before making a decision
  if (currentUser && !activeUserProfile) {
    return (
      <div style={{ width: "100%", height: "40vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader />
      </div>
    ); // Or a loading spinner if needed
  }

  let isNewUser =
    activeUserProfile &&
    (activeUserProfile?.created === today || activeUserProfile?.created === yesterday) &&
    !activeUserProfile.onboardingOptOut;

  if (!currentUser) {
    return <Outlet />;
  } else if (isNewUser) {
    return <Navigate to="/welcome" />;
  } else {
    return <Navigate to="/expenses" />;
  }
};

const PrivateRoute = () => {
  const { currentUser, activeUserProfile } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  // Determine if the user is a "new user"
  const today = new Date().toLocaleDateString("en-CA");
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday = yesterday.toLocaleDateString("en-CA");

  let isNewUser =
    activeUserProfile &&
    (activeUserProfile.created === today || activeUserProfile.created === yesterday) &&
    !activeUserProfile.onboardingOptOut;

  // Redirect non-new users away from /welcome
  if (!isNewUser && location.pathname === "/welcome") {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export { PublicRoute, PrivateRoute };
