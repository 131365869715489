import React from "react";
import { AccountValue } from "./AccountValue/AccountValue";
import { AnnualProgress } from "./AnnualProgress/AnnualProgress";
import { ShoeboxStats } from "./ShoeboxStats/ShoeboxStats";
import { useAuth } from "../../../contexts/AuthContext";
import { Contributions } from "./Contributions/Contributions";

const Tiles = () => {
  return (
    <section className="portfolio-page">
      <AccountValue />
      <AnnualProgress />
      <ShoeboxStats />
      <Contributions />
    </section>
  );
};

export { Tiles };
