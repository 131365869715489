import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { notify } from "../../hooks-n-utils/toast";
import { currencyDeformatter, percentageInputHandling } from "../../hooks-n-utils/currency";
import { dateToStandardDateString } from "../../hooks-n-utils/date";

const Investments = ({ editingIndex, setEditingIndex }) => {
  const {
    activeUserProfile,
    currentUser,
    updateInvestmentStatus,
    getInvestmentStatus,
    loadingInvestmentStatus,
    investmentStatus,
    updateAccountValuesAfterChange,
  } = useAuth();

  // Index-Investing State
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Cash-Investing State
  const [editingCash, setEditingCash] = useState(false);
  const [isEarningInterest, setIsEarningInterest] = useState(false);
  const [interestRate, setInterestRate] = useState();
  const [rateError, setRateError] = useState();

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  useEffect(() => {
    if (!investmentStatus) {
      getInvestmentStatus(currentUser.uid);
    }
    if (investmentStatus && !editingIndex) {
      setSelectedIndex(investmentStatus);
    }
    // sets session value to stop all tile chart animations after initial user session load
    if (!afterFirstVisit) {
      setTimeout(() => {
        window.sessionStorage.setItem("after-first-session-portfolio-visit", true);
      }, 3000);
    }
  }, [activeUserProfile, investmentStatus, editingIndex, afterFirstVisit]);

  useEffect(() => {
    // updateAccountValuesAfterChange("2024-11-11", "2024-11-11", "withdrawal", 45, currentUser.uid, { amount: 0 });
  }, []);

  const handleIndexSelection = (index) => {
    setSelectedIndex(index);
  };

  let doNotTrackIndex = {
    activeInvestments: true,
    symbol: "Not Tracking",
    interestRate: null,
    indexTitle: "No Active Investments",
    dataLabel: null,
  };

  let cashIndex = {
    activeInvestments: true,
    symbol: "CASH",
    interestRate: isEarningInterest ? interestRate : null,
    indexTitle: isEarningInterest ? "Interest-Bearing Cash" : "Non-Interest-Bearing Cash",
    dataLabel: "cash",
  };

  const updateInvestment = (index) => {
    updateInvestmentStatus(index, currentUser.uid)
      .then(() => {
        if (index.symbol !== "CASH" && index.symbol !== "Not Tracking") {
          notify(`${index.symbol} investment has been saved`, "success");
        } else {
          notify(`Your investment preferences are updated`, "primary");
        }
        setEditingIndex(false);
        setEditingCash(false);
        setIsEarningInterest(false);
        setInterestRate();
      })
      .catch((error) => {
        notify(`Error updating preferences: ${error}`, "danger");
      });
  };

  const saveInvestmentSelection = (index) => {
    if (index.symbol === investmentStatus.symbol) {
      if (index.dataLabel === "cash" && index.interestRate !== investmentStatus.interestRate) {
        updateInvestment(index);
      } else {
        setEditingIndex(false);
        setEditingCash(false);
        setRateError();
      }
    } else {
      updateInvestment(index);
    }
  };

  const handleRateInput = (input, event) => {
    let value;
    if (event === "blur") {
      value = percentageInputHandling(input, "blur");
    } else {
      value = percentageInputHandling(input);
    }

    if (currencyDeformatter(value) < 0.01) {
      setRateError("Oops! Please set a value greater than 0%");
    } else if (currencyDeformatter(value) > 99.99) {
      setRateError("Oops! Only APYs under 99.99% are allowed. Please try an updated value.");
    } else {
      setRateError("");
    }

    if (event === "blur") {
      if (input !== "") {
        if (input.length === 2) {
          setInterestRate(parseInt(value));
        } else {
          setInterestRate(value);
        }
      }
    } else {
      setInterestRate(value);
    }
  };

  if (loadingInvestmentStatus) {
    return (
      <div className="investments-panel-loader">
        <sl-skeleton effect="sheen" class="investing-card-loader"></sl-skeleton>
        <sl-skeleton effect="sheen" class="investing-button-loader"></sl-skeleton>
      </div>
    );
  } else {
    return (
      <section className="investments-panel">
        {!editingCash ? (
          !editingIndex ? (
            <div className={afterFirstVisit ? "unset-investment-buttons" : "unset-investment-buttons animated"}>
              {selectedIndex && selectedIndex.activeInvestments ? (
                <>
                  <section className={afterFirstVisit ? "selected-index-card" : "selected-index-card animated"}>
                    <div className="selected-index-header">
                      <div className="index-header-symbol">
                        <h2>{selectedIndex?.symbol}</h2>
                        <small>{selectedIndex?.indexTitle}</small>
                      </div>
                    </div>
                    <span className="selected-index-body-text">
                      {selectedIndex?.dataLabel !== null
                        ? selectedIndex?.dataLabel === "cash"
                          ? `Your portfolio is invested in cash earning ${
                              selectedIndex?.interestRate ? selectedIndex?.interestRate + "%" : "no"
                            } interest.`
                          : `Your portfolio is tracking the ${selectedIndex?.indexTitle}.`
                        : "You are not currently allocated to any active investment."}
                    </span>
                  </section>
                  {selectedIndex?.dataLabel === null ? (
                    <div className="edit-index-buttons">
                      <sl-button
                        variant="primary"
                        onClick={() => {
                          setEditingIndex(true);
                          setSelectedIndex();
                        }}
                      >
                        Track Index
                      </sl-button>
                      <sl-button variant="neutral" onClick={() => setEditingCash(true)}>
                        Track Cash
                      </sl-button>
                    </div>
                  ) : (
                    <div className="edit-index-buttons">
                      <sl-button
                        variant="neutral"
                        onClick={() => {
                          if (selectedIndex?.dataLabel === "cash") {
                            setEditingCash(true);
                            if (selectedIndex?.interestRate) {
                              setIsEarningInterest(true);
                              setInterestRate(selectedIndex?.interestRate);
                            }
                          } else {
                            setEditingIndex(true);
                            setSelectedIndex(selectedIndex);
                          }
                        }}
                      >
                        {selectedIndex?.dataLabel === "cash" ? "Edit Cash" : "Edit Index"}
                      </sl-button>
                      <sl-button variant="danger" onClick={() => saveInvestmentSelection(doNotTrackIndex)}>
                        Stop Tracking {selectedIndex?.dataLabel === "cash" ? "Cash" : "Index"}
                      </sl-button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <sl-button class="track-index-btn" onClick={() => setEditingIndex(true)}>
                    Track by Index
                  </sl-button>
                  <div className="secondary-selections">
                    <sl-button variant="neutral" onClick={() => setEditingCash(true)}>
                      Cash Investment
                    </sl-button>
                    <sl-button variant="neutral" onClick={() => saveInvestmentSelection(doNotTrackIndex)}>
                      Don't Track Growth
                    </sl-button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <section className="index-selector">
              <span>Choose a broad-market index to track & drive your HSA portfolio growth:</span>
              <div className="index-tiles">
                <div className="button-row">
                  <div
                    id="blocks-icon-button"
                    onClick={() =>
                      selectedIndex?.symbol === "S&P 500"
                        ? handleIndexSelection(null)
                        : handleIndexSelection({
                            activeInvestments: true,
                            symbol: "S&P 500",
                            indexTitle: "Standard & Poor's 500",
                            dataLabel: "GSPC",
                            interestRate: null,
                          })
                    }
                    class={selectedIndex?.symbol === "S&P 500" ? "index-tile selected" : "index-tile"}
                    variant="neutral"
                  >
                    <h2>S&P 500</h2>
                    <small>Standard & Poor's 500</small>
                  </div>
                  <div
                    id="blocks-icon-button"
                    onClick={() =>
                      selectedIndex?.symbol === "NASDAQ"
                        ? handleIndexSelection(null)
                        : handleIndexSelection({
                            activeInvestments: true,
                            symbol: "NASDAQ",
                            indexTitle: "Nasdaq Composite Index",
                            dataLabel: "IXIC",
                            interestRate: null,
                          })
                    }
                    class={selectedIndex?.symbol === "NASDAQ" ? "index-tile selected" : "index-tile"}
                    variant="neutral"
                  >
                    <h2>NASDAQ</h2>
                    <small>Nasdaq Composite Index</small>
                  </div>
                </div>
                <div className="button-row">
                  <div
                    id="blocks-icon-button"
                    onClick={() =>
                      selectedIndex?.symbol === "DJIA"
                        ? handleIndexSelection(null)
                        : handleIndexSelection({
                            activeInvestments: true,
                            symbol: "DJIA",
                            indexTitle: "Dow Jones Industrial Average",
                            dataLabel: "DJI",
                            interestRate: null,
                          })
                    }
                    class={selectedIndex?.symbol === "DJIA" ? "index-tile selected" : "index-tile"}
                    variant="neutral"
                  >
                    <h2>DJIA</h2>
                    <small>Dow Jones Industrial Average</small>
                  </div>
                  <div
                    id="blocks-icon-button"
                    onClick={() =>
                      selectedIndex?.symbol === "VTI"
                        ? handleIndexSelection(null)
                        : handleIndexSelection({
                            activeInvestments: true,
                            symbol: "VTI",
                            indexTitle: "Vanguard Total Stock Market",
                            dataLabel: "VTI",
                            interestRate: null,
                          })
                    }
                    class={selectedIndex?.symbol === "VTI" ? "index-tile selected" : "index-tile"}
                    variant="neutral"
                  >
                    <h2>VTI</h2>
                    <small>Vanguard Total Stock Market</small>
                  </div>
                </div>
              </div>
              <div className={afterFirstVisit ? "index-selection-buttons" : "index-selection-buttons animated"}>
                <sl-button
                  id="select-index-btn"
                  onClick={() => saveInvestmentSelection(selectedIndex)}
                  disabled={!selectedIndex ? "disabled" : null}
                >
                  Select {selectedIndex?.symbol ? selectedIndex?.symbol : "Index"}
                </sl-button>
                <sl-button
                  id="cancel-index-btn"
                  onClick={() => {
                    setSelectedIndex();
                    setEditingIndex(false);
                  }}
                  variant="neutral"
                >
                  Cancel
                </sl-button>
              </div>
            </section>
          )
        ) : (
          <section className="cash-investment-form">
            <div className={rateError ? "form-error-wrapper shown" : "form-error-wrapper"}>
              <span className="cash-form-error">{rateError}</span>
            </div>
            <div className="cash-form-fields">
              <span>
                <label>Earning APY?</label>
                <sl-radio-group value={isEarningInterest}>
                  <sl-radio-button value={true} onClick={() => setIsEarningInterest(true)}>
                    Yes
                  </sl-radio-button>
                  <sl-radio-button
                    value={false}
                    onClick={() => {
                      setIsEarningInterest(false);
                      setInterestRate();
                      setRateError();
                    }}
                  >
                    No
                  </sl-radio-button>
                </sl-radio-group>
              </span>
              {isEarningInterest && (
                <span>
                  <label>Current APY</label>
                  <div className="percentage-input-field">
                    <span>%</span>
                    <input
                      value={interestRate}
                      onChange={(e) => handleRateInput(e.target.value)}
                      onBlur={(e) => handleRateInput(e.target.value, "blur")}
                      maxLength={6}
                    />
                  </div>
                </span>
              )}
            </div>
            <div className="cash-controls">
              <sl-button
                onClick={() => saveInvestmentSelection(cashIndex)}
                disabled={(isEarningInterest && !interestRate) || rateError ? "disabled" : null}
              >
                Set Cash
              </sl-button>
              <sl-button
                variant="neutral"
                onClick={() => {
                  setEditingCash(false);
                  setIsEarningInterest(false);
                  setRateError();
                }}
              >
                Cancel
              </sl-button>
            </div>
          </section>
        )}
      </section>
    );
  }
};

export { Investments };
