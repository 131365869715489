import React, { useEffect, useState } from "react";
import { ContributionSchedule } from "./ContributionSchedule";
import { AiFillPlusCircle } from "react-icons/ai";
import { TbAlertHexagonFilled, TbRotate2, TbSquareRoundedLetterE } from "react-icons/tb";
import { GoDot } from "react-icons/go";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { ButtonLoader, Loader } from "../../../global/Loader";

const Contributions = () => {
  const { contributions, contributionSchedule } = useAuth();
  const navigate = useNavigate();

  const [collapsedView, setCollapsedView] = useState();
  const [stackedView, setStackedView] = useState();

  const updateWidth = () => {
    if (document.querySelector(".automated-status-line")) {
      setCollapsedView(document.querySelector(".contribution-tile-footer").getBoundingClientRect().width < 465);
      setStackedView(window.innerWidth < 426);
    }
  };

  useEffect(() => {
    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <div className="portfolio-tile" id="contribution-tile">
      <div className="tile-header">
        <h2>Contributions</h2>
        {contributions !== undefined ? (
          contributions?.length > 0 ? (
            <div className="see-all-btn" onClick={() => document.getElementById("all-contributions-drawer")?.show()}>
              See All
            </div>
          ) : null
        ) : (
          <ButtonLoader />
        )}
      </div>

      {contributions?.length > 0 ? (
        <section className="schedule-table">
          <ContributionSchedule />
        </section>
      ) : null}

      {contributions?.length < 1 ? (
        <div className="empty-contributions">
          <TbAlertHexagonFilled />
          <div className="line-with-link">
            No contributions yet! <Link to="/portfolio/configuration">Configure automated contributions</Link> or use
            the button below to log a one-time contribution.
          </div>
        </div>
      ) : null}

      <section className="contribution-tile-footer">
        {contributions?.length > 0 ? (
          <section className="automated-status-line">
            <sl-badge variant="neutral-filled" class="status-text">
              {contributionSchedule?.activeSchedule ? (
                <>
                  <sl-badge variant="success" pill pulse class="indicator"></sl-badge> <span>active contributions</span>
                </>
              ) : (
                <>
                  <sl-badge variant="neutral" pill pulse class="indicator"></sl-badge>{" "}
                  <span>inactive contributions</span>
                </>
              )}
            </sl-badge>
            <div
              className="schedule-link"
              onClick={() => navigate("/portfolio/configuration", { state: { editing: true, createSchedule: true } })}
            >
              Edit
            </div>
          </section>
        ) : null}

        {contributions?.length > 0 ? (
          <section className="add-one-time-btn">
            <sl-button
              class="blocks-pill-btn"
              pill
              onClick={() => navigate("/portfolio/configuration", { state: { editing: true, createOneTime: true } })}
            >
              {collapsedView
                ? stackedView
                  ? "Add One-Time Contribution"
                  : "Add One-Time"
                : "Add One-Time Contribution"}
            </sl-button>
          </section>
        ) : null}
      </section>

      {!contributions ? (
        <div className="contribution-tile-loader">
          <Loader />
        </div>
      ) : null}

      <sl-drawer placement="bottom" class="shoebox-drawer" id="all-contributions-drawer">
        <span slot="label" class="drawer-header">
          <div className="left-side">
            <h1>All Contributions</h1>
            <div className="table-legend">
              <small className="one-time">
                <GoDot /> One-Time
              </small>
              <small>
                <TbRotate2 /> Recurring
              </small>
              <small>
                <TbSquareRoundedLetterE /> Employer
              </small>
            </div>
          </div>

          <AiFillPlusCircle
            className="close-btn"
            onClick={() => {
              document.getElementById("all-contributions-drawer")?.hide();
            }}
          />
        </span>

        <div>
          <section className="table-wrapper">
            <ContributionSchedule showFullHistory={true} />
          </section>
        </div>
      </sl-drawer>
    </div>
  );
};

export { Contributions };
