export const generateUID = (type, year) => {
  let prefix;
  if (type === "contribution") {
    prefix = "cont-";
  } else if (type === "expense") {
    prefix = "exp-";
  } else if (type === "investment") {
    prefix = "inv-";
  } else {
    return;
  }

  let ts = Date.now();

  return `${prefix}${year}-${ts}`;
};
