import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { ErrorHandler } from "./ErrorHandler";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import Apple from "../../assets/Apple.svg";
import Google from "../../assets/Google.svg";
import { Loader } from "../global/Loader";

const SignIn = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signIn, authenticate } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  window.addEventListener("resize", updateWidth);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    await signIn(emailRef.current.value, passwordRef.current.value)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        setError(ErrorHandler(error.code));
      });
    setLoading(false);
  };

  const googleSignIn = async () => {
    setError("");
    setLoading(true);
    await authenticate("google").then(() => {
      setLoading(false);
    });
  };

  return (
    <div className="sign-in">
      <h1>Sign In</h1>

      <div className={error === "" ? "alert" : "alert error"}>{error}</div>

      <sl-input type="email" ref={emailRef} label="Email Address" filled></sl-input>
      <sl-input type="password" id="passwordInput" ref={passwordRef} filled password-toggle onKeyDown={handleEnter}>
        <span className="password-label" slot="label">
          <span>Password</span> <Link to="/reset-password">Forgot Password?</Link>
        </span>
        <span className="password-toggle" slot="hide-password-icon">
          <RiEyeFill />
        </span>
        <span className="password-toggle" slot="show-password-icon">
          <RiEyeCloseFill />
        </span>
      </sl-input>
      {isDesktop ? <sl-button onClick={handleSubmit}>Sign In</sl-button> : null}
      {isDesktop ? (
        <div className="desktop-has-account">
          <span>
            Don't have an account? <Link to="/create-account">Sign Up</Link>
          </span>
        </div>
      ) : null}

      {isDesktop ? null : (
        <section className="sign-in-controls">
          <sl-button onClick={handleSubmit}>Sign In</sl-button>
          <div className="mobile-has-account">
            <span>
              Don't have an account? <Link to="/create-account">Sign Up</Link>
            </span>
          </div>
        </section>
      )}
    </div>
  );
};

export { SignIn };
