export const runContributionRecordSort = (records, type) => {
  // Helper function to convert date strings to date objects & unix timestamp to date objects
  const parseDate = (record) => new Date(record.date);
  const parseIdTimestamp = (record) => {
    const unixTimestamp = record.id.split("-")[2]; // Extract the timestamp part
    return new Date(parseInt(unixTimestamp, 10)); // Convert to Date
  };

  if (type === "upcoming") {
    // Sort upcoming records by date (closest to farthest), then by date of created (for items with the same date)
    records?.sort((a, b) => {
      const dateDiff = parseDate(a) - parseDate(b); // Compare by date first
      if (dateDiff !== 0) {
        return dateDiff; // If dates are different, return the comparison
      }
      // If dates are the same, compare by id timestamp
      return parseIdTimestamp(a) - parseIdTimestamp(b);
    });
  }

  if (type === "nonUpcoming") {
    // Sort non-upcoming records by date (most recent first), then by date of created (for items with the same date)
    records?.sort((a, b) => {
      const dateDiff = parseDate(b) - parseDate(a); // Compare by date first
      if (dateDiff !== 0) {
        return dateDiff; // If dates are different, return the comparison
      }
      // If dates are the same, compare by id timestamp
      return parseIdTimestamp(b) - parseIdTimestamp(a);
    });
  }
};

export const getCollapsedViewContributionRecords = (records) => {
  // Separate upcoming and non-upcoming records
  const upcomingRecords = records.filter((record) => record.status === "Upcoming");
  const nonUpcomingRecords = records.filter((record) => record.status !== "Upcoming");

  runContributionRecordSort(upcomingRecords, "upcoming");
  runContributionRecordSort(nonUpcomingRecords, "nonUpcoming");

  // If at least 2 upcoming records, return the 2 closest upcoming and 1 most recent non-upcoming
  if (upcomingRecords.length >= 2) {
    return [...upcomingRecords.slice(0, 2), nonUpcomingRecords[0]];
  }

  // If 1 upcoming record, return 1 upcoming and the 2 most recent non-upcoming
  if (upcomingRecords.length === 1) {
    return [...upcomingRecords.slice(0, 1), ...nonUpcomingRecords.slice(0, 2)];
  }

  // If no upcoming records, return the 3 most recent non-upcoming
  return nonUpcomingRecords.slice(0, 3);
};

export const getExpandedViewContributionRecords = (records) => {
  // Separate upcoming and non-upcoming records
  const upcomingRecords = records.filter((record) => record.status === "Upcoming");
  const nonUpcomingRecords = records.filter((record) => record.status !== "Upcoming");

  runContributionRecordSort(upcomingRecords, "upcoming");
  runContributionRecordSort(nonUpcomingRecords, "nonUpcoming");

  // Return all records
  let allRecords = upcomingRecords.concat(nonUpcomingRecords);
  return allRecords;
};
