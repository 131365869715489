import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TbArrowLeft, TbExternalLink } from "react-icons/tb";
import { useAuth } from "../../contexts/AuthContext";
import { openCustomerPortal } from "../../stripe/customerPortal";
import { createCheckoutSession } from "../../stripe/checkoutSession";
import { notify } from "../../hooks-n-utils/toast";
import { dateHandler, subscriptionDateHandler } from "../../hooks-n-utils/date";
import { Loader } from "../global/Loader";
import { Pricing } from "../external/Pricing";
import ShoeboxFilebox from "../../assets/Shoebox_Filebox.webp";

const Subscription = () => {
  const {
    activeUserProfile,
    currentUser,
    subscriptionDetails,
    authLoading,
    loadingVerifiedSubscription,
    setLoadingCheckout,
  } = useAuth();

  const [isDesktop, setIsDesktop] = useState();
  const [isCompact, setIsCompact] = useState();

  // Established Subscription
  const [loadingPortal, setLoadingPortal] = useState(false);
  const [loadingCancelPortal, setLoadingCancelPortal] = useState(false);
  const [portalError, setPortalError] = useState("");

  // New Subscription
  const [selectedPlan, setSelectedPlan] = useState("monthly");

  const updateWidth = () => {
    planSelectButton = document.getElementById("plan-selection-button");
    setIsDesktop(window.innerWidth > 740);
    setIsCompact(window.innerWidth < 400);
    if (selectedPlan === "") {
      planSelectButton?.setAttribute("disabled", true);
    } else {
      planSelectButton?.removeAttribute("disabled");
    }
  };

  window.addEventListener("resize", updateWidth);

  useEffect(() => {
    updateWidth();
  }, []);

  let planSelectButton;

  useEffect(() => {
    planSelectButton = document.getElementById("plan-selection-button");
    if (selectedPlan === "") {
      planSelectButton?.setAttribute("disabled", true);
    } else {
      planSelectButton?.removeAttribute("disabled");
    }
  }, [selectedPlan, planSelectButton]);

  const handlePrice = (price) => {
    let formattedPrice = price?.toString().slice(0, -2) + "." + price?.toString().slice(-2);
    return formattedPrice;
  };

  const handleTitleInterval = (interval) => {
    let formattedInterval = interval !== "month" ? (interval === "year" ? "Annual" : "") : "Monthly";
    return formattedInterval;
  };

  const handleDate = (createdDate) => {
    let date = new Date(createdDate * 1000);
    function addMonths(date) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate());
      return date;
    }
    let newDate = addMonths(date);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let formattedDate = month + "/" + day + "/" + year;
    return formattedDate;
  };

  const handlePurchase = () => {
    setLoadingPortal(true);
    document.querySelector(".checkout-button").setAttribute("disabled", true);
    createCheckoutSession(currentUser.uid, selectedPlan, "established-account", setLoadingCheckout)
      .then(() => {
        setTimeout(() => {
          setLoadingPortal(false);
          document.querySelector(".checkout-button").removeAttribute("disabled");
        }, 2500);
      })
      .catch((err) => {
        setTimeout(() => {
          setLoadingPortal(false);
          setPortalError(err);
          notify(`There was an error accessing your portal: ${err}`, "danger");
          document.querySelector(".checkout-button").removeAttribute("disabled");
        }, 2500);
      });
  };

  const handlePortalRedirect = (location) => {
    if (location === "cancel") {
      setLoadingCancelPortal(true);
    } else {
      setLoadingPortal(true);
    }
    document.querySelector(".portal-button").setAttribute("disabled", true);
    openCustomerPortal()
      .then(() => {
        setLoadingPortal(false);
        setLoadingCancelPortal(false);
        document.querySelector(".portal-button").removeAttribute("disabled");
      })
      .catch((err) => {
        setLoadingPortal(false);
        setLoadingCancelPortal(false);
        setPortalError(err);
        notify(`There was an error accessing your portal: ${err}`, "danger");
        document.querySelector(".portal-button").removeAttribute("disabled");
      });
  };

  return (
    <section className="subscription-page">
      <div className="settings-sub-header">
        <div className="title">
          <Link to="/profile">
            <sl-button variant="neutral" class="sub-setting-back-button">
              <TbArrowLeft />
            </sl-button>
          </Link>
          <h1>Subscription</h1>
        </div>
      </div>
      {!loadingVerifiedSubscription ? (
        activeUserProfile?.subActive ? (
          <div className="subscription-content-wrapper">
            <h2 className="subscription-status-text">You have an active subscription:</h2>
            <div className="subscription-tile">
              <div className="subscription-image">
                <img src={ShoeboxFilebox} />
              </div>
              {subscriptionDetails?.interval && subscriptionDetails?.price && subscriptionDetails?.created ? (
                <div className="subscription-details">
                  <span className="title">{activeUserProfile?.subscription}</span>
                  <span className="price-line">
                    {subscriptionDetails?.canceled ? (
                      <span className="canceled">Canceled</span>
                    ) : (
                      <>
                        <span className="dollars">${handlePrice(subscriptionDetails?.price)}</span>
                        <span className="slash">/</span>
                        {subscriptionDetails?.interval}
                      </>
                    )}
                  </span>
                  {subscriptionDetails?.canceled ? (
                    <span className="support-text italic">
                      We're sorry to see you go. Your subscription has been canceled and will end on{" "}
                      {subscriptionDateHandler(subscriptionDetails?.cancelDate)}.
                    </span>
                  ) : (
                    <span className="support-text italic">
                      {subscriptionDateHandler(new Date()) ===
                      subscriptionDateHandler(subscriptionDetails?.currentBillingPeriodEnd)
                        ? "Next charge will occur today."
                        : `Next charge will occur on ${subscriptionDateHandler(
                            subscriptionDetails?.currentBillingPeriodEnd
                          )}.`}
                    </span>
                  )}
                  {subscriptionDetails?.canceled && (
                    <span className="support-text">After this date, all Shoebox features will be unavailable.</span>
                  )}
                  {subscriptionDetails?.canceled ? (
                    <span className="support-text-two">
                      If you change your mind, click the button below to open the portal and restart your subscription.
                      You will be redirected to Stripe.
                    </span>
                  ) : (
                    <span className="support-text-two">
                      Click the buttons below to make any subscription changes or view to your saved payment method. You
                      will be redirected to Stripe.
                    </span>
                  )}
                </div>
              ) : (
                <div className="subscription-details">
                  <sl-skeleton effect="pulse" class="interval-skeleton"></sl-skeleton>
                  <sl-skeleton effect="pulse" class="price-skeleton"></sl-skeleton>
                  <sl-skeleton effect="sheen" class="support-text-skeleton"></sl-skeleton>
                  <sl-skeleton effect="sheen" class="support-text-skeleton-two"></sl-skeleton>
                </div>
              )}
            </div>
            <div className="subscription-action-buttons">
              <sl-button
                class="portal-button"
                onClick={() => handlePortalRedirect()}
                variant="neutral"
                disabled={loadingCancelPortal ? true : null}
              >
                {!loadingPortal ? (
                  isCompact ? (
                    "Subscription Portal"
                  ) : (
                    "Access Subscription Portal"
                  )
                ) : (
                  <sl-spinner variant="button-neutral"></sl-spinner>
                )}{" "}
                {!loadingPortal ? <TbExternalLink style={{ fontSize: "16pt", marginLeft: "5px" }} /> : null}
              </sl-button>
              <sl-button
                class="portal-button cancel"
                onClick={() => handlePortalRedirect("cancel")}
                variant="danger"
                disabled={loadingPortal ? true : null}
              >
                {!loadingCancelPortal ? (
                  isCompact ? (
                    "Cancel Subscription"
                  ) : (
                    "Cancel Subscription"
                  )
                ) : (
                  <sl-spinner variant="button-danger"></sl-spinner>
                )}{" "}
                {!loadingCancelPortal ? <TbExternalLink style={{ fontSize: "16pt", marginLeft: "5px" }} /> : null}
              </sl-button>
            </div>
          </div>
        ) : (
          <div className="subscription-content-wrapper">
            <h2 className="subscription-status-text">Select a billing term to continue:</h2>
            <section id="pricing">
              <Pricing
                setSelectedPlan={setSelectedPlan}
                status="existing"
                handlePurchase={handlePurchase}
                loadingPortal={loadingPortal}
              />
            </section>
          </div>
        )
      ) : (
        <div className="subscription-loader">
          {/* This check prevents jitter when first arriving on page */}
          {authLoading ? null : <sl-skeleton effect="pulse" class="subscription-card-header-skeleton"></sl-skeleton>}
          {authLoading ? null : <sl-skeleton effect="sheen" class="subscription-card-skeleton"></sl-skeleton>}
          {authLoading ? null : <sl-skeleton effect="pulse" class="subscription-card-button-skeleton"></sl-skeleton>}
          {authLoading ? null : <sl-skeleton effect="pulse" class="subscription-card-button-skeleton"></sl-skeleton>}
        </div>
      )}
    </section>
  );
};

export { Subscription };
