const getAge = (DOB) => {
  var today = new Date();
  var birthDate = new Date(DOB);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const validateMinimumAge = (input) => {
  let enteredAge = getAge(input);
  if (enteredAge > 18) {
    return true;
  } else {
    return false;
  }
};

export const validateCatchUpAge = (input) => {
  let enteredAge = getAge(input);
  if (enteredAge > 54) {
    return true;
  } else {
    return false;
  }
};
