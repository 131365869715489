import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TbArrowLeft } from "react-icons/tb";
import { PiPassword } from "react-icons/pi";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import { useAuth } from "../../contexts/AuthContext";

const AccountSecurity = () => {
  const { currentUser, setNewPassword } = useAuth();

  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [seeOld, setSeeOld] = useState(false);

  const [updatedPassword, setUpdatedPassword] = useState("");
  const [seeUpdated, setSeeUpdated] = useState(false);

  const [updatedPasswordCopy, setUpdatedPasswordCopy] = useState("");
  const [seeUpdatedCopy, setSeeUpdatedCopy] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location && location?.state?.destination === "email") {
      setUpdatingEmail(true);
    }
  }, [location]);

  useEffect(() => {
    if (
      oldPassword.length > 3 &&
      updatedPassword.length > 3 &&
      updatedPasswordCopy.length > 3 &&
      updatedPassword === updatedPasswordCopy
    ) {
      document.getElementById("update-password-btn")?.removeAttribute("disabled");
    } else {
      document.getElementById("update-password-btn")?.setAttribute("disabled", true);
    }
  }, [oldPassword, updatedPassword, updatedPasswordCopy]);

  const toggleUpdate = (src) => {
    if (src === "password") {
      setUpdatingPassword(!updatingPassword);
      setUpdatingEmail(false);
    }
    if (src === "email") {
      setUpdatingEmail(!updatingEmail);
      setUpdatingPassword(false);
    }
  };

  const changePassword = () => {
    if (updatedPassword === updatedPasswordCopy) {
      setNewPassword(oldPassword, updatedPassword);
      toggleUpdate("password");
      setOldPassword("");
      setUpdatedPassword("");
      setUpdatedPasswordCopy("");
      setSeeOld(false);
      setSeeUpdated(false);
      setSeeUpdatedCopy(false);
    }
  };

  return (
    <section id="account-security">
      <div className="settings-sub-header">
        <div className="title">
          <Link to="/profile">
            <sl-button variant="neutral" class="sub-setting-back-button">
              <TbArrowLeft />
            </sl-button>
          </Link>
          <h1>Account Security</h1>
        </div>
      </div>
      <div className="page-content">
        <h2>Current Email</h2>
        <span className="email-update">
          <sl-badge variant="neutral">{currentUser?.email}</sl-badge>
          <a onClick={() => toggleUpdate("email")}>{updatingEmail ? "" : "Update"}</a>
        </span>
        <div className={updatingEmail ? "email-update-wrapper expanded" : "email-update-wrapper"}>
          <div className="email-update-form">
            <span>
              If you need to update your email address, please send us an email at{" "}
              <a href="mailto:support@shoebox.finance?subject=Email Update Request">support@shoebox.finance</a> and we
              can assist you.
            </span>
            <span className="buttons">
              <sl-button
                onClick={() => (window.location = "mailto:support@shoebox.finance?subject=Email Update Request")}
              >
                Email Us
              </sl-button>
              <sl-button onClick={() => toggleUpdate("email")} variant="neutral">
                Cancel
              </sl-button>
            </span>
          </div>
        </div>
        <h2>Password</h2>
        <span className="password-update">
          <PiPassword />
          <a onClick={() => toggleUpdate("password")}>{updatingPassword ? "" : "Update"}</a>
        </span>
        <div className={updatingPassword ? "password-update-wrapper expanded" : "password-update-wrapper"}>
          <div className="password-update-form">
            <div>
              <label>Current Password</label>
              <div className="input-wrapper">
                <input
                  type={seeOld ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <span className="password-toggle" onClick={() => setSeeOld(!seeOld)}>
                  {seeOld ? <RiEyeCloseFill /> : <RiEyeFill />}
                </span>
              </div>
            </div>
            <div>
              <label>New Password</label>
              <div className="input-wrapper">
                <input
                  type={seeUpdated ? "text" : "password"}
                  value={updatedPassword}
                  onChange={(e) => setUpdatedPassword(e.target.value)}
                />
                <span className="password-toggle" onClick={() => setSeeUpdated(!seeUpdated)}>
                  {seeUpdated ? <RiEyeCloseFill /> : <RiEyeFill />}
                </span>
              </div>
            </div>
            <div>
              <label>Confirm New Password</label>
              <div className="input-wrapper">
                <input
                  type={seeUpdatedCopy ? "text" : "password"}
                  value={updatedPasswordCopy}
                  onChange={(e) => setUpdatedPasswordCopy(e.target.value)}
                />
                <span className="password-toggle" onClick={() => setSeeUpdatedCopy(!seeUpdatedCopy)}>
                  {seeUpdatedCopy ? <RiEyeCloseFill /> : <RiEyeFill />}
                </span>
              </div>
            </div>
            <div className="password-form-controls">
              <sl-button
                onClick={() => {
                  toggleUpdate("password");
                  setOldPassword("");
                  setUpdatedPassword("");
                  setUpdatedPasswordCopy("");
                  setSeeOld(false);
                  setSeeUpdated(false);
                  setSeeUpdatedCopy(false);
                }}
                variant="neutral"
              >
                Cancel
              </sl-button>
              <sl-button id="update-password-btn" onClick={() => changePassword()}>
                Update
              </sl-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { AccountSecurity };
