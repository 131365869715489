import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Document, Thumbnail } from "react-pdf";
import { Loader } from "../global/Loader";
import { AiFillPlusCircle } from "react-icons/ai";
import { TbListDetails, TbRosetteDiscountCheckFilled, TbAlertTriangleFilled } from "react-icons/tb";
import { TiTimes } from "react-icons/ti";
import { ExpensesList } from "./ExpensesList";
import { ExpenseForm } from "./ExpenseForm";
import { dateHandler, shortDateHandler } from "../../hooks-n-utils/date";
import { currencyHandler } from "../../hooks-n-utils/currency";
import { titleHandler } from "../../hooks-n-utils/titling";
import { useLocation, useNavigate } from "react-router-dom";
import { insuranceAssetProvider } from "../../hooks-n-utils/insurance-assets";

const Expenses = () => {
  const { expenses, loadingExpenses, noExpenses, authLoading, colorMode, activeUserProfile, verifySubscription } =
    useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedExpense, setSelectedExpense] = useState();

  const [isDesktop, setIsDesktop] = useState();
  const [isTightMobile, setIsTightMobile] = useState();

  const [isNewSubscription, setIsNewSubscription] = useState(false);
  const [hasActiveSub, setHasActiveSub] = useState();

  const [allReceipts, setAllReceipts] = useState(false);
  const [containerWidth, setContainerWidth] = useState();

  let singleReceipt = [];
  if (selectedExpense?.receipts.length > 0) {
    singleReceipt.push(selectedExpense.receipts[0]);
  }
  let reciepts = selectedExpense?.receipts;

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
    setIsTightMobile(window.innerWidth < 420);
  };

  window.addEventListener("resize", updateWidth);

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-expenses-visit");

  useEffect(() => {
    let today = new Date().toLocaleDateString("en-CA");
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = yesterday.toLocaleDateString("en-CA");
    if (
      activeUserProfile &&
      (activeUserProfile?.created === today || activeUserProfile?.created === yesterday) &&
      !activeUserProfile.subActive
    ) {
      setIsNewSubscription(true);
    } else {
      setIsNewSubscription(false);
    }
    if (!afterFirstVisit) {
      setTimeout(() => {
        window.sessionStorage.setItem("after-first-session-expenses-visit", true);
      }, 1000);
    }
  }, [activeUserProfile, afterFirstVisit]);

  let expenseDrawer = document.getElementById("expense-form-drawer");

  useEffect(() => {
    updateWidth();

    if (location.pathname === "/expenses/add" && expenseDrawer) {
      if (hasActiveSub === "yes") {
        expenseDrawer.show();
      } else {
        navigate("/expenses");
      }
    }

    if (location.pathname.includes("/details/") && !selectedExpense) {
      navigate("/expenses");
    }

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [expenses, loadingExpenses, noExpenses, selectedExpense, authLoading, expenseDrawer]);

  useEffect(() => {
    if (activeUserProfile) {
      if (activeUserProfile.subActive) {
        setHasActiveSub("yes");
      } else {
        setHasActiveSub("no");
      }
    }
  }, [activeUserProfile]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const element = document.querySelector(".receipts-section");
      if (element) {
        setContainerWidth(element.getBoundingClientRect().width);
        observer.disconnect(); // Stop observing once found
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup when unmounting
  }, []);

  const LoadingExpenses = () => {
    return (
      <div className="expenses-loader">
        {/* This check prevents jitter when first arriving on page */}
        {authLoading ? null : <Loader />}
      </div>
    );
  };

  const LoadingSubscription = () => {
    return (
      <div className="expenses-loader">
        {/* This check prevents jitter when first arriving on page */}
        {authLoading ? null : <Loader />}
        <span>Please wait while we prepare your subscription.</span>
      </div>
    );
  };

  const RenderTags = () => {
    return (
      <div className="tags-container">
        {selectedExpense.tags.map((tag, index) => {
          return <sl-badge key={index}>{tag}</sl-badge>;
        })}
      </div>
    );
  };

  const RenderReciepts = (recieptArray) => {
    return (
      <div className="reciepts-container">
        {recieptArray.map((receipt, index) => {
          if (receipt.type === "pdf") {
            return (
              <Document key={index} file={receipt.downloadLink} className="pdf-thumbnail-wrapper">
                <Thumbnail className="thumbnail-page" width={containerWidth} pageIndex={0} />
              </Document>
            );
          } else {
            return <img key={index} src={receipt.downloadLink} />;
          }
        })}
      </div>
    );
  };

  return (
    <>
      <section className="expense-header">
        {!isDesktop && selectedExpense ? isTightMobile ? <h1>Details</h1> : <h1>Expense Detail</h1> : <h1>Expenses</h1>}
        {hasActiveSub !== "no" && !isDesktop && (
          <AiFillPlusCircle
            onClick={() => {
              setSelectedExpense();
              navigate("/expenses/add");
              expenseDrawer.show();
            }}
          />
        )}
        {hasActiveSub !== "no" && isDesktop && (
          <sl-button
            class="add-expense-btn"
            onClick={() => {
              setSelectedExpense();
              navigate("/expenses/add");
              expenseDrawer.show();
            }}
          >
            <AiFillPlusCircle /> Add Expense
          </sl-button>
        )}
      </section>
      {!isNewSubscription ? (
        !loadingExpenses || hasActiveSub === undefined ? (
          hasActiveSub !== "no" ? (
            noExpenses ? (
              <div className="empty-expenses">
                <TbListDetails />
                <h2>No Expenses...Yet</h2>
                <span>Get Started:</span>
                <sl-button id="new-profile-open-add" onClick={() => expenseDrawer.show()} class="blocks-pill-btn" pill>
                  Add Your First Expense
                </sl-button>
              </div>
            ) : (
              <>
                {isDesktop === false ? (
                  selectedExpense ? (
                    <section className="expense-detail">
                      <div className={selectedExpense?.name.length > 30 ? "detail-header wrapped" : "detail-header"}>
                        <div>
                          <h3>{selectedExpense?.name}</h3>
                          <span className="date-line">
                            {dateHandler(selectedExpense?.date)}
                            {selectedExpense?.withdrawn && (
                              <span>
                                <span className="withdrawn-divider">•</span>
                                <span className="withdrawn-text">WITHDRAWN</span>
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="detail-card-actions">
                          <sl-button
                            class="blocks-pill-btn"
                            pill
                            onClick={() => {
                              document.getElementById("expense-form-drawer")?.show();
                              navigate(`/expenses/details/${selectedExpense.id}/edit`);
                            }}
                          >
                            Edit
                          </sl-button>
                          <AiFillPlusCircle
                            className="close-detail"
                            onClick={() => {
                              setSelectedExpense();
                              navigate("/expenses");
                            }}
                          />
                        </div>
                      </div>
                      <div className="detail-body">
                        <small>Amount</small>
                        <h4 className="currency">{currencyHandler(selectedExpense?.amount)}</h4>

                        <small>Category</small>
                        <sl-badge variant="neutral-filled">{titleHandler(selectedExpense?.category)}</sl-badge>

                        <small>Eligibility</small>
                        <h4 className="eligible-check">
                          {selectedExpense?.eligible ? (
                            <TbRosetteDiscountCheckFilled />
                          ) : (
                            <TiTimes className="ineligible" />
                          )}
                          {selectedExpense?.eligible ? "HSA Eligible Expense" : "Not Eligible Expense"}
                        </h4>

                        {selectedExpense?.underInsurance ? (
                          <>
                            <small className="insurance-label">Insurance</small>
                            {selectedExpense?.isCustomInsurance ? (
                              <div className="custom-insurance-text">{selectedExpense?.selectedInsurance}</div>
                            ) : (
                              <img
                                className={
                                  selectedExpense?.selectedInsurance !== "aetna" &&
                                  selectedExpense?.selectedInsurance !== "bcbs" &&
                                  selectedExpense?.selectedInsurance !== "humana"
                                    ? selectedExpense?.selectedInsurance !== "harvard" &&
                                      selectedExpense?.selectedInsurance !== "centene"
                                      ? "insurance-asset small"
                                      : "insurance-asset medium"
                                    : "insurance-asset"
                                }
                                src={insuranceAssetProvider(selectedExpense?.selectedInsurance, colorMode)}
                              />
                            )}
                          </>
                        ) : null}

                        {selectedExpense?.tags.length > 0 ? (
                          <>
                            <small>Tags</small>
                            {RenderTags()}
                          </>
                        ) : null}

                        {selectedExpense?.withdrawn ? (
                          <>
                            <small>Withdrawal Information</small>
                            <span>
                              This expense was withdrawn from your HSA balance on{" "}
                              {shortDateHandler(selectedExpense.dateWithdrawn)}.
                            </span>
                          </>
                        ) : null}

                        <small className={selectedExpense?.tags.length > 0 ? "notes-section-label" : ""}>Notes</small>
                        {selectedExpense?.notes ? (
                          selectedExpense.notes
                        ) : (
                          <span className="no-notes-text">No Notes Provided</span>
                        )}

                        {selectedExpense?.receipts.length > 0 ? (
                          <>
                            <small className="receipts-section">
                              <span>Receipts</span>
                              {selectedExpense?.receipts.length > 1 ? (
                                <span className="receipts-toggle" onClick={() => setAllReceipts(!allReceipts)}>
                                  {allReceipts ? "See Less" : "See All"}
                                </span>
                              ) : null}
                            </small>
                            {allReceipts ? RenderReciepts(reciepts) : RenderReciepts(singleReceipt)}
                          </>
                        ) : null}
                      </div>
                    </section>
                  ) : (
                    <section className="mobile-detail-view">
                      <ExpensesList expenses={expenses} setSelectedExpense={setSelectedExpense} />
                    </section>
                  )
                ) : (
                  <section className="desktop-detail-view">
                    <ExpensesList
                      expenses={expenses}
                      selectedExpense={selectedExpense}
                      setSelectedExpense={setSelectedExpense}
                    />
                    {selectedExpense && (
                      <section className="expense-detail">
                        <div className={selectedExpense?.name.length > 30 ? "detail-header wrapped" : "detail-header"}>
                          <div>
                            <h3>{selectedExpense?.name}</h3>
                            <span className="date-line">
                              {dateHandler(selectedExpense?.date)}
                              {selectedExpense?.withdrawn && (
                                <span>
                                  <span className="withdrawn-divider">•</span>
                                  <span className="withdrawn-text">WITHDRAWN</span>
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="detail-card-actions">
                            <sl-button
                              class="blocks-pill-btn"
                              pill
                              onClick={() => {
                                document.getElementById("expense-form-drawer")?.show();
                                navigate(`/expenses/details/${selectedExpense.id}/edit`);
                              }}
                            >
                              Edit
                            </sl-button>
                            <AiFillPlusCircle
                              className="close-detail"
                              onClick={() => {
                                setSelectedExpense();
                                navigate("/expenses");
                              }}
                            />
                          </div>
                        </div>
                        <div className="detail-body">
                          <small>Amount</small>
                          <h4 className="currency">{currencyHandler(selectedExpense?.amount)}</h4>

                          <small>Category</small>
                          <sl-badge variant="neutral-filled">{titleHandler(selectedExpense?.category)}</sl-badge>

                          <small>Eligibility</small>
                          <h4 className="eligible-check">
                            {selectedExpense?.eligible ? (
                              <TbRosetteDiscountCheckFilled />
                            ) : (
                              <TiTimes className="ineligible" />
                            )}
                            {selectedExpense?.eligible ? "HSA Eligible Expense" : "Not Eligible Expense"}
                          </h4>

                          {selectedExpense?.underInsurance ? (
                            <>
                              <small className="insurance-label">Insurance</small>
                              {selectedExpense?.isCustomInsurance ? (
                                <div className="custom-insurance-text">{selectedExpense?.selectedInsurance}</div>
                              ) : (
                                <img
                                  className={
                                    selectedExpense?.selectedInsurance !== "aetna" &&
                                    selectedExpense?.selectedInsurance !== "bcbs" &&
                                    selectedExpense?.selectedInsurance !== "humana"
                                      ? selectedExpense?.selectedInsurance !== "harvard" &&
                                        selectedExpense?.selectedInsurance !== "centene"
                                        ? "insurance-asset small"
                                        : "insurance-asset medium"
                                      : "insurance-asset"
                                  }
                                  src={insuranceAssetProvider(selectedExpense?.selectedInsurance, colorMode)}
                                />
                              )}
                            </>
                          ) : null}

                          {selectedExpense?.tags.length > 0 ? (
                            <>
                              <small>Tags</small>
                              {RenderTags()}
                            </>
                          ) : null}

                          {selectedExpense?.withdrawn ? (
                            <>
                              <small>Withdrawal Information</small>
                              <span>
                                This expense was withdrawn from your HSA balance on{" "}
                                {shortDateHandler(selectedExpense.dateWithdrawn)}.
                              </span>
                            </>
                          ) : null}

                          <small className={selectedExpense?.tags.length > 0 ? "notes-section-label" : ""}>Notes</small>
                          {selectedExpense?.notes ? (
                            selectedExpense.notes
                          ) : (
                            <span className="no-notes-text">No Notes Provided</span>
                          )}

                          {selectedExpense?.receipts.length > 0 ? (
                            <>
                              <small className="receipts-section">
                                <span>Receipts</span>
                                {selectedExpense?.receipts.length > 1 ? (
                                  <span className="receipts-toggle" onClick={() => setAllReceipts(!allReceipts)}>
                                    {allReceipts ? "See Less" : "See All"}
                                  </span>
                                ) : null}
                              </small>
                              {containerWidth ? (
                                allReceipts ? (
                                  RenderReciepts(reciepts)
                                ) : (
                                  RenderReciepts(singleReceipt)
                                )
                              ) : (
                                <Loader />
                              )}
                            </>
                          ) : null}
                        </div>
                      </section>
                    )}
                  </section>
                )}
              </>
            )
          ) : (
            <div className="inactive-sub-tile">
              <TbAlertTriangleFilled />
              <span>Your subscription is not currently active.</span>
              <span className="link" onClick={() => navigate("/profile/subscription")}>
                Please click here to select your plan and checkout
              </span>
            </div>
          )
        ) : (
          <LoadingExpenses />
        )
      ) : (
        <LoadingSubscription />
      )}
      <ExpenseForm id="add-expense-form" selectedExpense={selectedExpense} setSelectedExpense={setSelectedExpense} />
    </>
  );
};

export { Expenses };
