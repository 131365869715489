import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { TbArrowLeft, TbPlus, TbMinus, TbRosetteDiscountCheckFilled, TbInfoCircleFilled } from "react-icons/tb";
import { MdChair } from "react-icons/md";
import { FaUmbrellaBeach } from "react-icons/fa6";
import { useAuth } from "../../contexts/AuthContext";
import { notify } from "../../hooks-n-utils/toast";
import { IoClose } from "react-icons/io5";
import { ProfilePlaceholder } from "../../hooks-n-utils/profile-placeholder";
import AvatarEditor from "react-avatar-editor";
import { memberDateHandler, shortDateHandler } from "../../hooks-n-utils/date";

const PersonalInfo = ({ profilePicture }) => {
  const {
    activeUserProfile,
    updateName,
    updateProfilePhoto,
    deleteProfilePhoto,
    currentTermsVersion,
    currentPrivacyVersion,
    updateCoverageType,
    updateDateOfBirth,
    logTermsAgreement,
    termsAcceptanceLoading,
  } = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [isDesktop, setIsDesktop] = useState();

  const [selectedFile, setSelectedFile] = useState({});
  const [fileError, setFileError] = useState("");
  const [initialDelete, setInitialDelete] = useState(false);
  const [imageScale, setImageScale] = useState(1);

  const [coverageType, setCoverageType] = useState();

  const [editingDob, setEditingDob] = useState(false);
  const [dob, setDob] = useState();
  const [dobError, setDobError] = useState("");

  const editorRef = useRef();

  const openTermsAgreementPopup = () => {
    document.getElementById("terms-agreement-popup")?.show();
  };

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);

    if (firstName !== activeUserProfile?.firstName || lastName !== activeUserProfile?.lastName) {
      document.getElementById("profile-update-button")?.removeAttribute("disabled");
      document.getElementById("profile-update-button-mobile")?.removeAttribute("disabled");
    } else {
      document.getElementById("profile-update-button")?.setAttribute("disabled", true);
      document.getElementById("profile-update-button-mobile")?.setAttribute("disabled", true);
    }
  };

  window.addEventListener("resize", updateWidth);

  const toggleDelete = () => {
    setInitialDelete(!initialDelete);
  };

  const toggleEditDob = () => {
    setEditingDob(!editingDob);
    setTimeout(() => {
      setDob(activeUserProfile.dateOfBirth ? activeUserProfile.dateOfBirth : "1990-01-01");
    }, 500);
  };

  useEffect(() => {
    updateWidth();

    if (activeUserProfile && !firstName && !lastName) {
      setFirstName(activeUserProfile.firstName);
      setLastName(activeUserProfile.lastName);
    }

    if (activeUserProfile) {
      setCoverageType(activeUserProfile?.coverageType);
      setDob(activeUserProfile.dateOfBirth);
    }

    if (firstName !== activeUserProfile?.firstName || lastName !== activeUserProfile?.lastName) {
      document.getElementById("profile-update-button")?.removeAttribute("disabled");
      document.getElementById("profile-update-button-mobile")?.removeAttribute("disabled");
    } else {
      document.getElementById("profile-update-button")?.setAttribute("disabled", true);
      document.getElementById("profile-update-button-mobile")?.setAttribute("disabled", true);
    }
  }, [activeUserProfile, firstName, lastName]);

  useEffect(() => {
    if (activeUserProfile?.dateOfBirth && !editingDob && activeUserProfile?.dateOfBirth !== dob) {
      setDob(activeUserProfile.dateOfBirth);
    }
    if (activeUserProfile?.dateOfBirth) {
      if (dob === activeUserProfile.dateOfBirth) {
        document.getElementById("dob-update-button")?.setAttribute("disabled", true);
      } else {
        document.getElementById("dob-update-button")?.removeAttribute("disabled");
      }
    }
  }, [activeUserProfile, dob]);

  const sendNameUpdate = (first, last) => {
    if (first !== activeUserProfile?.firstName || last !== activeUserProfile?.lastName) {
      updateName(first, last).then(() => {
        notify("Your name has been successfully updated", "success");
      });
    }
  };

  const handleCoverageTypeUpdate = (type) => {
    if (type !== coverageType) {
      updateCoverageType(type)
        .then(() => {
          notify(`Coverage type has been set to '${type.charAt(0).toUpperCase() + type.slice(1)}'`, "success");
        })
        .catch((err) => {
          notify(`There was an error while updating your coverage type, ${err}`, "danger");
        });
    }
  };

  const handleDobUpdate = (dob) => {
    if (dob !== activeUserProfile?.dateOfBirth) {
      updateDateOfBirth(dob)
        .then(() => {
          notify(`Date of birth has been update to ${shortDateHandler(dob)}`, "success");
          toggleEditDob();
        })
        .catch((err) => {
          notify(`There was an error while updating your date of birth, ${err}`, "danger");
        });
    }
  };

  const selectPicture = (e) => {
    let file = e.target.files[0];
    const MAX_FILE_SIZE = 2 * 1024 * 1024;
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setFileError("File size is too large. Please try another file smaller than 2MB.");
      } else if (file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png") {
        setFileError("File type must be a .jpg, .jpeg, or .png");
      } else {
        setFileError("");
        const reader = new FileReader();

        reader.addEventListener("load", function () {
          setSelectedFile({
            file: file,
            name: file.name,
          });
          file = {};
        });

        if (file) {
          setSelectedFile({
            file: file,
            name: file.name,
          });
          file = {};
        }
      }
    }
  };

  const clearFile = () => {
    setSelectedFile();
    setImageScale(1);
  };

  const zoom = (direction) => {
    if (imageScale <= 1.25 && direction === "out") {
      document.getElementById("profile-image-zoom-out").style.backgroundColor = "var(--tile-hover)";
      document.getElementById("profile-image-zoom-out").style.color = "var(--text-color-tertiary)";
    } else {
      document.getElementById("profile-image-zoom-out").style.backgroundColor = "var(--shoebox-yellow)";
      document.getElementById("profile-image-zoom-out").style.color = "var(--consistent-main)";
    }
    if (imageScale >= 4.75 && direction === "in") {
      document.getElementById("profile-image-zoom-in").style.backgroundColor = "var(--tile-hover)";
      document.getElementById("profile-image-zoom-in").style.color = "var(--text-color-tertiary)";
    } else {
      document.getElementById("profile-image-zoom-in").style.backgroundColor = "var(--shoebox-yellow)";
      document.getElementById("profile-image-zoom-in").style.color = "var(--consistent-main)";
    }
    if (direction === "in") {
      if (imageScale <= 4.75) {
        setImageScale(imageScale + 0.25);
      }
    }
    if (direction === "out") {
      if (imageScale >= 1.25) {
        setImageScale(imageScale - 0.25);
      }
    }
  };

  const savePicture = async () => {
    if (editorRef) {
      const dataUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      let croppedPic = {
        file: blob,
        name: selectPicture.name,
      };
      updateProfilePhoto(croppedPic)
        .then(() => {
          setSelectedFile();
          setImageScale(1);
          notify("Your profile photo has been successfully updated", "success");
        })
        .catch((err) => {
          notify("There was an error saving your profile photo. Please try again later.", "danger");
          console.error(err);
        });
    }
  };

  const deletePicture = () => {
    deleteProfilePhoto()
      .then(() => {
        setInitialDelete(false);
        notify("Your profile photo has been successfully deleted", "danger");
      })
      .catch((err) => {
        notify("There was an error removing your profile photo. Please try again later.", "danger");
        console.error(err);
      });
  };

  return (
    <section>
      <div className="settings-sub-header">
        <div className="title">
          <Link to="/profile">
            <sl-button variant="neutral" class="sub-setting-back-button">
              <TbArrowLeft />
            </sl-button>
          </Link>
          <h1>{profilePicture ? "Profile Picture" : "Personal Info"}</h1>
        </div>
        <div>
          {isDesktop && !profilePicture ? (
            <sl-button id="profile-update-button" onClick={() => sendNameUpdate(firstName, lastName)}>
              Update
            </sl-button>
          ) : null}
        </div>
      </div>
      {profilePicture ? (
        <section className="profile-picture-edit-container">
          <div className="picture-display-container">
            {!selectedFile?.file ? (
              activeUserProfile ? (
                activeUserProfile?.picture ? (
                  <img src={activeUserProfile?.picture} />
                ) : (
                  <ProfilePlaceholder
                    colorIndex={activeUserProfile?.defaultAvatar[0]}
                    iconIndex={activeUserProfile?.defaultAvatar[1]}
                    size="large"
                  />
                )
              ) : (
                <sl-skeleton effect="sheen" class="profile-picture-loader"></sl-skeleton>
              )
            ) : (
              <div className="photo-container">
                <AvatarEditor
                  className="photo-editor"
                  ref={editorRef}
                  image={selectedFile?.file}
                  width={200}
                  height={200}
                  border={0}
                  backgroundColor="#f3f3f3"
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={imageScale}
                  rotate={0}
                />
                <div className="zoom-controls">
                  <TbPlus id="profile-image-zoom-in" onClick={() => zoom("in")} />
                  <TbMinus id="profile-image-zoom-out" onClick={() => zoom("out")} />
                </div>
              </div>
            )}
          </div>
          <div className={selectedFile?.file ? "picture-edit-controls editing" : "picture-edit-controls"}>
            {!selectedFile?.file ? (
              initialDelete ? (
                <sl-button id="cancel-delete-action" variant="neutral" onClick={() => toggleDelete()}>
                  <IoClose />
                </sl-button>
              ) : (
                <label>
                  <input type="file" onChange={(e) => selectPicture(e)} />
                  <span>Upload New Photo</span>
                </label>
              )
            ) : (
              <sl-button onClick={() => savePicture()}>Save Photo</sl-button>
            )}
            {selectedFile?.file && (
              <sl-button class="clear-selected-button" variant="neutral" onClick={() => clearFile()}>
                Remove
              </sl-button>
            )}
            {activeUserProfile?.picture && !selectedFile?.file && (
              <sl-button
                class="remove-photo-button"
                onClick={() => {
                  initialDelete ? deletePicture() : toggleDelete();
                  setFileError("");
                }}
                variant="danger"
              >
                {initialDelete ? "Confirm Remove" : "Remove Photo"}
              </sl-button>
            )}
          </div>
        </section>
      ) : (
        <section className="personal-info-container">
          <div className="info-line">
            <label>First Name</label>
            <input
              id="profile-first-name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
            ></input>
          </div>
          <div className="info-line">
            <label>Last Name</label>
            <input
              id="profile-last-name"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
            ></input>
          </div>
          <div className="info-line">
            <label>Email Address</label>
            <div>
              {activeUserProfile?.email}
              <Link to="/profile/security" state={{ destination: "email" }}>
                Update
              </Link>
            </div>
          </div>
          <div className="switch-line">
            <label>HSA Plan Coverage</label>
            <div>
              {activeUserProfile ? (
                <sl-radio-group class="profile-info-button-group" value={coverageType}>
                  <sl-radio-button
                    class="info-group-button"
                    onClick={() => handleCoverageTypeUpdate("self")}
                    value={"self"}
                  >
                    Self
                  </sl-radio-button>
                  <sl-radio-button
                    class="info-group-button"
                    onClick={() => handleCoverageTypeUpdate("family")}
                    value={"family"}
                  >
                    Family
                  </sl-radio-button>
                </sl-radio-group>
              ) : (
                <sl-skeleton class="info-toggle-loader" effect="sheen"></sl-skeleton>
              )}
            </div>
          </div>
          <div className="eligibility-block">
            <div className="eligibility-header">
              <label>
                Catch-Up Eligiblity{" "}
                <TbInfoCircleFilled onClick={() => document.getElementById("catch-up-help-popup")?.show()} />
              </label>
            </div>
            <div className="eligibility-content">
              <div className="status-line">
                <sl-badge
                  variant="neutral"
                  class={activeUserProfile?.catchUpEligible ? "eligible-chip is-eligible" : "eligible-chip"}
                >
                  <TbRosetteDiscountCheckFilled />
                  <span className="chip-text">
                    {activeUserProfile?.catchUpEligible ? "Catch-Up Eligible" : "Not Catch-Up Eligible"}
                  </span>
                </sl-badge>
              </div>
              {!editingDob && <a onClick={() => toggleEditDob()}>Update DOB</a>}
            </div>
            <div className={editingDob ? "dob-input-wrapper expanded" : "dob-input-wrapper"}>
              <div className="dob-form">
                <input
                  type="date"
                  onBlur={(e) => setDob(e.target.value)}
                  onChange={(e) => setDob(e.target.value)}
                  value={dob ? dob : "1990-01-01"}
                ></input>
                <div className="form-buttons">
                  <sl-button variant="neutral" onClick={() => toggleEditDob()}>
                    Cancel
                  </sl-button>
                  <sl-button id="dob-update-button" onClick={() => handleDobUpdate(dob)}>
                    Update
                  </sl-button>
                </div>
              </div>
            </div>
          </div>
          <div className="info-line">
            <label>Member Since</label>
            <div>{activeUserProfile?.created ? memberDateHandler(activeUserProfile?.created) : ""}</div>
          </div>
          <div className="info-line">
            <label>Terms of Use & Privacy Policy Acknowledgements</label>
            <div className="ack-line">
              <span className="terms-agreement-line">
                {currentTermsVersion + " Terms of Use — "}
                {activeUserProfile?.useAgreements?.termsOfUse?.version === currentTermsVersion ? (
                  <div>
                    {activeUserProfile?.useAgreements?.termsOfUse?.agreementDate ? (
                      shortDateHandler(activeUserProfile?.useAgreements?.termsOfUse?.agreementDate)
                    ) : (
                      <sl-spinner variant="search"></sl-spinner>
                    )}
                  </div>
                ) : (
                  <span className="no-ack">Not Acknowledged</span>
                )}
              </span>
              {activeUserProfile?.useAgreements?.termsOfUse?.version === currentTermsVersion ? (
                <Link to="/terms-of-use">View</Link>
              ) : (
                <a onClick={() => openTermsAgreementPopup()}>Open</a>
              )}
            </div>
            <div className="ack-line">
              <span className="terms-agreement-line">
                {currentPrivacyVersion + " Privacy Policy — "}
                {/* {<sl-icon name="patch-check-fill"></sl-icon>} */}
                {activeUserProfile?.useAgreements?.privacyPolicy?.version === currentPrivacyVersion ? (
                  <div>
                    {activeUserProfile?.useAgreements?.privacyPolicy?.agreementDate ? (
                      shortDateHandler(activeUserProfile?.useAgreements?.privacyPolicy?.agreementDate)
                    ) : (
                      <sl-spinner variant="search"></sl-spinner>
                    )}
                  </div>
                ) : (
                  <span className="no-ack">Not Acknowledged</span>
                )}
              </span>
              {activeUserProfile?.useAgreements?.privacyPolicy?.version === currentPrivacyVersion ? (
                <Link to="/privacy-policy">View</Link>
              ) : (
                <a onClick={() => openTermsAgreementPopup()}>Open</a>
              )}
            </div>
          </div>
          {isDesktop ? null : (
            <sl-button id="profile-update-button-mobile" class="mobile-update">
              Update
            </sl-button>
          )}
        </section>
      )}
      <div className={fileError ? "form-error-wrapper shown" : "form-error-wrapper"}>
        <span className="file-form-error">{fileError}</span>
      </div>
      <sl-dialog label="Catch-Up Eligibility Help" id="catch-up-help-popup">
        <div>
          <h4>
            <FaUmbrellaBeach className="beach-icon" />
            What is "Catch-Up Eligibility"?
          </h4>
          <p>
            Catch-up eligibility allows account holders age 55 or older to make additional contributions to their HSA
            beyond the standard annual limit. This extra contribution is designed to help individuals boost their
            savings as they approach retirement.
          </p>
          <h4>
            <MdChair />
            How is eligibility defined?
          </h4>
          <p>
            Eligibility for catch-up contributions is based on the account holder’s age—specifically, reaching age 55
            within the current tax year. This age is set by the IRS and is subject to change following any tax law
            revisions.
          </p>
          <p>
            We ask for your date of birth solely for the purpose of determining your eligibility for catch-up
            contributions. This ensures your dashboards and calculations are as accurate as possible.
          </p>
        </div>
      </sl-dialog>
    </section>
  );
};

export { PersonalInfo };
