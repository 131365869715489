export const subscriptionDateHandler = (date) => {
  let month;
  let year;
  let day;
  if (typeof date === "number") {
    month = new Date(date * 1000).getMonth();
    year = new Date(date * 1000).getFullYear();
    day = new Date(date * 1000).getDate();
  } else {
    month = new Date(date).getMonth();
    year = new Date(date).getFullYear();
    day = new Date(date).getDate();
  }

  return dateHandler(year + "-" + (month + 1) + "-" + day);
};

export const dateHandler = (date) => {
  let splitDate = date.split("-");
  let year = +splitDate[0];
  let day = +splitDate[2];
  let monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthIndex = +splitDate[1] - 1;
  let month = monthOptions[monthIndex];

  return `${month} ${day}, ${year}`;
};

export const shortDateHandler = (date) => {
  // Leaves on leading zeros
  let splitDate = date.split("-");
  let year = splitDate[0];
  let day = splitDate[2];
  let month = splitDate[1];

  return `${month}/${day}/${year}`;
};

export const shorterDateHandler = (date) => {
  // Leaves on leading zeros
  let splitDate = date.split("-");
  let year = splitDate[0].slice(2);
  let day = splitDate[2];
  let month = splitDate[1];

  return `${month}/${day}/${year}`;
};

export const memberDateHandler = (date) => {
  let splitDate = date.split("-");
  let year = +splitDate[0];
  let monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthIndex = +splitDate[1] - 1;
  let month = monthOptions[monthIndex];

  return `${month} ${year}`;
};

export function dateToStandardDateString(date) {
  let month;
  let year;
  let day;
  if (typeof date === "number") {
    month = new Date(date * 1000).getMonth();
    year = new Date(date * 1000).getFullYear();
    day = new Date(date * 1000).getDate();
  } else {
    month = new Date(date).getMonth();
    year = new Date(date).getFullYear();
    day = new Date(date).getDate();
  }

  return year + "-" + (month + 1) + "-" + day;
}

export function parseDateToLocal(dateString) {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
}

export function parseDateToUTC(dateString) {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(Date.UTC(year, month - 1, day));
}

export function getNearestDate(date1, date2) {
  const now = Date.now();

  // If either date is null, return the other
  if (!date1) return date2;
  if (!date2) return date1;

  // Convert both dates to milliseconds for comparison
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();

  // Compare the absolute differences from the current time
  return Math.abs(time1 - now) < Math.abs(time2 - now) ? date1 : date2;
}
