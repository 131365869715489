import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "../global/Loader";
import { createCheckoutSession } from "../../stripe/checkoutSession";
import {
  TbAlertTriangleFilled,
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
  TbSquareRoundedLetterW,
} from "react-icons/tb";
import { HiArchiveBox, HiBriefcase } from "react-icons/hi2";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaRegFaceGrinWink } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ExpenseFormLight from "../../assets/onboarding/expense-form-light.svg";
import ExpenseFormDark from "../../assets/onboarding/expense-form-dark.svg";
import AddExpenseButton from "../../assets/onboarding/add-expense-button.svg";
import InvoiceLight from "../../assets/onboarding/Invoice_Light.svg";
import InvoiceDark from "../../assets/onboarding/Invoice_Dark.svg";
import WithdrawnListLight from "../../assets/onboarding/withdrawn-list-item-light.png";
import WithdrawnListDark from "../../assets/onboarding/withdrawn-list-item-dark.png";
import WithdrawnDetailLight from "../../assets/onboarding/withdrawn-detail-light.png";
import WithdrawnDetailDark from "../../assets/onboarding/withdrawn-detail-dark.png";
import AccountValueInputLight from "../../assets/onboarding/account-value-input-light.png";
import AccountValueInputDark from "../../assets/onboarding/account-value-input-dark.png";
import AccountValueTileLight from "../../assets/onboarding/account-value-tile-light.png";
import AccountValueTileDark from "../../assets/onboarding/account-value-tile-dark.png";
import ContributionScheduleLight from "../../assets/onboarding/contribution-schedule-light.png";
import ContributionScheduleDark from "../../assets/onboarding/contribution-schedule-dark.png";
import ContributionTileLight from "../../assets/onboarding/contribution-tile-light.png";
import ContributionTileDark from "../../assets/onboarding/contribution-tile-dark.png";
import IndexListLight from "../../assets/onboarding/index-list-light.png";
import IndexListDark from "../../assets/onboarding/index-list-dark.png";
import CashTrackingLight from "../../assets/onboarding/cash-tracking-light.png";
import CashTrackingDark from "../../assets/onboarding/cash-tracking-dark.png";
import PersonalInfoLight from "../../assets/onboarding/personal-info-light.png";
import PersonalInfoDark from "../../assets/onboarding/personal-info-dark.png";
import SubscriptionInfoLight from "../../assets/onboarding/subscription-info-light.png";
import SubscriptionInfoDark from "../../assets/onboarding/subscription-info-dark.png";
import SupportOptionsLight from "../../assets/onboarding/support-options-light.png";
import SupportOptionsDark from "../../assets/onboarding/support-options-dark.png";

const Onboarding = () => {
  const { activeUserProfile, loadingCheckout, setLoadingCheckout, welcomeDaysOptOut, colorMode } = useAuth();

  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("Expenses");

  const handleOptOut = () => {
    welcomeDaysOptOut().then(() => {
      navigate("/expenses");
    });
  };

  const renderSectionCard = (activeSection) => {
    let cardContent;
    if (activeSection === "Expenses") {
      cardContent = (
        <>
          <h3>Track & Organize Your Medical Expenses</h3>
          <div className="description">
            Shoebox helps you keep track of HSA-eligible expenses, store receipts, and manage withdrawals
            (reimbursements) efficiently.
          </div>
          <h3 className="secondary">Here's The Basics:</h3>
          <div className="feature-tiles">
            <div className="feature-card">
              <h4>
                <TbCircleNumber1Filled />
                Add, Update & Manage Expenses
              </h4>
              <div className="feature-support-text">
                Keep track of your HSA-eligible expenses by adding new entries, assigning insurance providers, and
                organizing your spending history through notes & tags.
              </div>
              <img className="expense-form-img" src={colorMode === "light" ? ExpenseFormLight : ExpenseFormDark} />
              <div className="feature-support-text with-button-img">
                Click <img src={AddExpenseButton} /> to add your first expense!
              </div>
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber2Filled />
                Upload Receipts
              </h4>
              <div className="feature-support-text">
                Store digital copies of your medical receipts to ensure easy access for reimbursements and tax
                documentation.
              </div>
              <img className="invoice-image" src={colorMode === "light" ? InvoiceLight : InvoiceDark} />
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber3Filled />
                Track Withdrawn Items
              </h4>
              <div className="feature-support-text">
                Monitor which expenses have been reimbursed and maintain an accurate record of your HSA withdrawals.
              </div>
              <div className="feature-support-text">
                Expenses that have been withdrawn will be marked with a <TbSquareRoundedLetterW className="w-icon" /> in
                the expense list.
              </div>
              <img className="fw-image" src={colorMode === "light" ? WithdrawnListLight : WithdrawnListDark} />
              <div className="feature-support-text">
                Viewing the expense detail, you can view the withdrawal details and make any necessary updates to help
                track your reimbursements.
              </div>
              <img className="fw-image" src={colorMode === "light" ? WithdrawnDetailLight : WithdrawnDetailDark} />
            </div>
          </div>
        </>
      );
    }
    if (activeSection === "Portfolio") {
      cardContent = (
        <>
          <h3>Monitor Your HSA Growth</h3>
          <div className="description">
            Watch your HSA investments grow over time, track index or cash returns, and view how your expenses stack up
            against total account value.
          </div>
          <h3 className="secondary">Key Portfolio Actions:</h3>
          <div className="feature-tiles" id="portfolio-tiles">
            <div className="feature-card">
              <h4>
                <TbCircleNumber1Filled />
                Set Your Current Account Value
              </h4>
              <div className="feature-support-text">
                Enter your current HSA balance to start tracking your account growth and financial progress.
              </div>
              <img className="fw-image" src={colorMode === "light" ? AccountValueInputLight : AccountValueInputDark} />
              <div className="feature-support-text">
                Click Configuration and Edit your Total Account Value to start.
              </div>
              <img className="fw-image" src={colorMode === "light" ? AccountValueTileLight : AccountValueTileDark} />
              <div className="feature-support-text">
                Don't have any money in your HSA account yet? No worries, we'll leave this as $0.00!
              </div>
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber2Filled />
                Log & Automate Contributions
              </h4>
              <div className="feature-support-text">
                Record one-time deposits or set up recurring contributions to stay on top of your HSA funding.
              </div>
              <img
                className="fw-image"
                src={colorMode === "light" ? ContributionScheduleLight : ContributionScheduleDark}
              />
              <div className="feature-support-text">
                We provide a helpful dashboard view to encourage you to reach the annual contribution limit each year.
              </div>
              <img className="fw-image" src={colorMode === "light" ? ContributionTileLight : ContributionTileDark} />
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber3Filled />
                Enable Portfolio Tracking
              </h4>
              <div className="feature-support-text">
                Monitor your HSA investments with index-based growth projections and cash balance insights.
              </div>
              <img className="fw-image" src={colorMode === "light" ? IndexListLight : IndexListDark} />
              <div className="feature-support-text">
                Select from one our four major indices that align with your HSA account investments. Holding cash
                instead? Use the Track Cash button to enter in an APY, if applicable.
              </div>
              <img className="fw-image" src={colorMode === "light" ? CashTrackingLight : CashTrackingDark} />
            </div>
          </div>
        </>
      );
    }
    if (activeSection === "Profile & Settings") {
      cardContent = (
        <>
          <h3>Personalize Your Shoebox Experience</h3>
          <div className="description">
            Update your account details, manage your subscription, share feedback, and explore helpful resources.
          </div>
          <h3 className="secondary">Make It Your Own:</h3>
          <div className="feature-tiles" id="profile-tiles">
            <div className="feature-card">
              <h4>
                <TbCircleNumber1Filled />
                Edit Personal Information
              </h4>
              <div className="feature-support-text">
                Keep your account details up to date, including personal and financial settings.
              </div>
              <img className="fw-image" src={colorMode === "light" ? PersonalInfoLight : PersonalInfoDark} />
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber2Filled />
                Manage Your Subscription
              </h4>
              <div className="feature-support-text">
                View and adjust your Shoebox subscription to fit your needs and budget.
              </div>
              <img className="fw-image" src={colorMode === "light" ? SubscriptionInfoLight : SubscriptionInfoDark} />
              {/* <img className="invoice-image" src={InvoiceImage} /> */}
            </div>
            <div className="feature-card">
              <h4>
                <TbCircleNumber3Filled />
                Access Support & Give Feedback
              </h4>
              <div className="feature-support-text">
                Get help when needed and view HSA resources & educational materials.
              </div>
              <img className="fw-image" src={colorMode === "light" ? SupportOptionsLight : SupportOptionsDark} />
              <div className="feature-support-text">
                We want to hear your thoughts! Your feedback is critical to help improve the Shoebox experience. Any
                constructive (or positive <FaRegFaceGrinWink />) feedback is greatly appreciated.
              </div>
            </div>
          </div>
        </>
      );
    }
    let id = activeSection.toLowerCase().split(" ")[0];
    return (
      <div className="section-overview-card">
        <div className="section-content-wrapper" id={id}>
          <h2>{activeSection}</h2>
          <section className="section-content">{cardContent}</section>
        </div>
      </div>
    );
  };

  return (
    <div className="onboarding-container">
      <h1>Welcome to Shoebox, {activeUserProfile.firstName}</h1>
      {activeUserProfile ? (
        <section>
          {!activeUserProfile.subActive ? (
            loadingCheckout ? (
              <div className="loading-checkout-block">
                <Loader />
                <span>Preparing Subscription Checkout Session</span>
                <span className="subtext">You will be redirected to Stripe.</span>
              </div>
            ) : (
              <div className="manual-checkout-block">
                <span>
                  <TbAlertTriangleFilled />
                </span>
                <span>There was an issue while automatically entering checkout.</span>
                <span>Please click the button below to be redirected to Stripe.</span>
                <sl-button
                  onClick={() =>
                    createCheckoutSession(
                      activeUserProfile.uid,
                      activeUserProfile.plan,
                      "create-account",
                      setLoadingCheckout
                    )
                  }
                >
                  {`Start Checkout for ${
                    activeUserProfile.plan.charAt(0).toUpperCase() + activeUserProfile.plan.slice(1)
                  } Subscription`}
                </sl-button>
              </div>
            )
          ) : (
            <section className="intro-cards-block">
              <div className="exit-options">
                <a onClick={() => navigate("/expenses")}>Skip Intro For Now</a>
                <a className="secondary" onClick={() => handleOptOut()}>
                  Don't Show Again
                </a>
              </div>
              <span className="support-text">Explore the areas of the app to learn how to get started:</span>
              <div className="button-container">
                <sl-button
                  id="blocks-icon-button"
                  onClick={() => setActiveSection("Expenses")}
                  variant={activeSection === "Expenses" ? "primary" : "neutral"}
                >
                  <HiArchiveBox />
                  <span>Expenses</span>
                </sl-button>
                <sl-button
                  id="blocks-icon-button"
                  onClick={() => setActiveSection("Portfolio")}
                  variant={activeSection === "Portfolio" ? "primary" : "neutral"}
                >
                  <HiBriefcase />
                  <span>Portfolio</span>
                </sl-button>
                <sl-button
                  class="full-width"
                  id="blocks-icon-button"
                  onClick={() => setActiveSection("Profile & Settings")}
                  variant={activeSection === "Profile & Settings" ? "primary" : "neutral"}
                >
                  <IoPersonCircleOutline />
                  <span>Profile & Settings</span>
                </sl-button>
              </div>
              {renderSectionCard(activeSection)}
            </section>
          )}
        </section>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export { Onboarding };
