import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ButtonLoader } from "../global/Loader";
import { validateEmail } from "../../hooks-n-utils/email-validation";
import { validateMinimumAge, validateCatchUpAge } from "../../hooks-n-utils/age-validation";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import { BsFillClipboard2HeartFill, BsFillCalendar2CheckFill } from "react-icons/bs";
import { HiQuestionMarkCircle } from "react-icons/hi";

const SignUp = ({ selectedPlan, setSelectedPlan }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dob, setDob] = useState("");
  const [planCoverageType, setPlanCoverageType] = useState("self");
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { signUp, createAccount, getCurrentAgreementVersions, currentTermsVersion, currentPrivacyVersion } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let createButton = document.getElementById("create-account-btn");
  let passwordField = document.getElementById("pass-input");
  let confirmPasswordField = document.getElementById("confirm-pass-input");
  let acceptToggle = document.getElementById("accept-toggle");

  useEffect(() => {
    if (createButton && passwordField && confirmPasswordField && acceptToggle) {
      passwordField.addEventListener("sl-input", (e) => {
        setPassword(e.target.value);
      });
      confirmPasswordField.addEventListener("sl-input", (e) => {
        setConfirmPassword(e.target.value);
      });
      acceptToggle.addEventListener("sl-change", (e) => {
        setAcceptTerms(e.target.checked);
      });
      setError("");
      if (firstName && lastName && email && password && confirmPassword && dob && acceptTerms) {
        if (loading) {
          createButton.disabled = true;
        } else {
          createButton.disabled = false;
        }
      } else {
        createButton.disabled = true;
      }
    }

    return () => {
      passwordField?.removeEventListener("sl-input", () => {});
      confirmPasswordField?.removeEventListener("sl-input", () => {});
      acceptToggle?.removeEventListener("sl-change", () => {});
    };
  }, [
    createButton,
    passwordField,
    confirmPasswordField,
    acceptToggle,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    dob,
    acceptTerms,
  ]);

  useEffect(() => {
    if (!currentTermsVersion || !currentPrivacyVersion) {
      getCurrentAgreementVersions();
    }
  }, [currentTermsVersion, currentPrivacyVersion]);

  const handleSubmit = async () => {
    if (!currentTermsVersion || !currentPrivacyVersion) {
      console.error("No Terms of Use or Privacy Policy available. Unable to create accounts at this time.");
      return;
    }
    if (firstName.length < 2) {
      return setError("First Name must be at least 2 characters.");
    }
    if (lastName.length < 2) {
      return setError("Last Name must be at least 2 characters.");
    }
    if (!validateEmail(email)) {
      return setError("Please enter a valid email address.");
    }
    if (!validateMinimumAge(dob)) {
      return setError("Users must be over the age of 18.");
    }
    if (password !== confirmPassword) {
      return setError("Passwords do not match! Please try again.");
    }
    if (password.length < 6) {
      return setError("Password must be at least 6 characters.");
    }
    let catchUpEligible = validateCatchUpAge(dob);
    setError("");
    setLoading(true);
    let plan = selectedPlan.toLowerCase();
    await signUp(email, password, firstName, lastName)
      .then(() => {
        createAccount(plan, firstName, lastName, dob, catchUpEligible, planCoverageType).then((success) => {
          if (success) {
            setLoading(false);
          } else {
            setLoading(false);
            setError("There was an error creating your account. Please try again later.");
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.code === "auth/email-already-in-use") {
          setError("Looks like there is already an account for this email! Please try another.");
        } else {
          setError(err.code);
        }
        setLoading(false);
      });
  };

  return (
    <div className="account-details">
      <div className="sign-up-form">
        <div className="name-block">
          <span className="input-block">
            <label>First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </span>
          <span className="input-block">
            <label>Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </span>
        </div>
        <span className="input-block">
          <label>Email Address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </span>

        <sl-input type="password" id="pass-input" value={password} label="Password" filled password-toggle>
          <span className="password-toggle" slot="hide-password-icon">
            <RiEyeFill />
          </span>
          <span className="password-toggle" slot="show-password-icon">
            <RiEyeCloseFill />
          </span>
        </sl-input>
        <sl-input
          type="password"
          id="confirm-pass-input"
          value={confirmPassword}
          label="Confirm Password"
          filled
          password-toggle
        >
          <span className="password-toggle" slot="hide-password-icon">
            <RiEyeFill />
          </span>
          <span className="password-toggle" slot="show-password-icon">
            <RiEyeCloseFill />
          </span>
        </sl-input>
        <span className="input-block">
          <label className="label-with-help-icon">
            Date of Birth <HiQuestionMarkCircle onClick={() => document.getElementById("sign-up-dob-popup")?.show()} />
          </label>
          <input
            type="date"
            value={dob}
            onChange={(e) => {
              setDob(e.target.value);
            }}
          />
        </span>
        <sl-dialog label="Why Do We Ask For Your DOB?" id="sign-up-dob-popup">
          <div>
            <h4>
              <BsFillCalendar2CheckFill className="beach-icon" />
              Determining Your Catch-Up Eligibility
            </h4>
            <p>
              With your birthday, we are able to calculate if you are eligible to make catch-up contributions (over 55
              years old). This extra amount is designed to help people closer to retirement to save even more as a way
              to 'catch up'.
            </p>
            <p>We do not use this information for any other purpose -- other than to wish you a happy birthday!</p>
          </div>
        </sl-dialog>
        <div className="toggles">
          <label className="label-with-help-icon">
            Health Plan Coverage
            <HiQuestionMarkCircle onClick={() => document.getElementById("sign-up-plan-type-popup")?.show()} />
          </label>
          <sl-radio-group class="profile-info-button-group" value={planCoverageType}>
            <sl-radio-button class="info-group-button" value={"self"} onClick={() => setPlanCoverageType("self")}>
              Self
            </sl-radio-button>
            <sl-radio-button class="info-group-button" value={"family"} onClick={() => setPlanCoverageType("family")}>
              Family
            </sl-radio-button>
          </sl-radio-group>
        </div>
        <sl-dialog label="Selecting Health Plan Coverage" id="sign-up-plan-type-popup">
          <div>
            <h4>
              <BsFillClipboard2HeartFill className="beach-icon" />
              Picking Your Coverage Type
            </h4>
            <p>
              If you are the only person covered by your health insurance policy, then you should select{" "}
              <strong>'Self'</strong>. For policies that have more than one person covered (typically a spouse and/or
              children), then you should select the <strong>'Family'</strong> option.
            </p>
            <p>
              This selection allows us to determine what the correct contribution limit to apply for any given year.
              Feel free to update this anytime if something changes. The limits will automatically reflect in your
              portfolio.
            </p>
          </div>
        </sl-dialog>
      </div>
      <div className="terms-block">
        <sl-switch id="accept-toggle" value={acceptTerms}></sl-switch>
        <span>
          By creating an account, you agree to the Shoebox <br />
          <a href="https://shoebox.finance/terms-of-use" target="_blank">
            Terms of Use
          </a>{" "}
          &{" "}
          <a href="https://shoebox.finance/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </span>
      </div>
      <div className={error === "" ? "alert" : "alert error"}>{error}</div>
      <section className="sign-in-controls">
        <sl-button id="create-account-btn" onClick={() => handleSubmit()}>
          {loading ? <ButtonLoader /> : "Create Account"}
        </sl-button>
      </section>
    </div>
  );
};

export { SignUp };
