import aetnaLight from "../assets/insurance-graphics/Aetna_Light.svg";
import aetnaDark from "../assets/insurance-graphics/Aetna_Dark.svg";
import bcbsLight from "../assets/insurance-graphics/BCBS_Light.svg";
import bcbsDark from "../assets/insurance-graphics/BCBS_Dark.svg";
import centeneLight from "../assets/insurance-graphics/Centene_Light.svg";
import centeneDark from "../assets/insurance-graphics/Centene_Dark.svg";
import cignaLight from "../assets/insurance-graphics/Cigna_Light.svg";
import cignaDark from "../assets/insurance-graphics/Cigna_Dark.svg";
import harvardLight from "../assets/insurance-graphics/Harvard_Light.svg";
import harvardDark from "../assets/insurance-graphics/Harvard_Dark.svg";
import humanaLight from "../assets/insurance-graphics/Humana_Light.svg";
import humanaDark from "../assets/insurance-graphics/Humana_Dark.svg";
import kaiserLight from "../assets/insurance-graphics/Kaiser_Light.svg";
import kaiserDark from "../assets/insurance-graphics/Kaiser_Dark.svg";
import uhcLight from "../assets/insurance-graphics/UHC_Light.svg";
import uhcDark from "../assets/insurance-graphics/UHC_Dark.svg";

export const insuranceAssetProvider = (insurance, colorMode) => {
  let setColor = localStorage.getItem("theme");

  if (insurance === "other") return;

  const insuranceLogos = {
    aetna: {
      light: aetnaLight,
      dark: aetnaDark,
    },
    bcbs: {
      light: bcbsLight,
      dark: bcbsDark,
    },
    centene: {
      light: centeneLight,
      dark: centeneDark,
    },
    cigna: {
      light: cignaLight,
      dark: cignaDark,
    },
    harvard: {
      light: harvardLight,
      dark: harvardDark,
    },
    humana: {
      light: humanaLight,
      dark: humanaDark,
    },
    kaiser: {
      light: kaiserLight,
      dark: kaiserDark,
    },
    uhc: {
      light: uhcLight,
      dark: uhcDark,
    },
  };

  // Ensure that the insurance provider exists and return the correct asset
  if (insuranceLogos[insurance]) {
    return insuranceLogos[insurance][colorMode === "system" ? setColor : colorMode];
  } else {
    return null; // Or return a default logo if needed
  }
};
