export function notify(message, variant) {
  let icon;

  switch (variant) {
    case "success":
      icon = "check-circle-fill";
      break;
    case "primary":
      icon = "chat-fill";
      break;
    case "update":
      icon = "file-earmark-check-fill";
      break;
    case "danger":
      icon = "exclamation-diamond-fill";
      break;
    default:
      icon = "app-indicator";
  }

  function escapeHtml(html) {
    const div = document.createElement("div");
    div.textContent = html;
    return div.innerHTML;
  }

  const alert = Object.assign(document.createElement("sl-alert"), {
    variant,
    closable: true,
    duration: 2000,
    innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `,
  });

  document.body.append(alert);
  return alert.toast();
}
