import React, { useEffect, useState } from "react";
import { currencyHandler } from "../../../../hooks-n-utils/currency";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";
import { useAuth } from "../../../../contexts/AuthContext";
import { TbArrowNarrowUp, TbLine } from "react-icons/tb";
import { dateToStandardDateString, shortDateHandler } from "../../../../hooks-n-utils/date";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import { calculateWeightedPercentChange } from "../../../../hooks-n-utils/investment-utils";
import { Loader } from "../../../global/Loader";

const AccountValue = () => {
  const {
    totalAccountValue,
    currentUser,
    activeUserProfile,
    getInvestmentData,
    totalAccountLoading,
    loadingInvestmentData,
    investmentData,
    noInvestmentData,
  } = useAuth();

  const [rawMarkers, setRawMarkers] = useState([]);
  const [rawValues, setRawValues] = useState([]);
  const [rawObjects, setRawObjects] = useState([]);

  const [dataPointMarkers, setDataPointMarkers] = useState([]);
  const [dataPointValues, setDataPointValues] = useState([]);
  const [dataPointObjects, setDataPointObjects] = useState([]);
  const [hoverDataPoint, setHoverDataPoint] = useState();

  const [intervalSelection, setIntervalSelection] = useState("M");
  const [intervalSelectionExpanded, setIntervalSelectionExpanded] = useState(false);

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  useEffect(() => {
    if (!investmentData && !noInvestmentData) {
      getInvestmentData(currentUser.uid);
    }
    // sets session value to stop all tile chart animations after initial user session load
    if (!afterFirstVisit) {
      setTimeout(() => {
        window.sessionStorage.setItem("after-first-session-portfolio-visit", true);
      }, 3000);
    }
    if (investmentData || !dataPointValues) {
      generateDataArrays(investmentData);
    }
  }, [afterFirstVisit, investmentData]);

  const generateDataArrays = (data) => {
    let yearDoc = [];

    for (let i = 0; i < data.length; i++) {
      let dataPoints = data[i]?.data;
      dataPoints?.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
      yearDoc = yearDoc.concat(dataPoints);
    }

    const n = yearDoc?.length;
    const tempMarkersArray = [];
    const tempValuesArray = [];
    const tempObjectsArray = [];

    let interval;

    switch (intervalSelection) {
      case "M":
        interval = 30;
        break;
      case "W":
        interval = 7;
        break;
      case "Q":
        interval = 90;
        break;
      case "Y":
        interval = 365;
        break;
      default:
        return;
    }

    for (let i = 0; i < n; i++) {
      tempMarkersArray.push(i + 1);
      tempValuesArray.push(yearDoc[i].accountValue);
      tempObjectsArray.push(yearDoc[i]);
    }
    setRawMarkers(tempMarkersArray);
    setRawValues(tempValuesArray);
    setRawObjects(tempObjectsArray);

    if (yearDoc.length < interval) {
      let difference = interval - tempMarkersArray.length;
      const n = interval;
      const startTempMarkersArray = [];
      const startTempValuesArray = [];
      const startTempObjectsArray = [];

      for (let i = 0; i < n; i++) {
        startTempMarkersArray.push(i + 1);
      }

      for (let i = 0; i < difference; i++) {
        startTempValuesArray.push(tempValuesArray[0]);
        startTempObjectsArray.push(tempObjectsArray[0]);
      }

      for (let i = 0; i < tempValuesArray.length; i++) {
        startTempValuesArray.push(tempValuesArray[i]);
        startTempObjectsArray.push(tempObjectsArray[i]);
      }
      startTempValuesArray.push(tempValuesArray);

      setDataPointMarkers(startTempMarkersArray);
      setDataPointValues(startTempValuesArray);
      setDataPointObjects(startTempObjectsArray);
    } else {
      setDataPointMarkers(tempMarkersArray.slice(-interval));
      setDataPointValues(tempValuesArray.slice(-interval));
      setDataPointObjects(tempObjectsArray.slice(-interval));
    }
  };

  const toggleSelector = () => {
    setIntervalSelectionExpanded(!intervalSelectionExpanded);
  };

  const handleInvervalSelection = (interval) => {
    setIntervalSelection(interval);
    switch (interval) {
      case "M":
        interval = 30;
        break;
      case "W":
        interval = 7;
        break;
      case "Q":
        interval = 90;
        break;
      case "Y":
        interval = 365;
        break;
      default:
        break;
    }
    if (interval === "ALL") {
      setDataPointMarkers(rawMarkers);
      setDataPointValues(rawValues);
      setDataPointObjects(rawObjects);
      toggleSelector();
      return;
    }
    if (rawMarkers.length < interval) {
      let difference = interval - rawMarkers.length;
      const n = interval;
      const tempMarkersArray = [];
      const tempValuesArray = [];
      const tempObjectsArray = [];

      for (let i = 0; i < n; i++) {
        tempMarkersArray.push(i + 1);
      }

      for (let i = 0; i < difference; i++) {
        tempValuesArray.push(rawValues[0]);
        tempObjectsArray.push(rawObjects[0]);
      }
      for (let i = 0; i < rawValues.length; i++) {
        tempValuesArray.push(rawValues[i]);
        tempObjectsArray.push(rawObjects[i]);
      }
      tempValuesArray.push(rawValues);

      setDataPointMarkers(tempMarkersArray);
      setDataPointValues(tempValuesArray);
      setDataPointObjects(tempObjectsArray);
      toggleSelector();
    } else {
      setDataPointMarkers(rawMarkers.slice(-interval));
      setDataPointValues(rawValues.slice(-interval));
      setDataPointObjects(rawObjects.slice(-interval));
      toggleSelector();
    }
  };

  const leaveCanvas = () => {
    setTimeout(() => {
      setHoverDataPoint();
    }, 100);
  };

  const hoverLine = {
    id: "hoverLine",
    afterDraw: function (chart) {
      const ctx = chart.ctx;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const x = activePoint.element.x;
        const topY = chart.scales.y?.top;
        const bottomY = chart.scales.y?.bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#dddddd";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, hoverLine);

  let labels = dataPointMarkers;

  let style = getComputedStyle(document.body);
  let shoeboxYellow = style.getPropertyValue("--shoebox-yellow");

  let data = {
    labels,
    datasets: [{ data: dataPointValues }],
  };

  useEffect(() => {
    labels = dataPointMarkers;
    data = {
      labels,
      datasets: [{ data: dataPointValues }],
    };
  }, [dataPointValues, dataPointMarkers, dataPointObjects]);

  const tooltip = {
    id: "tooltip",
    backgroundColor: "transparent",
    titleColor: "transparent",
    bodyColor: "transparent",
    displayColors: false,
    callbacks: {
      title: function () {
        return;
      },
      label: function (tooltipItem) {
        setHoverDataPoint(dataPointObjects[tooltipItem.dataIndex]);
        return;
      },
    },
    mode: "index",
    intersect: false,
  };

  let options = {
    animation: {
      duration: !afterFirstVisit ? 600 : 0,
    },
    events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend"],
    responsive: true,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    borderColor: shoeboxYellow,
    borderWidth: 5,
    tension: 0.33,
    backgroundColor: shoeboxYellow,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      hoverLine,
      tooltip,
    },
  };

  if (loadingInvestmentData || totalAccountLoading) {
    return (
      <div className="portfolio-tile loading" id="account-value-loading-tile">
        <div className="tile-header">
          <small>Total Account Value</small>
        </div>
        <div className="account-value-loader">
          <Loader />
        </div>
      </div>
    );
  } else {
    return (
      <div className="portfolio-tile" id="account-value-tile" onMouseOut={leaveCanvas} onTouchEnd={leaveCanvas}>
        <div className="tile-header">
          <small>Total Account Value</small>
          {totalAccountValue === null ? (
            <span className="account-value not-set">Not Set</span>
          ) : (
            <span className="account-value">
              {currencyHandler(hoverDataPoint ? hoverDataPoint?.accountValue : totalAccountValue)}
            </span>
          )}

          {!noInvestmentData && hoverDataPoint ? (
            <div className="datapoint-detail">
              <span>{shortDateHandler(hoverDataPoint?.date)}</span>
              <GoDotFill className="divider" />
              <span
                className={
                  calculateWeightedPercentChange(activeUserProfile.preferences[1].weightingMethod, hoverDataPoint) !== 0
                    ? calculateWeightedPercentChange(activeUserProfile.preferences[1].weightingMethod, hoverDataPoint) >
                      0
                      ? "percent-change positive"
                      : "percent-change negative"
                    : "percent-change neutral"
                }
              >
                <TbArrowNarrowUp />
                {`${calculateWeightedPercentChange(activeUserProfile.preferences[1].weightingMethod, hoverDataPoint)}%`}
              </span>
            </div>
          ) : null}
        </div>
        {noInvestmentData || (!totalAccountValue && totalAccountValue !== 0) ? (
          <div className="empty-data">
            <TbLine />
            No datapoints yet! Check back tomorrow for this chart.
          </div>
        ) : null}
        {!noInvestmentData || (!totalAccountValue && totalAccountValue !== 0 && dataPointValues) ? (
          <Line data={data} options={options} />
        ) : null}
        {!noInvestmentData || (!totalAccountValue && totalAccountValue !== 0) ? (
          <div className="interval-selector-wrapper">
            <div className={intervalSelectionExpanded ? "interval-option-drawer expanded" : "interval-option-drawer"}>
              {intervalSelectionExpanded ? (
                <>
                  <button
                    onClick={() => handleInvervalSelection("W")}
                    className={intervalSelection === "W" ? "option-button selected" : "option-button"}
                  >
                    W
                  </button>
                  <button
                    onClick={() => handleInvervalSelection("M")}
                    className={intervalSelection === "M" ? "option-button selected" : "option-button"}
                  >
                    M
                  </button>
                  <button
                    onClick={() => handleInvervalSelection("Q")}
                    className={intervalSelection === "Q" ? "option-button selected" : "option-button"}
                  >
                    Q
                  </button>
                  <button
                    onClick={() => handleInvervalSelection("Y")}
                    className={intervalSelection === "Y" ? "option-button selected" : "option-button"}
                  >
                    Y
                  </button>
                  <button
                    onClick={() => handleInvervalSelection("ALL")}
                    className={intervalSelection === "ALL" ? "option-button selected" : "option-button"}
                  >
                    ALL
                  </button>
                </>
              ) : (
                <button onClick={toggleSelector} className="interval-toggle">
                  {intervalSelection}
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export { AccountValue };
