import React from 'react';

const Resources = () => {
  return (
    <div>
        <h1>Resources</h1>
    </div>
  )
}

export {Resources}