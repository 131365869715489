import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  IoPieChart,
  IoGrid,
  IoPhonePortrait,
  IoTabletPortrait,
  IoLaptop,
  IoDocumentAttach,
  IoChatbubbles,
} from "react-icons/io5";
import { PiStrategyBold } from "react-icons/pi";
import { TbExternalLink } from "react-icons/tb";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { Loader } from "../global/Loader";

const Pricing = ({ setSelectedPlan, status, handlePurchase, loadingPortal }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isDesktop, setIsDesktop] = useState(true);
  const [isPhone, setIsPhone] = useState(true);

  const [plan, setPlan] = useState("Monthly");

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 800);
  };

  window.addEventListener("resize", updateWidth);

  return (
    <section className="pricing-block">
      <section className="selection-window">
        <sl-radio-group id="pricing-term-selection" value={plan}>
          <sl-radio-button
            onClick={() => {
              if (status === "existing") {
                setSelectedPlan("monthly");
              }
              setPlan("Monthly");
            }}
            value="Monthly"
          >
            Monthly
          </sl-radio-button>
          <sl-radio-button
            onClick={() => {
              if (status === "existing") {
                setSelectedPlan("annual");
              }
              setPlan("Annual");
            }}
            value="Annual"
          >
            Annual
          </sl-radio-button>
        </sl-radio-group>
        <div className="selection-text">
          <div className="support-text">You've Selected</div>
          <div className="selected-tier">{plan} Billing</div>
        </div>

        {plan === "Monthly" ? (
          <div className="pricing-display-month">
            <div className="dollar-figure">{plan === "Monthly" ? "$9" : "$89"}</div>
            <div className="term-text">{plan === "Monthly" ? "per month" : "per year"}</div>
          </div>
        ) : (
          <div className="pricing-display-year">
            <div className="dollar-figure">{plan === "Monthly" ? "$9" : "$89"}</div>
            <div className="term-text">{plan === "Monthly" ? "per month" : "per year"}</div>
          </div>
        )}
        <div className="cta-block">
          <span>Cancel Anytime</span>
          {status === "existing" ? (
            <sl-button
              class="checkout-button"
              onClick={() => {
                handlePurchase();
              }}
            >
              {!loadingPortal ? "Continue to Payment" : <sl-spinner variant="button-primary"></sl-spinner>}{" "}
              {!loadingPortal ? <TbExternalLink style={{ fontSize: "16pt", marginLeft: "5px" }} /> : null}
            </sl-button>
          ) : (
            <sl-button
              onClick={() => {
                location.pathname === "/create-account"
                  ? setSelectedPlan(plan)
                  : navigate("/create-account", { state: { selectedPlan: plan } });
              }}
            >
              Start Free 7-Day Trial
            </sl-button>
          )}
        </div>
      </section>
      <section className="product-features">
        <div className="feature-block">
          <span className="feature-graphic unlimited">Unlimited</span>
          <span className="feature-description">Expenses per Year</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <IoPieChart /> <IoGrid />
          </span>
          <span className="feature-description">Customized Portfolio Tracking</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <PiStrategyBold /> <BsFillBookmarkStarFill className="star-banner" />
          </span>
          <span className="feature-description">Included Tools to Strategize &</span>
          <span className="feature-description">Maximize Your HSA</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic devices">
            <IoPhonePortrait className="phone" />
            <IoTabletPortrait className="tablet" />
            <IoLaptop className="laptop" />
          </span>
          <span className="feature-description">Access on All Your Devices</span>
        </div>
        {/* <div className="feature-block">
          <span className="feature-graphic">
            <IoDocumentAttach />
          </span>
          <span className="feature-description">Data Export to PDF</span>
        </div>
        <div className="feature-block">
          <span className="feature-graphic">
            <IoChatbubbles />
          </span>
          <span className="feature-description">Active Support</span>
        </div> */}
      </section>
    </section>
  );
};

export { Pricing };
