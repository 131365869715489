import React, { useEffect } from "react";
import Shoebox from "../../assets/Shoebox_Filebox.webp";
import TheBlocksDark from "../../assets/landing/TheBlocksDark.svg";
import TheBlocksLight from "../../assets/landing/TheBlocksLight.svg";
import { FaHeart } from "react-icons/fa6";
import { TbCopyright } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const About = () => {
  const navigate = useNavigate();

  let theme;

  const { releaseNotes, getReleaseNotes, colorMode } = useAuth();

  useEffect(() => {
    if (!releaseNotes || releaseNotes.length < 1) {
      getReleaseNotes();
    }
  }, [releaseNotes]);

  useEffect(() => {
    theme = document.documentElement.getAttribute("data-theme");
  }, [theme]);

  let year = new Date().getFullYear();

  return (
    <section className="about-page">
      <h1>About</h1>
      <div className="about-page-content">
        <img src={Shoebox} />
        <h2>Shoebox</h2>
        <sl-badge
          class="shoebox-version-badge"
          variant="neutral"
          onClick={() => (releaseNotes ? navigate("/about/release-notes") : null)}
        >
          {releaseNotes ? (
            releaseNotes[0].versionNumber
          ) : (
            <sl-spinner style={{ margin: "0px 14.5px" }} variant="badge-neutral"></sl-spinner>
          )}
        </sl-badge>
        <div className="made-by-line">
          Made with <FaHeart /> by <img src={colorMode === "dark" ? TheBlocksLight : TheBlocksDark} />
        </div>
        <div className="blocks-copyright">
          <span>
            The Blocks Lab LLC <TbCopyright /> {year}.
          </span>
          <span>All Rights Reserved.</span>
        </div>
      </div>
    </section>
  );
};

export { About };
