export const calculateWeightedPercentChange = (method, datapoint) => {
  if (method === "time-weighted") {
    return datapoint.percentChange;
  } else {
    let decimalMultipe;
    if (datapoint.percentChange < 0) {
      decimalMultipe = 1 - Math.abs(datapoint.percentChange / 100);
    }
    if (datapoint.percentChange > 0) {
      decimalMultipe = 1 + datapoint.percentChange / 100;
    }
    if (datapoint.percentChange === 0) {
      decimalMultipe = 1;
    }

    const preAdditionsTotal =
      datapoint.accountValue - datapoint.contributions - datapoint.withdrawals - datapoint.adjustments;

    const originalValue = preAdditionsTotal / decimalMultipe;
    let moneyWeightedChangeInValue;

    if (originalValue > datapoint.accountValue) {
      moneyWeightedChangeInValue = (originalValue - datapoint.accountValue) / originalValue;
    } else {
      moneyWeightedChangeInValue = (datapoint.accountValue - originalValue) / originalValue;
    }

    const moneyWeightedPercentChange = moneyWeightedChangeInValue * 100;
    if (originalValue > datapoint.accountValue) {
      return -moneyWeightedPercentChange.toFixed(2);
    } else {
      return moneyWeightedPercentChange.toFixed(2);
    }
  }
};
