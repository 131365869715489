import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { BsPersonFillGear, BsFillChatHeartFill } from "react-icons/bs";
import { MdSell, MdSupport } from "react-icons/md";
import { BiSolidUserPin } from "react-icons/bi";
import { IoInformationCircle } from "react-icons/io5";
import * as TbIcon from "react-icons/tb";
import { HiBanknotes } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { ProfilePlaceholder } from "../../hooks-n-utils/profile-placeholder";

const Profile = () => {
  const {
    signOut,
    activeUserProfile,
    setActiveUser,
    currentUser,
    updateColorPreference,
    colorMode,
    updateWeightingPreference,
    weightingMethod,
  } = useAuth();

  const navigate = useNavigate();
  let colorModeToggle;

  // Removing default desktop 'true' value to remedy desktop button flicker on mobile
  const [isDesktop, setIsDesktop] = useState();
  const [isCompact, setIsCompact] = useState();

  let colors = ["#FF993C", "#2FB500", "#464353", "#DFC6FF", "#0085FF", "#B20000", "#FFC832", "#8D8D8D"];
  let icons = [
    "TbBandageFilled",
    "TbDiamondFilled",
    "TbEggFilled",
    "TbFlagFilled",
    "TbGhostFilled",
    "TbPuzzleFilled",
    "TbUmbrellaFilled",
    "TbRosetteFilled",
  ];

  useEffect(() => {
    // Load Canny SDK
    if (!document.getElementById("canny-jssdk")) {
      const script = document.createElement("script");
      script.id = "canny-jssdk";
      script.src = "https://canny.io/sdk.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (window.Canny && activeUserProfile) {
          window.Canny("identify", {
            appID: process.env.REACT_APP_CANNY_APP_ID,
            user: {
              email: activeUserProfile.email,
              name: `${activeUserProfile.firstName} ${activeUserProfile.lastName.slice(0, 1)}.`,
              id: activeUserProfile.uid,
              avatarURL: activeUserProfile.picture ? activeUserProfile.picture : "",
            },
          });
        }
      };
    } else if (window.Canny && activeUserProfile) {
      window.Canny("identify", {
        appID: process.env.REACT_APP_CANNY_APP_ID,
        user: {
          email: activeUserProfile.email,
          name: `${activeUserProfile.firstName} ${activeUserProfile.lastName.slice(0, 1)}.`,
          id: activeUserProfile.uid,
          avatarURL: activeUserProfile.picture ? activeUserProfile.picture : "",
        },
      });
    }
  }, [activeUserProfile]);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 500);
    setIsCompact(window.innerWidth < 425);
  };

  useEffect(() => {
    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const Icon = ({ iconName }) => {
    let icon;

    if (iconName.slice(0, 2) === "Tb") {
      icon = React.createElement(TbIcon[iconName]);
    }

    return <div className="icon-wrapper">{icon}</div>;
  };

  const signOutUser = () => {
    signOut();
  };

  return (
    <div className="profile-dashboard">
      <div className="title">
        <h1>Profile</h1>
        {isDesktop ? (
          <sl-button id="desktop-sign-out" onClick={signOutUser}>
            Sign Out
          </sl-button>
        ) : null}
      </div>

      <section className="profile-header">
        <div>
          <h2>Hi, {activeUserProfile?.firstName}!</h2>
          <h3>Shoeboxing since {activeUserProfile?.created.slice(0, 4)}</h3>
        </div>
        <div>
          {activeUserProfile ? (
            activeUserProfile?.picture ? (
              <img src={activeUserProfile.picture} />
            ) : (
              <ProfilePlaceholder
                colorIndex={activeUserProfile?.defaultAvatar[0]}
                iconIndex={activeUserProfile?.defaultAvatar[1]}
              />
            )
          ) : (
            <sl-skeleton effect="sheen" class="profile-picture-loader"></sl-skeleton>
          )}
        </div>
      </section>

      <section className="profile-body">
        <div className="main-settings">
          <section className="general">
            <small>General Information</small>
            <div className="button-row">
              <sl-button id="blocks-icon-button" onClick={() => navigate("/profile/personal-info")} variant="neutral">
                <BsPersonFillGear />
                <span>Personal Info</span>
              </sl-button>
              <sl-button id="blocks-icon-button" onClick={() => navigate("/profile/edit-picture")} variant="neutral">
                <BiSolidUserPin />
                <span>Profile Picture</span>
              </sl-button>
            </div>
          </section>
          <section className="account">
            <small>Account Controls</small>
            <div className="button-row">
              <sl-button id="blocks-icon-button" onClick={() => navigate("/profile/security")} variant="neutral">
                <TbIcon.TbLockFilled />
                <span>{isCompact ? "Acct Security" : "Account Security"}</span>
              </sl-button>
              <sl-button id="blocks-icon-button" onClick={() => navigate("/profile/subscription")} variant="neutral">
                <MdSell />
                <span>Subscription</span>
              </sl-button>
            </div>
          </section>
        </div>
        <div className="additional-options">
          <section className="preferences">
            <small>Preferences</small>
            <sl-radio-group
              class="account-preferences-button-groups"
              id="color-mode-toggle"
              label="Color Mode"
              value={colorMode}
            >
              <sl-radio-button
                class="preference-button"
                onClick={() => updateColorPreference(currentUser.uid, "light")}
                value="light"
              >
                Light
              </sl-radio-button>
              <sl-radio-button
                class="preference-button"
                onClick={() => updateColorPreference(currentUser.uid, "dark")}
                value="dark"
              >
                Dark
              </sl-radio-button>
              <sl-radio-button
                class="preference-button"
                onClick={() => updateColorPreference(currentUser.uid, "system")}
                value="system"
              >
                System
              </sl-radio-button>
            </sl-radio-group>
            <sl-radio-group value={weightingMethod} class="account-preferences-button-groups">
              <span slot="label" className="weighting-method-control-label">
                Portfolio Weight of Return Method{" "}
                <TbIcon.TbInfoCircleFilled onClick={() => document.getElementById("weighting-help-popup")?.show()} />
              </span>
              <sl-radio-button
                class="preference-button"
                onClick={() => updateWeightingPreference(currentUser.uid, "time-weighted")}
                value="time-weighted"
              >
                Time
              </sl-radio-button>
              <sl-radio-button
                class="preference-button"
                onClick={() => updateWeightingPreference(currentUser.uid, "money-weighted")}
                value="money-weighted"
              >
                Money
              </sl-radio-button>
            </sl-radio-group>
          </section>
          <sl-dialog label="Weight of Return Methods" id="weighting-help-popup">
            <div>
              <h4>
                <TbIcon.TbClockHour5Filled />
                Time-Weighted Return (TWR)
              </h4>
              <p>
                Time-weighted returns (TWR's) are the most common calculation method for investment returns and are
                calculated by displaying growth or decline <span>after</span> deducting any contributions or
                withdrawals. This allows for a more accurate picture of how your portfolio is growing based on your
                investments.
              </p>
              <p>
                In the event that you need to withdraw any money for a medical expense or have a large deposit, your
                account growth for that period will not be skewed. It will remain level and in-line with your investment
                performance.
              </p>
              <h4>
                <HiBanknotes className="dollar-icon" />
                Money-Weighted Return (MWR)
              </h4>
              <p>
                Money-weighted return (MWR, also known as dollar-weighted (DWR) or internal rate of return (IRR)){" "}
                <span>does</span> factor in any contributions into your percent growth or decline.
              </p>
              <p>
                Given that accounts using the shoeboxing method are designed to stay intact until retirement with
                minimal withdrawals, people may decide that this method of calculation provides a more accurate
                representation of your total account growth — that's why we give you the option!
              </p>
            </div>
          </sl-dialog>
          <section className="support">
            <small>App Information & Support</small>
            {isCompact ? (
              <>
                <div className="button-row">
                  <sl-button id="blocks-icon-button" onClick={() => navigate("/support")} variant="neutral">
                    <MdSupport />
                    <span>Support</span>
                  </sl-button>
                  <sl-button id="blocks-icon-button" onClick={() => navigate("/about")} variant="neutral">
                    <IoInformationCircle />
                    <span>About</span>
                  </sl-button>
                </div>
                <div className="single-item">
                  <a data-canny-link href="https://shoebox.canny.io/feedback" target="_blank">
                    <sl-button id="blocks-icon-button" variant="neutral">
                      <BsFillChatHeartFill />
                      <span className="external-link">
                        Feedback <TbIcon.TbExternalLink />
                      </span>
                    </sl-button>
                  </a>
                </div>
              </>
            ) : (
              <div className="tri-button-row">
                <sl-button id="blocks-icon-button" onClick={() => navigate("/support")} variant="neutral">
                  <MdSupport />
                  <span>Support</span>
                </sl-button>
                <a data-canny-link href="https://shoebox.canny.io/feedback" target="_blank">
                  <sl-button id="blocks-icon-button" variant="neutral">
                    <BsFillChatHeartFill />
                    <span className="external-link">
                      Feedback <TbIcon.TbExternalLink />
                    </span>
                  </sl-button>
                </a>
                <sl-button id="blocks-icon-button" onClick={() => navigate("/about")} variant="neutral">
                  <IoInformationCircle />
                  <span>About</span>
                </sl-button>
              </div>
            )}
          </section>
        </div>
      </section>

      {isDesktop === false && (
        <sl-button class="sign-out-mobile" onClick={signOutUser}>
          Sign Out
        </sl-button>
      )}
    </div>
  );
};

export { Profile };
