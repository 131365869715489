import React, { useEffect, useState } from "react";
import urlBuilder from "@sanity/image-url";
import { getImageDimensions } from "@sanity/asset-utils";
import { PortableText } from "@portabletext/react";
import * as TbIcon from "react-icons/tb";
import * as IoIcon from "react-icons/io5";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { shortDateHandler } from "../../hooks-n-utils/date";

const HelpDocs = () => {
  const { sanityClient, helpCategories, getHelpCategories, helpDocuments, getHelpDocuments } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [pageCategory, setPageCategory] = useState();

  const SampleImageComponent = ({ value }) => {
    const builder = urlBuilder(sanityClient);
    const { width, height } = getImageDimensions(value);
    return (
      <img
        src={builder.image(value).width(800).fit("max").auto("format").url()}
        alt={value.alt || " "}
        loading="lazy"
        style={{
          // Avoid jumping around with aspect-ratio CSS property
          aspectRatio: width / height,
        }}
      />
    );
  };

  const renderIcon = ({ library, icon }) => {
    // Choose the library based on the "library" value
    const IconLibrary = library === "Tb" ? TbIcon : library === "Io5" ? IoIcon : null;

    if (!IconLibrary || !IconLibrary[icon]) {
      console.warn("Invalid library or icon name");
      return null;
    }

    // Render the icon component dynamically
    const IconComponent = IconLibrary[icon];
    return <IconComponent className="category-icon" />;
  };

  const renderTitle = (pathname) => {
    helpCategories?.map((category) => {
      if (category.name === pathname) {
        return category.title;
      }
    });
  };

  useEffect(() => {
    if (!helpCategories) {
      getHelpCategories();
    }
    if (!helpDocuments) {
      getHelpDocuments();
    }
    if (helpCategories) {
      helpCategories?.map((category) => {
        if (category.name === location.pathname.slice(9)) {
          setPageCategory(category);
        }
      });
    }
  }, [helpCategories, helpDocuments]);

  return (
    <div>
      <div className="help-category-header">
        <h1>Support: {pageCategory && pageCategory.title}</h1>
        <sl-button pill variant="neutral" class="blocks-pill-btn" onClick={() => navigate("/support")}>
          <TbIcon.TbArrowBack /> Back To All Resources
        </sl-button>
      </div>
      {helpDocuments?.map((doc) => {
        return (
          <div className="individual-document" key={doc._id}>
            <h2 className="doc-header">{doc.title}</h2>
            <span className="updated-chip">
              Last Updated: <sl-badge variant="neutral">{shortDateHandler(doc.lastUpdated)}</sl-badge>
            </span>
            <PortableText
              className="help-doc-body"
              components={{
                types: {
                  image: SampleImageComponent,
                },
              }}
              value={doc.documentBody}
            />
          </div>
        );
      })}
    </div>
  );
};

export { HelpDocs };
