import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { validateEmail } from "../../hooks-n-utils/email-validation";
import { notify } from "../../hooks-n-utils/toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const { getPasswordResetLink } = useAuth();

  useEffect(() => {
    if (!validateEmail(email) || email === "" || emailError) {
      document.getElementById("pass-reset-submit-btn")?.setAttribute("disabled", true);
    } else {
      document.getElementById("pass-reset-submit-btn")?.removeAttribute("disabled");
    }
  }, [email, emailError]);

  const checkEmail = () => {
    if (validateEmail(email) || email === "") {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address");
    }
  };

  const submitEmail = (email) => {
    getPasswordResetLink(email);
    setEmail("");
    setEmailError("");
  };

  return (
    <div className="reset-password-page">
      <h1>Reset Password</h1>
      <div className="support-text">Please enter your email to recieve a password reset link</div>
      <div className={emailError ? "email-error-wrapper shown" : "email-error-wrapper"}>
        <span className="email-form-error">{emailError}</span>
      </div>
      <div className="input-wrapper">
        <span className="input-block">
          <label>Email Address</label>
          <input
            type="email"
            autoComplete="on"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => checkEmail()}
          ></input>
        </span>

        <sl-button
          id="pass-reset-submit-btn"
          onClick={() => {
            validateEmail(email);
            if (!emailError) {
              submitEmail(email);
            }
          }}
        >
          Submit
        </sl-button>
      </div>
    </div>
  );
};

export { ForgotPassword };
