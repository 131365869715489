import React from 'react'

const ErrorHandler = (errorCode) => {
    console.error(errorCode);
  switch (errorCode) {
    case 'auth/user-not-found':
        return 'There is no account associated with this email address.';
        break;
    case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
        break;
    case 'auth/email-already-in-use':
        return 'An account has already been created with this email. Please try another.';
        break;
    case 'auth/too-many-requests':
        return 'Access to this account has been disabled due to too many attempts. Please try again later.';
        break;
    case 'auth/invalid-email':
        return 'Invalid email. Please use a proper email address: ex. example@mitchkitch.com';
        break;
    case 'auth/missing-email':
        return 'No email entered. Please enter a valid user email.';
        break;
    default:
        return 'An error has occurred';
        break;
  }
}

export { ErrorHandler };