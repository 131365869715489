import React, { useEffect, useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useAuth } from "../../contexts/AuthContext";
import { notify } from "../../hooks-n-utils/toast";
import { FaPerson } from "react-icons/fa6";
import { TbRotate2 } from "react-icons/tb";
import { HiOfficeBuilding } from "react-icons/hi";
import {
  currencyDeformatter,
  currencyFormatter,
  currencyHandler,
  currencyInputHandling,
} from "../../hooks-n-utils/currency";
import { generateUID } from "../../hooks-n-utils/uid-generator";
import { dateHandler, parseDateToLocal } from "../../hooks-n-utils/date";
import { useLocation } from "react-router-dom";
import { ButtonLoader } from "../global/Loader";

const ContributionForm = () => {
  const {
    currentUser,
    annualData,
    contributionSchedule,
    recordContributionSchedule,
    recordOneTimeContribution,
    contributions,
  } = useAuth();

  const location = useLocation();
  const state = location.state;

  const [editing, setEditing] = useState(false);
  const [createSchedule, setCreateSchedule] = useState(false);
  const [createOneTime, setCreateOneTime] = useState(false);

  const [loadedSetSchedule, setLoadedSetSchedule] = useState(false);
  const [saveError, setSaveError] = useState("");

  const [maxDate, setMaxDate] = useState("");
  const [minOtDate, setMinOtDate] = useState("");
  const [minScheduleDate, setMinScheduleDate] = useState("");

  // Schedule Data - Dual Source, i: individual & e: employer
  const [hasIndividual, setHasIndividual] = useState(false);
  const [hasEmployer, setHasEmployer] = useState(false);
  const [iTitle, setITitle] = useState("Monthly Individual Contribution");
  const [iCadence, setICadence] = useState("monthly");
  const [eTitle, setETitle] = useState("Monthly Employer Contribution");
  const [eCadence, setECadence] = useState("monthly");
  const [iStartDate, setIStartDate] = useState("");
  const [iEndDate, setIEndDate] = useState("");
  const [iDateError, setIDateError] = useState("");
  const [eStartDate, setEStartDate] = useState("");
  const [eEndDate, setEEndDate] = useState("");
  const [hasEEndDate, setHasEEndDate] = useState(false);
  const [hasIEndDate, setHasIEndDate] = useState(false);
  const [eDateError, setEDateError] = useState("");
  const [noFormChange, setNoFormChange] = useState(true);

  // One-Time Data
  const [otTitle, setOtTitle] = useState("");
  const [otSource, setOtSource] = useState(true);
  const [otDate, setOtDate] = useState("");
  const [otDateError, setOtDateError] = useState("");

  // Multi-State Amount
  const [amounts, setAmounts] = useState({
    iAmount: "",
    eAmount: "",
    otAmount: "",
  });

  // Errors
  const [amountErrors, setAmountErrors] = useState({
    iAmountError: "",
    eAmountError: "",
    otAmountError: "",
  });
  const [contributionDateError, setContributionDateError] = useState("");

  const [contributionLimit, setContributionLimit] = useState();

  const [previousITitle, setPreviousITitle] = useState("");
  const [previousETitle, setPreviousETitle] = useState("");

  const [nextIndividualDate, setNextIndividualDate] = useState("");
  const [nextEmployerDate, setNextEmployerDate] = useState("");

  let configDrawer;

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  useEffect(() => {
    let currentDate = new Date();
    let expenseMaxDate = new Date();
    expenseMaxDate.setFullYear(expenseMaxDate.getFullYear() + 1);
    setMaxDate(expenseMaxDate.toJSON().slice(0, 10));
    let expenseMinOtDate = new Date(2004, 0, 1);
    setMinOtDate(expenseMinOtDate.toJSON().slice(0, 10));
    setMinScheduleDate(currentDate.toJSON().slice(0, 10));

    if (annualData) {
      let currentYear = new Date().getFullYear().toString();
      const currentYearData = annualData?.find((doc) => doc.year === currentYear);
      if (currentYearData) {
        setContributionLimit(currentYearData.individualContributionLimit);
      }
    }

    if (
      contributionSchedule &&
      (contributionSchedule?.activeSchedule || !contributionSchedule?.activeSchedule) &&
      !loadedSetSchedule
    ) {
      mapScheduleToForm(contributionSchedule.scheduleDetail);
      setLoadedSetSchedule(true);
    }

    configDrawer = document.getElementById("configuration-popup");

    if (iTitle !== "" && iTitle === previousITitle) {
      setITitle(`${capitalizeHyphenated(iCadence)} Individual Contribution`);
    }
    if (eTitle !== "" && eTitle === previousETitle) {
      setETitle(`${capitalizeHyphenated(eCadence)} Employer Contribution`);
    }

    setPreviousITitle(`${capitalizeHyphenated(iCadence)} Individual Contribution`);
    setPreviousETitle(`${capitalizeHyphenated(eCadence)} Employer Contribution`);

    window.addEventListener("sl-after-hide", (e) => {
      if (e.target.id === "configuration-popup") {
        resetForm();
        setEditing(false);
        setSaveError("");
        setCreateSchedule(false);
        setCreateOneTime(false);
      }
    });

    return () => {
      window.removeEventListener("sl-after-hide", resetForm);
    };
  }, [iCadence, eCadence, configDrawer, contributionSchedule, currentUser, annualData]);

  useEffect(() => {
    if (state) {
      setEditing(state.editing);
      setCreateOneTime(state.createOneTime);
      setCreateSchedule(state.createSchedule);
    }
  }, [state]);

  useEffect(() => {
    // sets session value to stop all tile chart animations after initial user session load
    if (!afterFirstVisit) {
      setTimeout(() => {
        window.sessionStorage.setItem("after-first-session-portfolio-visit", true);
      }, 3000);
    }
  }, [afterFirstVisit]);

  useEffect(() => {
    // Clear Existing Errors for Fresh Check
    setIDateError("");
    if (iStartDate && iEndDate) {
      if (parseDateToLocal(iStartDate) > parseDateToLocal(iEndDate)) {
        setIDateError("End date must come after start date.");
      }
    }
    if (iStartDate) {
      if (parseDateToLocal(iStartDate) > parseDateToLocal(maxDate)) {
        setIDateError("Start date must begin within 12 months.");
      }
      if (parseDateToLocal(iStartDate) < parseDateToLocal(minScheduleDate)) {
        setIDateError("Start date cannot be in the past.");
      }
    }

    // End Date Errors
    // Clear Existing Errors for Fresh Check
    setEDateError("");
    if (eStartDate && eEndDate) {
      if (parseDateToLocal(eStartDate) > parseDateToLocal(eEndDate)) {
        setEDateError("End date must come after start date.");
      }
    }
    if (eStartDate) {
      if (parseDateToLocal(eStartDate) > parseDateToLocal(maxDate)) {
        setEDateError("Start date must begin within 12 months.");
      }
      if (parseDateToLocal(eStartDate) < parseDateToLocal(minScheduleDate)) {
        setEDateError("Start date cannot be in the past.");
      }
    }
  }, [iStartDate, iEndDate, eStartDate, eEndDate]);

  useEffect(() => {
    if (contributions && contributionSchedule?.activeSchedule) {
      if (contributionSchedule?.scheduleDetail?.hasEmployerContributionSchedule) {
        getNextDate("e");
      }
      if (contributionSchedule?.scheduleDetail?.hasIndividualContributionSchedule) {
        getNextDate("i");
      }
    }
  }, [contributions, contributionSchedule]);

  useEffect(() => {
    if (contributionSchedule) {
      let savedIndividualSchedule = contributionSchedule.scheduleDetail.individualContributionSchedule;
      let savedEmployerSchedule = contributionSchedule.scheduleDetail.employerContributionSchedule;

      const isIndividualSame =
        hasIndividual &&
        amounts.iAmount == savedIndividualSchedule.amount &&
        iCadence === savedIndividualSchedule.cadence &&
        iEndDate === savedIndividualSchedule.endDate &&
        hasIEndDate === savedIndividualSchedule.hasEndDate &&
        iStartDate === savedIndividualSchedule.startDate &&
        iTitle === savedIndividualSchedule.title &&
        hasEmployer === contributionSchedule.scheduleDetail.hasEmployerContributionSchedule;

      const isEmployerSame =
        hasEmployer &&
        amounts.eAmount == savedEmployerSchedule.amount &&
        eCadence === savedEmployerSchedule.cadence &&
        eEndDate === savedEmployerSchedule.endDate &&
        hasEEndDate === savedEmployerSchedule.hasEndDate &&
        eStartDate === savedEmployerSchedule.startDate &&
        eTitle === savedEmployerSchedule.title &&
        hasIndividual === contributionSchedule.scheduleDetail.hasIndividualContributionSchedule;

      const bothSame = isIndividualSame && isEmployerSame;

      const noScheduleChange =
        !hasIndividual &&
        !hasEmployer &&
        hasIndividual === contributionSchedule.scheduleDetail.hasIndividualContributionSchedule &&
        hasEmployer === contributionSchedule.scheduleDetail.hasEmployerContributionSchedule;

      setNoFormChange(Boolean(isIndividualSame || isEmployerSame || bothSame || noScheduleChange));
    } else {
      setNoFormChange(false);
    }
  }, [
    amounts,
    iCadence,
    iEndDate,
    hasIEndDate,
    iStartDate,
    iTitle,
    eCadence,
    eEndDate,
    hasEEndDate,
    eStartDate,
    eTitle,
    contributionSchedule,
  ]);

  const resetForm = () => {
    setHasIndividual(false);
    setHasEmployer(false);
    setITitle("Monthly Individual Contribution");
    setICadence("monthly");
    setETitle("Monthly Employer Contribution");
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [`iAmount`]: "",
      [`eAmount`]: "",
    }));
    setAmountErrors((prevErrors) => ({
      ...prevErrors,
      ["iAmountError"]: "",
      ["eAmountError"]: "",
    }));
    setECadence("monthly");
    setIStartDate("");
    setEStartDate("");
    setIEndDate("");
    setEEndDate("");
    setHasIEndDate(false);
    setHasEEndDate(false);
  };

  const resetOneTime = () => {
    setOtTitle("");
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [`otAmount`]: "",
    }));
    setAmountErrors((prevErrors) => ({
      ...prevErrors,
      ["otAmountError"]: "",
    }));
    setOtSource(true);
    setOtDate("");
  };

  const resetIndividual = () => {
    if (contributionSchedule.scheduleDetail.hasIndividualContributionSchedule) {
      setITitle(contributionSchedule.scheduleDetail.individualContributionSchedule.title);
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`iAmount`]: contributionSchedule.scheduleDetail.individualContributionSchedule.amount,
      }));
      setICadence(contributionSchedule.scheduleDetail.individualContributionSchedule.cadence);
      setIStartDate(contributionSchedule.scheduleDetail.individualContributionSchedule.startDate);
      setHasIEndDate(contributionSchedule.scheduleDetail.individualContributionSchedule.hasEndDate);
      setIEndDate(contributionSchedule.scheduleDetail.individualContributionSchedule.endDate);
    } else {
      setITitle("Monthly Individual Contribution");
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`iAmount`]: "",
      }));
      setAmountErrors((prevErrors) => ({
        ...prevErrors,
        ["iAmountError"]: "",
      }));
      setICadence("monthly");
      setIStartDate("");
      setIEndDate("");
      setHasIEndDate(false);
      setIDateError("");
    }
  };

  const resetEmployer = () => {
    if (contributionSchedule.scheduleDetail.hasEmployerContributionSchedule) {
      setETitle(contributionSchedule.scheduleDetail.employerContributionSchedule.title);
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`eAmount`]: contributionSchedule.scheduleDetail.employerContributionSchedule.amount,
      }));
      setECadence(contributionSchedule.scheduleDetail.employerContributionSchedule.cadence);
      setEStartDate(contributionSchedule.scheduleDetail.employerContributionSchedule.startDate);
      setHasEEndDate(contributionSchedule.scheduleDetail.employerContributionSchedule.hasEndDate);
      setEEndDate(contributionSchedule.scheduleDetail.employerContributionSchedule.endDate);
    } else {
      setETitle("Monthly Employer Contribution");
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`eAmount`]: "",
      }));
      setAmountErrors((prevErrors) => ({
        ...prevErrors,
        ["eAmountError"]: "",
      }));
      setECadence("monthly");
      setEStartDate("");
      setEEndDate("");
      setHasEEndDate(false);
      setEDateError("");
    }
  };

  const mapScheduleToForm = () => {
    setHasIndividual(contributionSchedule.scheduleDetail.hasIndividualContributionSchedule);
    setHasEmployer(contributionSchedule.scheduleDetail.hasEmployerContributionSchedule);

    if (contributionSchedule.scheduleDetail.hasIndividualContributionSchedule) {
      setITitle(contributionSchedule.scheduleDetail.individualContributionSchedule.title);
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`iAmount`]: currencyFormatter(contributionSchedule.scheduleDetail.individualContributionSchedule.amount),
      }));
      setICadence(contributionSchedule.scheduleDetail.individualContributionSchedule.cadence);
      setIStartDate(contributionSchedule.scheduleDetail.individualContributionSchedule.startDate);
      setHasIEndDate(contributionSchedule.scheduleDetail.individualContributionSchedule.hasEndDate);
      setIEndDate(contributionSchedule.scheduleDetail.individualContributionSchedule.endDate);
    }

    if (contributionSchedule.scheduleDetail.hasEmployerContributionSchedule) {
      setETitle(contributionSchedule.scheduleDetail.employerContributionSchedule.title);
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`eAmount`]: currencyFormatter(contributionSchedule.scheduleDetail.employerContributionSchedule.amount),
      }));
      setECadence(contributionSchedule.scheduleDetail.employerContributionSchedule.cadence);
      setEStartDate(contributionSchedule.scheduleDetail.employerContributionSchedule.startDate);
      setHasEEndDate(contributionSchedule.scheduleDetail.employerContributionSchedule.hasEndDate);
      setEEndDate(contributionSchedule.scheduleDetail.employerContributionSchedule.endDate);
    }
  };

  const closeEdit = () => {
    setEditing(false);
    setSaveError("");
    setCreateSchedule(false);
    setCreateOneTime(false);
    resetOneTime();
    if (contributionSchedule?.activeSchedule) {
      mapScheduleToForm();
    } else {
      resetForm();
    }
  };

  const makeSchedule = () => {
    if (contributionSchedule?.activeSchedule) {
      mapScheduleToForm();
    } else {
      resetForm();
    }
    setEditing(true);
    setSaveError("");
    setCreateOneTime(false);
    setCreateSchedule(true);
  };

  const makeOneTime = () => {
    setEditing(true);
    setSaveError("");
    setCreateSchedule(false);
    setCreateOneTime(true);
  };

  const saveSchedule = () => {
    let individualSched = hasIndividual
      ? {
          title: iTitle,
          amount: currencyDeformatter(amounts.iAmount),
          cadence: iCadence,
          hasEndDate: hasIEndDate,
          startDate: iStartDate,
          endDate: iEndDate ? iEndDate : "",
        }
      : {};
    let employerSched = hasEmployer
      ? {
          title: eTitle,
          amount: currencyDeformatter(amounts.eAmount),
          cadence: eCadence,
          hasEndDate: hasEEndDate,
          startDate: eStartDate,
          endDate: eEndDate ? eEndDate : "",
        }
      : {};

    let payload = {
      activeSchedule: hasIndividual || hasEmployer,
      scheduleDetail: {
        hasIndividualContributionSchedule: hasIndividual,
        individualContributionSchedule: individualSched,
        hasEmployerContributionSchedule: hasEmployer,
        employerContributionSchedule: employerSched,
      },
    };
    recordContributionSchedule(payload, currentUser.uid)
      .then((schedule) => {
        closeEdit();
        notify("Your contribution schedule has been set", "success");
        mapScheduleToForm(schedule);
      })
      .catch((err) => {
        console.error(err);
        notify(err, "danger");
      });
  };

  const saveOneTime = () => {
    const inputDate = otDate;

    // Split the string into year, month, and day
    const [year, month, day] = inputDate.split("-").map(Number);

    // Create a Date object, note that the month is zero-indexed (January is 0)
    const inputDateOnly = new Date(year, month - 1, day);

    // Get today's date (with time set to 00:00:00)
    const today = new Date();
    const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // Generate unique ID for new contribution
    const id = generateUID("contribution", today.getFullYear());

    let payload = {
      id: id,
      date: otDate,
      isRecurring: false,
      lastEdit: null,
      status: inputDateOnly > todayDateOnly ? "Upcoming" : "Completed",
      title: otTitle,
      amount: currencyDeformatter(amounts.otAmount),
      type: otSource ? "Employer" : "Individual",
    };

    recordOneTimeContribution(payload, currentUser.uid)
      .then(() => {
        closeEdit();
      })
      .catch((err) => {
        console.error(err);
        setSaveError("There was an issue trying to save your contribution. Please try again later.");
      });
  };

  const validateAmount = (amount, source, event) => {
    let value;
    if (event === "blur") {
      value = currencyInputHandling(amount, "blur");
    } else {
      value = currencyInputHandling(amount);
    }

    let errorKey = `${source}AmountError`; // e.g., iAmountError, eAmountError, otAmountError

    if (currencyDeformatter(value) < 0.01) {
      setAmountErrors((prevErrors) => ({
        ...prevErrors,
        [errorKey]: "Oops! Please set a value greater than $0.00.",
      }));
    } else if (currencyDeformatter(value) > contributionLimit) {
      setAmountErrors((prevErrors) => ({
        ...prevErrors,
        [errorKey]: `Hmm... This year's contribution limit is $${currencyFormatter(
          contributionLimit
        )}. Double check you've entered the right amount!`,
      }));
    } else {
      setAmountErrors((prevErrors) => ({
        ...prevErrors,
        [errorKey]: "", // Clear the error if valid
      }));
    }

    // Update the correct amount field based on the 'source'
    if (event === "blur") {
      if (amount !== "") {
        setAmounts((prevAmounts) => ({
          ...prevAmounts,
          [`${source}Amount`]: value, // Update either iAmount, eAmount, or otAmount
        }));
      }
    } else {
      setAmounts((prevAmounts) => ({
        ...prevAmounts,
        [`${source}Amount`]: value, // Update either iAmount, eAmount, or otAmount
      }));
    }
  };

  const validateDate = (date) => {
    if (date > maxDate) {
      setContributionDateError(
        "Oops! You've set a date too far the future, only expenses within the next 12 months can be added."
      );
    } else if (date < minOtDate) {
      setContributionDateError("Oops! You can only add expenses up to 1/1/2004 and forward.");
    } else {
      setContributionDateError("");
    }
    setOtDate(date);
  };

  const getNextDate = (type) => {
    if (
      type === "i" &&
      contributions &&
      contributions.length > 0 &&
      contributionSchedule?.scheduleDetail?.hasIndividualContributionSchedule
    ) {
      const filtered = contributions.filter(
        (contribution) =>
          contribution.status === "Upcoming" && contribution.isRecurring && contribution.type === "Individual"
      );
      if (filtered.length > 0) {
        setNextIndividualDate(dateHandler(filtered[0].date));
      }
    }
    if (
      type === "e" &&
      contributions &&
      contributions.length > 0 &&
      contributionSchedule?.scheduleDetail?.hasEmployerContributionSchedule
    ) {
      const filtered = contributions.filter(
        (contribution) =>
          contribution.status === "Upcoming" && contribution.isRecurring && contribution.type === "Employer"
      );
      if (filtered.length > 0) {
        setNextEmployerDate(dateHandler(filtered[0].date));
      }
    }
  };

  const capitalizeHyphenated = (str) =>
    str
      .replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1))
      .replace(/-\w/g, (match) => match.toUpperCase());

  return (
    <section className="schedule-panel">
      {editing || contributions?.length < 1 ? (
        editing ? (
          createSchedule ? (
            <div className="schedule-editor-wrapper">
              <div className="editor-panel">
                <span className="schedule-panel-header">
                  <h2>Contribution Schedule</h2>
                  <span className="cancel-edit-btn">
                    <AiFillPlusCircle onClick={() => closeEdit()} />
                  </span>
                </span>
                <div className="individual-header">
                  <h3>
                    <FaPerson /> Individual
                  </h3>
                  <small>Recurring Individual Contributions?</small>
                  <sl-radio-group class="has-individual-schedule-toggle" value={hasIndividual}>
                    <sl-radio-button value={true} onClick={() => setHasIndividual(true)}>
                      Yes
                    </sl-radio-button>
                    <sl-radio-button
                      value={false}
                      onClick={() => {
                        setHasIndividual(false);
                        resetIndividual();
                      }}
                    >
                      No
                    </sl-radio-button>
                  </sl-radio-group>
                </div>
                <div className={hasIndividual ? "individual-form-wrapper expanded" : "individual-form-wrapper"}>
                  <div className="schedule-controls">
                    <span className="contribution-title-section">
                      <small>Title</small>

                      <input
                        onChange={(e) => setITitle(e.target.value)}
                        onBlur={(e) =>
                          e.target.value === "" &&
                          setITitle(`${capitalizeHyphenated(iCadence)} Individual Contribution`)
                        }
                        value={iTitle}
                        maxLength="85"
                      ></input>
                    </span>
                    <small>Amount</small>
                    <div className={amountErrors.iAmountError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                      <span className="amount-error-text">{amountErrors.iAmountError ?? null}</span>
                    </div>
                    <span className="schedule-amount-selectors">
                      <span className="blocks-currency-input">
                        <span>$</span>
                        <input
                          onClick={(e) => e.target.select()}
                          onChange={(e) => validateAmount(e.target.value, "i")}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              validateAmount(e.target.value, "i", "blur");
                            }
                          }}
                          value={amounts.iAmount}
                        ></input>
                      </span>
                      <sl-select value={iCadence} filled>
                        <sl-option onClick={() => setICadence("weekly")} value="weekly">
                          Weekly
                        </sl-option>
                        <sl-option onClick={() => setICadence("bi-weekly")} value="bi-weekly">
                          Bi-Weekly
                        </sl-option>
                        <sl-option onClick={() => setICadence("semi-monthly")} value="semi-monthly">
                          Semi-Monthly
                        </sl-option>
                        <sl-option onClick={() => setICadence("monthly")} value="monthly">
                          Monthly
                        </sl-option>
                        <sl-option onClick={() => setICadence("quarterly")} value="quarterly">
                          Quarterly
                        </sl-option>
                        <sl-option onClick={() => setICadence("annual")} value="annual">
                          Annually
                        </sl-option>
                      </sl-select>
                    </span>
                    <small>Time Frame</small>
                    <span className="end-date-toggle-wrapper">
                      <label className="cont-form-button-group-label">Has End Date</label>
                      <sl-radio-group class="end-date-toggle" value={hasIEndDate}>
                        <sl-radio-button onClick={() => setHasIEndDate(true)} value={true}>
                          Yes
                        </sl-radio-button>
                        <sl-radio-button
                          onClick={() => {
                            setHasIEndDate(false);
                            setIEndDate();
                          }}
                          value={false}
                        >
                          No
                        </sl-radio-button>
                      </sl-radio-group>
                    </span>
                    <div className={iDateError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                      <span className="amount-error-text">{iDateError ?? null}</span>
                    </div>
                    <span class="schedule-date-pickers">
                      <label className="cont-form-button-group-label">Starts</label>
                      <input
                        onChange={(e) => setIStartDate(e.target.value)}
                        min={minScheduleDate}
                        max={maxDate}
                        value={iStartDate}
                        type="date"
                      ></input>
                    </span>
                    {hasIEndDate && (
                      <span class="schedule-date-pickers">
                        <label className="cont-form-button-group-label">Ends</label>
                        <input
                          onChange={(e) => setIEndDate(e.target.value)}
                          min={minScheduleDate}
                          max={maxDate}
                          value={iEndDate}
                          type="date"
                        ></input>
                      </span>
                    )}
                  </div>
                </div>
                <div className="employer-header">
                  <h3 className="employer-h-three">
                    <HiOfficeBuilding /> Employer
                  </h3>
                  <small>Recurring Employer Contributions?</small>
                  <sl-radio-group class="has-employer-schedule-toggle" value={hasEmployer}>
                    <sl-radio-button value={true} onClick={() => setHasEmployer(true)}>
                      Yes
                    </sl-radio-button>
                    <sl-radio-button
                      value={false}
                      onClick={() => {
                        setHasEmployer(false);
                        resetEmployer();
                      }}
                    >
                      No
                    </sl-radio-button>
                  </sl-radio-group>
                </div>
                <div className={hasEmployer ? "employer-form-wrapper expanded" : "employer-form-wrapper"}>
                  <div className="schedule-controls">
                    <span className="contribution-title-section">
                      <small>Title</small>

                      <input
                        onChange={(e) => setETitle(e.target.value)}
                        onBlur={(e) =>
                          e.target.value === "" && setETitle(`${capitalizeHyphenated(eCadence)} Employer Contribution`)
                        }
                        value={eTitle}
                        maxLength="85"
                      ></input>
                    </span>
                    <small>Amount</small>
                    <div className={amountErrors.eAmountError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                      <span className="amount-error-text">{amountErrors.eAmountError ?? null}</span>
                    </div>
                    <span className="schedule-amount-selectors">
                      <span className="blocks-currency-input">
                        <span>$</span>
                        <input
                          onClick={(e) => e.target.select()}
                          onChange={(e) => validateAmount(e.target.value, "e")}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              validateAmount(e.target.value, "e", "blur");
                            }
                          }}
                          value={amounts.eAmount}
                        ></input>
                      </span>
                      <sl-select value={eCadence} filled>
                        <sl-option onClick={() => setECadence("weekly")} value="weekly">
                          Weekly
                        </sl-option>
                        <sl-option onClick={() => setECadence("bi-weekly")} value="bi-weekly">
                          Bi-Weekly
                        </sl-option>
                        <sl-option onClick={() => setECadence("semi-monthly")} value="semi-monthly">
                          Semi-Monthly
                        </sl-option>
                        <sl-option onClick={() => setECadence("monthly")} value="monthly">
                          Monthly
                        </sl-option>
                        <sl-option onClick={() => setECadence("quarterly")} value="quarterly">
                          Quarterly
                        </sl-option>
                        <sl-option onClick={() => setECadence("annual")} value="annual">
                          Annually
                        </sl-option>
                      </sl-select>
                    </span>
                    <small>Time Frame</small>
                    <span className="end-date-toggle-wrapper">
                      <label className="cont-form-button-group-label">Has End Date</label>
                      <sl-radio-group class="end-date-toggle" value={hasEEndDate}>
                        <sl-radio-button onClick={() => setHasEEndDate(true)} value={true}>
                          Yes
                        </sl-radio-button>
                        <sl-radio-button
                          onClick={() => {
                            setHasEEndDate(false);
                            setEEndDate("");
                          }}
                          value={false}
                        >
                          No
                        </sl-radio-button>
                      </sl-radio-group>
                    </span>
                    <div className={eDateError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                      <span className="amount-error-text">{eDateError ?? null}</span>
                    </div>
                    <span class="schedule-date-pickers">
                      <label className="cont-form-button-group-label">Starts</label>
                      <input onChange={(e) => setEStartDate(e.target.value)} value={eStartDate} type="date"></input>
                    </span>
                    {hasEEndDate && (
                      <span class="schedule-date-pickers">
                        <label className="cont-form-button-group-label">Ends</label>
                        <input onChange={(e) => setEEndDate(e.target.value)} value={eEndDate} type="date"></input>
                      </span>
                    )}
                  </div>
                </div>

                <div className="schedule-form-footer">
                  <sl-button
                    class="save-schedule-button"
                    onClick={() => saveSchedule()}
                    disabled={
                      noFormChange
                        ? "disabled"
                        : hasIndividual && amounts.iAmount == 0
                        ? "disabled"
                        : hasEmployer && amounts.eAmount == 0
                        ? "disabled"
                        : hasIndividual
                        ? !(
                            iTitle &&
                            amounts.iAmount &&
                            !amountErrors.iAmountError &&
                            iCadence &&
                            iStartDate &&
                            ((hasIEndDate && iEndDate) || !hasIEndDate) &&
                            !iDateError
                          )
                          ? "disabled"
                          : null
                        : hasEmployer
                        ? !(
                            eTitle &&
                            amounts.eAmount &&
                            !amountErrors.eAmountError &&
                            eCadence &&
                            eStartDate &&
                            ((hasEEndDate && eEndDate) || !hasEEndDate) &&
                            !eDateError
                          )
                          ? "disabled"
                          : null
                        : !contributionSchedule?.activeSchedule
                        ? "disabled"
                        : null
                    }
                  >
                    {contributionSchedule?.activeSchedule
                      ? hasIndividual || hasEmployer
                        ? "Update Schedule"
                        : "Disable Schedule"
                      : "Save Schedule"}
                  </sl-button>
                </div>
              </div>
              {contributionSchedule ? null : (
                <sl-button class="otc-btn" onClick={() => makeOneTime()} variant="neutral">
                  One-Time Contribution
                </sl-button>
              )}
            </div>
          ) : (
            <div className="schedule-editor-wrapper">
              <div className="editor-panel">
                <span className="schedule-panel-header">
                  <h2>One-Time Contribution</h2>
                  <span className="cancel-edit-btn">
                    <AiFillPlusCircle onClick={() => closeEdit()} />
                  </span>
                </span>
                {saveError ? <span className="save-error-block">{saveError}</span> : null}
                <div className="schedule-controls">
                  <span className="contribution-title-section">
                    <small>Title</small>
                    <input
                      placeholder="Contribution Title"
                      onClick={(e) => e.target.select()}
                      onChange={(e) => setOtTitle(e.target.value)}
                      value={otTitle}
                      maxLength="85"
                    ></input>
                  </span>
                  <div className={amountErrors.otAmountError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                    <span className="amount-error-text">{amountErrors.otAmountError ?? null}</span>
                  </div>
                  <span className="one-time-dual-field-section">
                    <span className="amount">
                      <small>Amount</small>
                      <span className="blocks-currency-input">
                        <span className="dollar-sign">$</span>
                        <input
                          onClick={(e) => e.target.select()}
                          onChange={(e) => validateAmount(e.target.value, "ot")}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              validateAmount(e.target.value, "ot", "blur");
                            }
                          }}
                          value={amounts.otAmount}
                        ></input>
                      </span>
                    </span>
                    <span className="employer-toggle">
                      <small>Employer?</small>
                      <sl-radio-group class="one-time-employer-toggle" value={otSource}>
                        <sl-radio-button value={true} onClick={() => setOtSource(true)}>
                          Yes
                        </sl-radio-button>
                        <sl-radio-button value={false} onClick={() => setOtSource(false)}>
                          No
                        </sl-radio-button>
                      </sl-radio-group>
                    </span>
                  </span>
                  <div className={contributionDateError ? "form-error-wrapper shown" : "form-error-wrapper"}>
                    <span className="amount-error-text">{contributionDateError ?? null}</span>
                  </div>
                  <span className="contribution-date-input-section">
                    <small>Contribution Date</small>
                    <input
                      onChange={(e) => validateDate(e.target.value)}
                      onBlur={(e) => {
                        validateDate(e.target.value, "blur");
                      }}
                      type="date"
                      min={minOtDate}
                      max={maxDate}
                      value={otDate ? otDate : ""}
                    ></input>
                  </span>
                </div>
                <sl-button
                  onClick={() => saveOneTime()}
                  class="save-one-time-button"
                  disabled={
                    otTitle.length < 2 ||
                    amounts.otAmount < 0 ||
                    !otDate ||
                    amountErrors.otAmountError ||
                    contributionDateError
                      ? "disabled"
                      : null
                  }
                >
                  Save Contribution
                </sl-button>
              </div>
            </div>
          )
        ) : (
          <div className="new-contribution-buttons">
            <sl-button onClick={() => makeSchedule()} class="create-schedule-btn">
              Create Contribution Schedule
            </sl-button>
            <sl-button variant="neutral" onClick={() => makeOneTime()} class="add-one-time-btn">
              Add One-Time Contribution
            </sl-button>
          </div>
        )
      ) : (
        <div>
          <div className="schedule-display-box">
            <h2>
              {loadedSetSchedule ? (
                <span className={contributionSchedule?.activeSchedule ? "recurring-icon active" : "recurring-icon"}>
                  <TbRotate2 />
                </span>
              ) : (
                <div id="schedule-header-loading-indicator">
                  <ButtonLoader />
                </div>
              )}

              {loadedSetSchedule
                ? contributionSchedule?.activeSchedule
                  ? "You have an active schedule for recurring contributions."
                  : "You do not have an active schedule for recurring contributions."
                : "Loading contribution schedule"}
            </h2>
            <div>
              <section className="schedule-section">
                <h3>
                  <FaPerson /> Individual Schedule
                  {loadedSetSchedule ? (
                    contributionSchedule?.scheduleDetail.hasIndividualContributionSchedule ? (
                      <sl-badge variant="small-success">ACTIVE</sl-badge>
                    ) : (
                      <sl-badge variant="small-neutral">INACTIVE</sl-badge>
                    )
                  ) : (
                    <div id="schedule-loading-indicator">
                      <ButtonLoader />
                    </div>
                  )}
                </h3>
                {loadedSetSchedule ? (
                  contributionSchedule?.scheduleDetail.hasIndividualContributionSchedule ? (
                    <div className="schedule-information">
                      <div>
                        You are scheduled to make an automatic{" "}
                        <strong>{contributionSchedule?.scheduleDetail.individualContributionSchedule.cadence}</strong>{" "}
                        contribution.
                      </div>
                      <div>
                        The next contribution will be on <strong>{nextIndividualDate}</strong> for{" "}
                        <strong>
                          {currencyHandler(contributionSchedule?.scheduleDetail.individualContributionSchedule.amount)}
                        </strong>
                        .
                      </div>
                    </div>
                  ) : null
                ) : null}
              </section>

              <section className="schedule-section">
                <h3>
                  <HiOfficeBuilding /> Employer Schedule
                  {loadedSetSchedule ? (
                    contributionSchedule?.scheduleDetail.hasEmployerContributionSchedule ? (
                      <sl-badge variant="small-success">ACTIVE</sl-badge>
                    ) : (
                      <sl-badge variant="small-neutral">INACTIVE</sl-badge>
                    )
                  ) : (
                    <div id="schedule-loading-indicator">
                      <ButtonLoader />
                    </div>
                  )}
                </h3>
                {loadedSetSchedule ? (
                  contributionSchedule?.scheduleDetail.hasEmployerContributionSchedule ? (
                    <div className="schedule-information">
                      <div>
                        Your employer is scheduled to make{" "}
                        {contributionSchedule?.scheduleDetail.employerContributionSchedule.cadence === "annual"
                          ? "an"
                          : "a"}{" "}
                        <strong>{contributionSchedule?.scheduleDetail.employerContributionSchedule.cadence}</strong>{" "}
                        contribution.
                      </div>
                      <div>
                        The next contribution will be on <strong>{nextEmployerDate}</strong> for{" "}
                        <strong>
                          {currencyHandler(contributionSchedule?.scheduleDetail.employerContributionSchedule.amount)}
                        </strong>
                        .
                      </div>
                    </div>
                  ) : null
                ) : null}
              </section>
            </div>
          </div>
          <div className={afterFirstVisit ? "active-schedule-controls" : "active-schedule-controls animated"}>
            <sl-button variant="neutral" onClick={() => makeSchedule()}>
              {contributionSchedule?.activeSchedule ? "Edit Schedule" : "Create Schedule"}
            </sl-button>
            <sl-button onClick={() => makeOneTime()}>One-Time Contribution</sl-button>
          </div>
        </div>
      )}
    </section>
  );
};

export { ContributionForm };
