import React, { useEffect, useState } from "react";
import { createClient } from "@sanity/client";
import { PortableText } from "@portabletext/react";
import { Loader } from "./Loader";
import { useAuth } from "../../contexts/AuthContext";

const ReleaseNotes = () => {
  const { releaseNotes, getReleaseNotes } = useAuth();

  useEffect(() => {
    if (!releaseNotes || releaseNotes.length < 1) {
      getReleaseNotes();
    }
  }, [releaseNotes]);

  return (
    <section className="release-notes-page">
      <h1>Release_Notes</h1>
      {releaseNotes ? (
        <section className="notes-wrapper">
          {releaseNotes.map((note) => {
            return (
              <div className="individual-note" key={note._id}>
                <h2 className="note-header">
                  <span className="version-chip">{note.versionNumber}</span>
                  {note.title}
                </h2>
                <PortableText className="note-body" value={note.releaseNotes} />
              </div>
            );
          })}
        </section>
      ) : (
        <div className="notes-loader">
          <Loader />
        </div>
      )}
    </section>
  );
};

export { ReleaseNotes };
