import React, { useEffect, useState } from "react";
import { MdSupport } from "react-icons/md";
import * as TbIcon from "react-icons/tb";
import * as IoIcon from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HiMiniChatBubbleOvalLeft } from "react-icons/hi2";
import { useAuth } from "../../contexts/AuthContext";
import { Loader } from "./Loader";
import { render } from "@testing-library/react";

const Support = () => {
  const navigate = useNavigate();

  const { helpCategories, getHelpCategories, helpDocuments, getHelpDocuments, currentUser } = useAuth();

  useEffect(() => {
    if (!helpCategories) {
      getHelpCategories();
    }
    if (!helpDocuments) {
      getHelpDocuments();
    }
    if (helpCategories && helpDocuments) {
      helpCategories.forEach((category) => {});
    }
  }, [helpCategories, helpDocuments]);

  const handleMailtoClick = () => {
    const email = "support@shoebox.finance";
    const subject = `Support Issue | S-${Date.now()}`;
    const body = encodeURIComponent(
      `\n\n______________________________________________________________\n\nPlease leave subject & body lines set and answer above the line. This allows us to correctly sort your request. Thank you!\nU-${currentUser.uid}`
    );

    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${body}`;

    // Open the mailto link
    window.location.href = mailtoLink;
  };

  const renderIcon = ({ library, icon }) => {
    // Choose the library based on the "library" value
    const IconLibrary = library === "Tb" ? TbIcon : library === "Io5" ? IoIcon : null;

    if (!IconLibrary || !IconLibrary[icon]) {
      console.warn("Invalid library or icon name");
      return null;
    }

    // Render the icon component dynamically
    const IconComponent = IconLibrary[icon];
    return <IconComponent className="category-icon" />;
  };

  const renderCount = (category) => {
    let docNum = 0;
    helpDocuments?.map((doc) => {
      if (doc.category._ref === category._id) {
        docNum++;
      }
    });
    return `${
      docNum !== 0
        ? docNum > 1
          ? docNum + " resources available"
          : docNum + " resource available"
        : "No resources available"
    }`;
  };

  return (
    <section id="support-page">
      <div className="support-page-header">
        <h1>Support</h1>
      </div>
      <div className="support-header">
        <MdSupport />
        <div>We're here to help!</div>
      </div>

      <div className="active-support-options">
        <h2>Get Active Support</h2>
        <div className="button-row">
          <sl-button id="blocks-icon-button" variant="neutral" onClick={() => handleMailtoClick()}>
            <HiMiniChatBubbleOvalLeft />
            <span>Contact Us</span>
          </sl-button>
          <a data-canny-link href="https://shoebox.canny.io/feedback" target="_blank">
            <sl-button id="blocks-icon-button" variant="neutral">
              <TbIcon.TbStarFilled />
              <span className="external-link">
                Give Feedback <TbIcon.TbExternalLink />
              </span>
            </sl-button>
          </a>
        </div>
      </div>

      <div className="quick-support">
        <h2>Quick Support Resources</h2>
        {helpDocuments && helpCategories ? (
          <section className="quick-support-items">
            {helpCategories?.map((category) => {
              if (!renderCount(category).includes("No")) {
                return (
                  <div
                    className={
                      !renderCount(category).includes("No") ? "quick-support-card selectable" : "quick-support-card"
                    }
                    onClick={() => {
                      !renderCount(category).includes("No") && navigate(`/support/${category.name}`);
                    }}
                    key={category._id}
                  >
                    <div className="card-content">
                      {renderIcon(category.categoryInfo)}
                      <div className="title-and-info">
                        <h2>{category.title}</h2>
                        {helpDocuments ? <span>{renderCount(category)}</span> : <sl-skeleton></sl-skeleton>}
                      </div>
                    </div>
                    {!renderCount(category).includes("No") && <TbIcon.TbChevronRight />}
                  </div>
                );
              }
            })}
          </section>
        ) : (
          <div className="support-docs-loader">
            <sl-skeleton effect="sheen"></sl-skeleton>
            <sl-skeleton effect="sheen"></sl-skeleton>
            <sl-skeleton effect="sheen"></sl-skeleton>
          </div>
        )}
      </div>
      <div className="coming-up-support-text">More help items are on the way!</div>
    </section>
  );
};

export { Support };
