import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { TbRosetteDiscountCheckFilled, TbAlertHexagonFilled } from "react-icons/tb";
import { CgOverflow } from "react-icons/cg";
import { currencyRoundHandler } from "../../../../hooks-n-utils/currency";
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../global/Loader";

const AnnualProgress = () => {
  const { annualData, currentYearContributions, activeUserProfile } = useAuth();

  const navigate = useNavigate();

  const [contributionAmount, setContributionAmount] = useState();
  const [contributionLimit, setContributionLimit] = useState();
  const [arcHeight, setArcHeight] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-portfolio-visit");

  let currentYear = new Date().getFullYear();

  useEffect(() => {}, [afterFirstVisit]);

  let width, height, gradient;
  useEffect(() => {
    if (contributionAmount || contributionAmount === 0) {
      setTimeout(() => {
        setLoadingData(false);
      }, 100);
    }
    if (contributionAmount === contributionLimit) {
      document.getElementById("contribution-completion-check")?.setAttribute("disabled", true);
    } else {
      document.getElementById("contribution-completion-check")?.removeAttribute("disabled");
    }
    if (annualData) {
      setContributionLimit(
        getEligibleContributionAmount(activeUserProfile?.coverageType, activeUserProfile?.catchUpEligible, currentYear)
      );
    }
    if (currentYearContributions) {
      let currentYearTotal = 0;
      let filteredYearContributions = currentYearContributions.filter(
        (contribution) => contribution.status === "Completed"
      );
      filteredYearContributions.map((contribution) => {
        currentYearTotal = currentYearTotal + contribution.amount;
      });
      setContributionAmount(currentYearTotal);
    }
  }, [contributionAmount, contributionLimit, annualData, currentYearContributions]);

  const getEligibleContributionAmount = (coverageType, catchUpEligible, year) => {
    let contributionAmount = 0;
    annualData?.map((yearData) => {
      if (yearData.year == year) {
        if (coverageType === "self") {
          if (catchUpEligible) {
            contributionAmount = yearData.catchUpIndividualContributionLimit;
          } else {
            contributionAmount = yearData.individualContributionLimit;
          }
        }
        if (coverageType !== "self") {
          if (catchUpEligible) {
            contributionAmount = yearData.catchUpFamilyContributionLimit;
          } else {
            contributionAmount = yearData.familyContributionLimit;
          }
        }
      }
    });
    return contributionAmount;
  };

  ChartJS.register(ArcElement);

  let style = getComputedStyle(document.body);
  let shoeboxYellow = style.getPropertyValue("--shoebox-yellow");
  let emptyGrey = style.getPropertyValue("--tile-chart-background");
  let lightChartGradient = style.getPropertyValue("--tile-chart-light-gradient");
  let deepErrorGradient = style.getPropertyValue("--tile-chart-deep-error-gradient");
  let lightErrorGradient = style.getPropertyValue("--tile-chart-light-error-gradient");

  let progressFigure;
  if (contributionLimit - contributionAmount > 0) {
    progressFigure = contributionLimit - contributionAmount;
  }
  if (contributionLimit - contributionAmount === 0) {
    progressFigure = contributionLimit - contributionAmount;
  }
  if (contributionLimit - contributionAmount < 0) {
    progressFigure = 0;
  }

  let data = {
    datasets: [
      {
        data: [contributionAmount, progressFigure],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return [getGradient(ctx, chartArea), emptyGrey];
        },
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return [getGradient(ctx, chartArea), emptyGrey];
        },
      },
    ],
  };

  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      width = chartWidth;
      setArcHeight(chartHeight);
      height = chartHeight;
      gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right - 100, 0);
      if (contributionAmount && contributionLimit && contributionLimit - contributionAmount < 0) {
        gradient.addColorStop(0, lightErrorGradient);
        gradient.addColorStop(1, deepErrorGradient);
      }
      if (contributionAmount && contributionLimit && contributionLimit - contributionAmount >= 0) {
        gradient.addColorStop(0, lightChartGradient);
        gradient.addColorStop(1, shoeboxYellow);
      }
    }

    return gradient;
  }

  let options = {
    animation: {
      duration: !afterFirstVisit ? 600 : 0,
    },
    responsive: true,
    cutout: "80%",
    borderWidth: 0,
    rotation: -90,
    circumference: 180,
    aspectRatio: 2,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div className="portfolio-tile" id="annual-progress-tile">
      {!loadingData && contributionLimit && annualData && activeUserProfile ? (
        <>
          <div className="tile-header">
            <div className="title-wrapper">
              <h2>Annual Progress</h2>
              {contributionLimit - contributionAmount < 0 && (
                <small
                  className="help-link"
                  onClick={() => document.getElementById("excessive-contribution-popup")?.show()}
                >
                  What Does This Error Mean?
                </small>
              )}
              <sl-dialog label="Exceeding Contribution Limits" id="excessive-contribution-popup">
                <div>
                  <h4>
                    <TbAlertHexagonFilled />
                    What's This Error?
                  </h4>
                  <p>
                    This error is to let you know that based on your profile settings, the IRS-imposed contribution
                    limit has been exceeded.
                  </p>
                  <p>
                    We have you listed as having {activeUserProfile?.coverageType === "self" ? "individual" : "family"}{" "}
                    healthcare coverage and are {activeUserProfile?.catchUpEligible ? "over" : "under"} 55 years of age.
                    Therefore, your allowed contribution amount for {currentYear} is{" "}
                    <strong>
                      {currencyRoundHandler(
                        getEligibleContributionAmount(
                          activeUserProfile?.coverageType,
                          activeUserProfile?.catchUpEligible,
                          currentYear
                        )
                      )}
                    </strong>
                    . If this is incorrect, you can{" "}
                    <span className="link" onClick={() => navigate("/profile/personal-info")}>
                      update your profile here
                    </span>
                  </p>
                  <h4>
                    <CgOverflow className="overflow" />
                    How to Handle Excess Contributions
                  </h4>
                  <p>
                    Thankfully, handling excess contributions is much easier with HSAs compared to other tax-advantaged
                    retirement accounts. Simply withdrawing the amount of money over the contribution limit ahead of tax
                    season will not incur any penalties.
                  </p>
                  <p>
                    <strong>Note:</strong> It is important to mention that if any of these excess contributions were
                    invested automatically, the cooresponding gains/losses will need to be removed from your account as
                    well. In this situation, it's best to contact your HSA account administrator for assistance.
                  </p>
                </div>
              </sl-dialog>
            </div>

            {contributionLimit - contributionAmount >= 0 ? (
              <sl-tooltip
                id="contribution-completion-check"
                content={currencyRoundHandler(contributionLimit - contributionAmount) + " to go!"}
              >
                <TbRosetteDiscountCheckFilled className={contributionAmount === contributionLimit ? "complete" : ""} />
              </sl-tooltip>
            ) : (
              <sl-tooltip content="You've exceeded the allowed limit!">
                <TbAlertHexagonFilled className="alert-warning" />
              </sl-tooltip>
            )}
          </div>
          <Doughnut id="annual-progress-chart" data={data} options={options} />
          <div className="progress-text-wrapper" style={{ height: `calc(${arcHeight}px - 18%)` }}>
            {contributionAmount !== contributionLimit ? (
              contributionAmount < contributionLimit ? (
                <div className="progress-text">
                  <small>You've contributed</small>
                  <h3>{currencyRoundHandler(contributionAmount)}</h3>
                  <small>
                    (<span className="figures">{((contributionAmount / contributionLimit) * 100).toFixed(0)}</span>% of
                    the <span className="figures">{currencyRoundHandler(contributionLimit)}</span> limit)
                  </small>
                </div>
              ) : (
                <div className="progress-text">
                  <small>Uh oh!</small>
                  <small>You've exceeded the</small>
                  <small>annual contribution limit.</small>
                  <h3 className="error">{currencyRoundHandler(contributionAmount)}</h3>
                </div>
              )
            ) : (
              <div className="completed-text">
                <small>Congrats!</small>
                <small>You've reached the limit:</small>
                <h3>{currencyRoundHandler(contributionAmount)}</h3>
              </div>
            )}
          </div>
        </>
      ) : (
        <span className="annual-progress-loader">
          <div className="tile-header-loading">
            <h2>Annual Progress</h2>
            <TbRosetteDiscountCheckFilled />
          </div>
          <Loader />
        </span>
      )}
    </div>
  );
};

export { AnnualProgress };
