import React, { useEffect, useState } from "react";
import { TbInfoCircleFilled, TbAlertHexagonFilled } from "react-icons/tb";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { currencyHandler, currencyHandlerSansDollarSign } from "../../../../hooks-n-utils/currency";
import { useAuth } from "../../../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { Loader } from "../../../global/Loader";

const ShoeboxStats = () => {
  const { totalAccountValue, expenses, investmentData, activeUserProfile, loadingExpenses } = useAuth();

  const [isYearToDate, setIsYearToDate] = useState(true);
  const [annualGrowth, setAnnualGrowth] = useState();

  const [annualExpenses, setAnnualExpenses] = useState();
  const [allExpenses, setAllExpenses] = useState();

  const [annualWithdrawals, setAnnualWithdrawals] = useState();
  const [allWithdrawals, setAllWithdrawals] = useState();

  const [mobileWidth, setMobileWidth] = useState();

  const [collapsedView, setCollapsedView] = useState();

  const tabulateAllTimes = () => {
    let expenseTotal = 0;
    let withdrawalTotal = 0;
    expenses?.map((expense) => {
      expenseTotal = expenseTotal + expense.annualExpensesTotal;
      withdrawalTotal = withdrawalTotal + expense.annualWithdrawalsTotal;
    });

    setAllExpenses(expenseTotal);
    setAllWithdrawals(withdrawalTotal);
  };

  let toggle;
  let infoPopup;

  let currentYear = new Date().getFullYear();

  const updateWidth = () => {
    setMobileWidth(window.innerWidth < 400);
    if (document.querySelector("#shoebox-stats")) {
      setCollapsedView(document.querySelector("#shoebox-stats").getBoundingClientRect().width < 330);
    }
  };

  useEffect(() => {
    if (expenses?.sort((a, b) => (a.year < b.year ? 1 : b.year < a.year ? -1 : 0))[0]?.year === currentYear) {
      setAnnualExpenses(
        expenses?.sort((a, b) => (a.year < b.year ? 1 : b.year < a.year ? -1 : 0))[0]?.annualExpensesTotal
      );
      setAnnualWithdrawals(
        expenses?.sort((a, b) => (a.year < b.year ? 1 : b.year < a.year ? -1 : 0))[0]?.annualWithdrawalsTotal
      );
    } else {
      setAnnualExpenses(0);
      setAnnualWithdrawals(0);
    }

    tabulateAllTimes();

    infoPopup = document.getElementById("stats-info-dialog");
    toggle = document.getElementById("time-toggle");
    toggle?.addEventListener("sl-change", (e) => {
      setIsYearToDate(e.target.checked);
    });

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      toggle?.removeEventListener("sl-change", (e) => {
        setIsYearToDate(e.target.checked);
      });
      window.removeEventListener("resize", updateWidth);
    };
  }, [
    toggle,
    infoPopup,
    isYearToDate,
    totalAccountValue,
    allExpenses,
    loadingExpenses,
    expenses,
    annualGrowth,
    investmentData,
    activeUserProfile,
    mobileWidth,
  ]);

  const noExpensesSplash = () => {
    return (
      <div className="empty-stats">
        <TbAlertHexagonFilled />
        <div className="line-with-link">
          No expenses yet! <Link to="/expenses/add">Add eligible expenses</Link> to see your stats.
        </div>
      </div>
    );
  };

  return (
    <div id="shoebox-stats" className="portfolio-tile">
      <div className="tile-header">
        <h2>{collapsedView ? "Stats" : "Shoebox Stats"}</h2>
        {expenses?.length > 0 && (
          <span className="toggle-wrapper">
            <span>{mobileWidth ? "All" : "All-Time"}</span>
            <sl-switch id="time-toggle" checked={true}></sl-switch>
            <span>YTD</span>
          </span>
        )}
      </div>

      {!loadingExpenses ? (
        expenses?.length > 0 ? (
          <>
            <div className="totals-section">
              <small>
                {isYearToDate ? currentYear : "All-Time"} Expenses{" "}
                <span>
                  <TbInfoCircleFilled onClick={() => document.getElementById("stats-info-dialog")?.show()} />
                </span>
              </small>
              <h3>{isYearToDate ? currencyHandler(annualExpenses) : currencyHandler(allExpenses)}</h3>
            </div>

            <div className="totals-section withdrawals">
              <small>
                {isYearToDate ? currentYear : "All-Time"} Withdrawals{" "}
                <span>
                  <TbInfoCircleFilled onClick={() => document.getElementById("stats-info-dialog")?.show()} />
                </span>
              </small>
              <h3>
                {isYearToDate
                  ? `$(${currencyHandlerSansDollarSign(annualWithdrawals)})`
                  : `$(${currencyHandlerSansDollarSign(allWithdrawals)})`}
              </h3>
            </div>
          </>
        ) : (
          noExpensesSplash()
        )
      ) : (
        <div className="loading-shoebox-stats">
          <Loader />
        </div>
      )}

      <sl-dialog label="Shoebox Stats Info" id="stats-info-dialog">
        <div>
          <h4>
            <FaFileInvoiceDollar /> All-Time & Annual Expenses
          </h4>
          <p>
            All-Time Expenses & Annual Expenses are the sum of all your expenses since inception and during the year,
            respectively. Any withdrawn expenses are deducted from this total.
          </p>
        </div>
        <div>
          <h4>
            <FaHandHoldingDollar /> All-Time & Annual Withdrawals
          </h4>
          <p>
            All-Time Withdrawals & Annual Withdrawals are the sum of all your withdrawals since inception and during the
            year, respectively.
          </p>
          <p>
            <strong>Note:</strong> It is important to keep withdrawals to a minimum and paying out of pocket whenever
            possible to maximize HSA effectiveness.
            {/* Reenable once Ultimate Guide is complete */}
            {/* {hsaExpenseGuide && (
              <a href={hsaExpenseGuide[0].url} target="_blank">
                Learn More
              </a>
            )} */}
          </p>
        </div>
      </sl-dialog>
    </div>
  );
};

export { ShoeboxStats };
