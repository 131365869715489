import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { IoPersonCircleOutline, IoClose } from "react-icons/io5";
import { HiMenuAlt4 } from "react-icons/hi";
import { HiArchiveBox, HiBriefcase } from "react-icons/hi2";
import Shoebox from "../../assets/Shoebox_Filebox.webp";
import { navigateToLandingSection } from "../../hooks-n-utils/analytics/events/landing";

const Header = () => {
  const {
    currentUser,
    termsAcceptanceLoading,
    legalDocs,
    getLegalDocs,
    activeTerms,
    logTermsAgreement,
    activeUserProfile,
  } = useAuth();

  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [isDesktop, setIsDesktop] = useState();

  const [termsOfUse, setTermsOfUse] = useState();

  const statsBlock = document.getElementById("stats");
  const featuresBlock = document.getElementById("features");
  const pricingBlock = document.getElementById("pricing");
  const addtlToolsBlock = document.getElementById("additional-tools");

  useEffect(() => {
    if (!legalDocs) {
      getLegalDocs();
    }
    if (legalDocs) {
      legalDocs.map((doc) => {
        if (doc.categories.hasOwnProperty("S Terms of Use")) {
          setTermsOfUse(doc);
        }
      });
    }
  }, [legalDocs]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleAcceptance = () => {
    logTermsAgreement(currentUser.uid, activeTerms);
  };

  const landingSectionNavigation = (section) => {
    if (isDesktop) {
      navigateToLandingSection(section);
    } else {
      navigateToLandingSection(section);
      toggleMenu();
    }
    if (section === "features") {
      window.scrollTo({
        top: document.getElementById("features")?.offsetTop - 100,
        behavior: "smooth",
      });
    }
    if (section === "strategy") {
      window.scrollTo({
        top: document.getElementById("strategy")?.offsetTop - 100,
        behavior: "smooth",
      });
    }
    if (section === "pricing") {
      window.scrollTo({
        top: document.getElementById("pricing")?.offsetTop - 100,
        behavior: "smooth",
      });
    }
    if (section === "additional_tools") {
      window.scrollTo({
        top: document.getElementById("additional-tools")?.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    updateWidth();
  }, []);

  const updateWidth = () => {
    setIsDesktop(window.innerWidth > 740);
  };

  window.addEventListener("resize", updateWidth);

  const UnauthenticatedNav = () => {
    return (
      <nav>
        <Link
          to="/#features"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
            landingSectionNavigation("features");
          }}
        >
          Features
        </Link>
        <Link
          to="/#strategy"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
            landingSectionNavigation("strategy");
          }}
        >
          Strategy
        </Link>
        <Link
          to="/#pricing"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
            landingSectionNavigation("pricing");
          }}
        >
          Pricing
        </Link>
        <Link
          to="/#additional-tools"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
            landingSectionNavigation("additional_tools");
          }}
        >
          Additional Tools
        </Link>
        <Link
          to="/create-account"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
          }}
        >
          <sl-button class="blocks-pill-btn" pill>
            Start for Free
          </sl-button>
        </Link>
        <Link
          to="/sign-in"
          onClick={() => {
            if (showMenu) {
              toggleMenu();
            }
          }}
          className={location.pathname.includes("/sign-in") ? "sign-in-btn active" : "sign-in-btn"}
        >
          Sign In
        </Link>
      </nav>
    );
  };

  const AuthenticatedNav = () => {
    return (
      <nav className={isDesktop ? "desktop-nav" : "mobile-nav"}>
        {location.pathname === "/expenses" ? (
          <div
            className={
              location.pathname === "/expenses" || location.pathname.includes("/expenses/")
                ? "menu-link active"
                : "menu-link"
            }
          >
            {!isDesktop ? <HiArchiveBox /> : "Expenses"}
          </div>
        ) : (
          <Link
            to="/expenses"
            className={
              location.pathname === "/expenses" || location.pathname.includes("/expenses/")
                ? "menu-link active"
                : "menu-link"
            }
          >
            {!isDesktop ? <HiArchiveBox /> : "Expenses"}
          </Link>
        )}
        {location.pathname === "/portfolio" ? (
          <div
            className={
              location.pathname === "/portfolio" || location.pathname.includes("/portfolio/")
                ? "menu-link active"
                : "menu-link"
            }
          >
            {!isDesktop ? <HiBriefcase /> : "Portfolio"}
          </div>
        ) : (
          <Link
            to="/portfolio"
            className={
              location.pathname === "/portfolio" || location.pathname.includes("/portfolio/")
                ? "menu-link active"
                : "menu-link"
            }
          >
            {!isDesktop ? <HiBriefcase /> : "Portfolio"}
          </Link>
        )}
      </nav>
    );
  };

  const scrollHandler = () => {
    let today = new Date().toLocaleDateString("en-CA");
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday = yesterday.toLocaleDateString("en-CA");
    let isNewUser =
      activeUserProfile &&
      (activeUserProfile?.created === today || activeUserProfile?.created === yesterday) &&
      !activeUserProfile.onboardingOptOut;
    if (location.pathname === "/" && location.hash !== "" && !activeUserProfile) {
      navigate("/", { replace: true });
    }
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (location.pathname === "/expenses" && !isNewUser) {
      return;
    } else if (location.pathname === "/welcome" && isNewUser) {
      return;
    } else {
      navigate("/");
    }
  };

  return (
    <header className="global-header">
      <div className="header-content">
        <section className="branding" onClick={() => scrollHandler()}>
          <img className="shoebox-brand" src={Shoebox} />
          {currentUser ? null : <h1>Shoebox</h1>}
        </section>
        <section className="global-controls">
          {currentUser && <AuthenticatedNav />}
          {!currentUser ? (
            <div>
              <div className="desktop-menu">
                <UnauthenticatedNav />
              </div>
              <div className="mobile-menu">
                <div className="mobile-header">
                  <HiMenuAlt4 className="menu-toggle" onClick={toggleMenu} />
                </div>

                <div className="mobile-overlay" onClick={toggleMenu} id={showMenu ? "" : "overlay-hidden"}></div>
                <div className="menu-container" id={showMenu ? "" : "container-hidden"}>
                  <div className="close">
                    <IoClose onClick={toggleMenu} />
                  </div>
                  <UnauthenticatedNav />
                </div>
              </div>
            </div>
          ) : null}
          {currentUser ? (
            <Link to="/profile">
              <div
                className={
                  location.pathname.includes("/profile") ||
                  location.pathname.includes("/support") ||
                  location.pathname.includes("/feedback") ||
                  location.pathname.includes("/about")
                    ? "profile active"
                    : "profile"
                }
              >
                {activeUserProfile?.picture ? (
                  location.pathname === "/profile" || location.pathname === "/profile/edit-picture" ? (
                    <IoPersonCircleOutline />
                  ) : (
                    <img src={activeUserProfile?.picture} className="profile-img-icon" />
                  )
                ) : (
                  <IoPersonCircleOutline />
                )}
              </div>
            </Link>
          ) : null}
        </section>
      </div>
      <sl-dialog label="Terms of Use Agreement" id="terms-agreement-popup">
        <div id="terms-agreement-body">
          <h4>Shoebox Terms of Use</h4>
          <div className="terms-popup-body" dangerouslySetInnerHTML={{ __html: termsOfUse?.content }}></div>
        </div>
        <div slot="footer">
          <sl-button
            variant="neutral"
            onClick={() => !termsAcceptanceLoading && document.getElementById("terms-agreement-popup")?.hide()}
          >
            Cancel
          </sl-button>
          <sl-button
            onClick={() => {
              !termsAcceptanceLoading && handleAcceptance();
            }}
          >
            {termsAcceptanceLoading ? <sl-spinner variant="button-primary"></sl-spinner> : "Accept"}
          </sl-button>
        </div>
      </sl-dialog>
    </header>
  );
};

export { Header };
