import React, { useState, useEffect } from 'react';
import * as TbIcon from 'react-icons/tb';

const ProfilePlaceholder = ({colorIndex, iconIndex, size}) => {

    const [ placeholderColor, setPlaceholderColor ] = useState();
    const [ placeholderIcon, setPlaceholderIcon ] = useState();
  
    let colors = ['#FF993C', '#2FB500', '#464353', '#DFC6FF', '#0085FF', '#B20000', '#FFC832', '#8D8D8D'];
    let icons = ['TbBandageFilled', 'TbDiamondFilled', 'TbEggFilled', 'TbFlagFilled', 'TbGhostFilled', 'TbPuzzleFilled', 'TbUmbrellaFilled', 'TbRosetteFilled'];

    const Icon = ({iconName}) => {
        let icon;
    
        if (iconName.slice(0, 2) === 'Tb') {
            icon = React.createElement(TbIcon[iconName], {style: placeholderIconStyles});
        }
        
        return (
          <div className='icon-wrapper' style={placeholderIconWrapperStyles}>{icon}</div>
        );
    };

    const placeholderStyles = {
        borderRadius: '100px',
        height: size === 'large' ? '200px' : '100px',
        width: size === 'large' ? '200px' : '100px',
        backgroundColor: placeholderColor
    }

    const placeholderIconWrapperStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: size === 'large' ? '200px' : '100px',
        width: size === 'large' ? '200px' : '100px'
    }

    const placeholderIconStyles = {
        color: 'var(--background-color)',
        fontSize: size === 'large' ? '72pt' : '42pt'
    }

    useEffect(() => {
        setPlaceholderColor(colors[colorIndex]);
        setPlaceholderIcon(icons[iconIndex]);
    }, [colorIndex, iconIndex]);

    return (
        <div className='profile-image-placeholder' style={placeholderStyles}>{placeholderIcon ? <Icon iconName={placeholderIcon} /> : null}</div>
    )
}

export { ProfilePlaceholder }