import React, { useEffect, useState } from "react";
import { dateHandler } from "../../hooks-n-utils/date";
import { currencyHandler } from "../../hooks-n-utils/currency";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { TbSquareRoundedLetterW, TbReceipt } from "react-icons/tb";

const ExpensesList = ({ expenses, setSelectedExpense, selectedExpense }) => {
  const navigate = useNavigate();

  let afterFirstVisit = window.sessionStorage.getItem("after-first-session-expenses-visit");

  useEffect(() => {}, [afterFirstVisit]);

  const RenderExpenses = () => {
    let ts = performance.now();

    return (
      <>
        {expenses
          ?.sort((a, b) => (a.year < b.year ? 1 : b.year < a.year ? -1 : 0))
          .map((expense, index) => {
            if (expense.expenses.length > 0) {
              return (
                <section className="year-block" key={index + "-yr-block-" + ts}>
                  <div className="year-header">{expense.year}</div>
                  {expense.expenses
                    ?.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0))
                    .map((expense, index) => {
                      return <ExpenseItem key={expense.id + "-expense-" + ts} expense={expense} index={index} />;
                    })}
                </section>
              );
            }
          })}
      </>
    );
  };

  const ExpenseItem = ({ expense, index }) => {
    const [titleDelta, setTitleDelta] = useState(0);

    let amountBlock;

    useEffect(() => {
      amountBlock = document.getElementById(`list-amount-block-${expense.id}`);

      if (amountBlock) {
        let width = amountBlock.getBoundingClientRect().width;
        setTitleDelta(width + 12);
      }
    }, [amountBlock]);

    return (
      <div
        className={selectedExpense && selectedExpense.id === expense.id ? "expense-item selected" : "expense-item"}
        key={index}
        onClick={() => {
          setSelectedExpense(expense);
          navigate(`/expenses/details/${expense.id}`);
        }}
      >
        <div className="expense-summary" style={{ width: `calc(100% - ${titleDelta}px)` }}>
          <h3 id={`expense-title-${expense.id}`}>{expense.name}</h3>
          <h4 id={`expense-date-line-${expense.id}`}>
            <span className={`expense-date-${expense.id}`}>{dateHandler(expense.date)}</span>
            {(expense.receipts.length > 0 || expense.withdrawn) && (
              <span className="status-icons-block">
                <span className="divider">•</span>
                {expense.receipts.length > 0 && (
                  <sl-tooltip content="Has Receipt(s)">
                    <TbReceipt />
                  </sl-tooltip>
                )}
                {expense.withdrawn && (
                  <sl-tooltip content="Expense Withdrawn">
                    <TbSquareRoundedLetterW className="withdrawn" />
                  </sl-tooltip>
                )}
              </span>
            )}
          </h4>
        </div>
        <span className="expense-amount" id={`list-amount-block-${expense.id}`}>
          {currencyHandler(expense.amount)}
          <IoIosArrowForward />
        </span>
      </div>
    );
  };

  return (
    <section className={afterFirstVisit ? "expense-list" : "expense-list animated"}>
      <RenderExpenses />
    </section>
  );
};

export { ExpensesList };
