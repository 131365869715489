import "./App.scss";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { SignIn } from "./components/authentication/SignIn";
import { PublicRoute, PrivateRoute } from "./components/authentication/RouteRestrictions";
import { NotFound } from "./components/global/404";
import { Expenses } from "./components/expenses/Expenses";
import { Portfolio } from "./components/portfolio/Portfolio";
import { Profile } from "./components/profile/Profile";
import { Header } from "./components/global/Header";
import { ForgotPassword } from "./components/authentication/ForgotPassword";
import { Landing } from "./components/external/Landing";
import { CreateAccount } from "./components/external/CreateAccount";
import { Support } from "./components/global/Support";
import { Feedback } from "./components/global/Feedback";
import { About } from "./components/global/About";
import { PersonalInfo } from "./components/profile/PersonalInfo";
import { Subscription } from "./components/profile/Subscription";
import { AccountSecurity } from "./components/profile/AccountSecurity";
import { Resources } from "./components/external/Resources";
import { FAQ } from "./components/global/FAQ";
import { Configuration } from "./components/portfolio/Configuration";
import { ReleaseNotes } from "./components/global/ReleaseNotes";
import { TbArrowBigDownLinesFilled } from "react-icons/tb";
import { PrivacyPolicy } from "./components/global/Legal/PrivacyPolicy";
import { TermsOfUse } from "./components/global/Legal/TermsOfUse";
import { HelpDocs } from "./components/global/HelpDocs";
import { Onboarding } from "./components/authentication/Onboarding";

const App = () => {
  const ScrollToTop = (props) => {
    const location = useLocation();

    useEffect(() => {
      if (!window.sessionStorage.getItem("lastPosition")) {
        window.sessionStorage.setItem("lastPosition", "");
      }
      let lastPosition = window.sessionStorage.getItem("lastPosition");
      if (lastPosition === "") {
        window.sessionStorage.setItem("lastPosition", location.pathname);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
      if (lastPosition !== location.pathname) {
        window.sessionStorage.setItem("lastPosition", location.pathname);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        title: location.pathname,
      });
    }, [location]);

    return <>{props.children}</>;
  };

  document.addEventListener("touchstart", function () {}, true);

  window.addEventListener("scroll", () => {
    document.documentElement.style.setProperty("--scroll-y", `${window.scrollY}px`);
  });

  const watchForHover = () => {
    // lastTouchTime is used for ignoring emulated mousemove events
    let lastTouchTime = 0;

    function enableHover() {
      if (new Date() - lastTouchTime < 500) return;
      document.body.classList.add("hasHover");
    }

    function disableHover() {
      document.body.classList.remove("hasHover");
    }

    function updateLastTouchTime() {
      lastTouchTime = new Date();
    }

    document.addEventListener("touchstart", updateLastTouchTime, true);
    document.addEventListener("touchstart", disableHover, true);
    document.addEventListener("mousemove", enableHover, true);

    enableHover();
  };

  watchForHover();

  ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

  return (
    <div>
      <Router>
        <ScrollToTop>
          <AuthProvider>
            <section className="header" id="app-header">
              <Header />
            </section>
            <section className="content">
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route exact path="/" element={<Landing />} />
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/create-account" element={<CreateAccount />} />
                  <Route path="/hsa-resources" element={<Resources />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/reset-password" element={<ForgotPassword />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route exact path="/" element={<Navigate to="/expenses" replace />} />
                  <Route path="/welcome" element={<Onboarding />} />
                  <Route path="/expenses" element={<Expenses />} />
                  <Route path="/expenses/add" element={<Expenses />} />
                  <Route path="/expenses/details/:id" element={<Expenses />} />
                  <Route path="/expenses/details/:id/edit" element={<Expenses />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/portfolio/configuration" element={<Configuration />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/profile/personal-info" element={<PersonalInfo />} />
                  <Route path="/profile/edit-picture" element={<PersonalInfo profilePicture={true} />} />
                  <Route path="/profile/subscription" element={<Subscription />} />
                  <Route path="/profile/security" element={<AccountSecurity />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/support/faq" element={<FAQ />} />
                  <Route path="/support/:topic" element={<HelpDocs />} />
                  <Route path="/support/:topic/:doc" element={<HelpDocs />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/feedback/*" element={<Feedback />} />
                </Route>
                <Route path="/about" element={<About />} />
                <Route path="/about/release-notes" element={<ReleaseNotes />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
              </Routes>
            </section>
          </AuthProvider>
        </ScrollToTop>
      </Router>
    </div>
  );
};
export default App;
